import React, { useState } from "react";
import '../../styles/validation.css'
import IconError from '../../images/icon-error.svg';
import Loading from "../loading";

export default function FailedID (props) {

    const[loading, setLoading]= useState('');
    const handleSubmit = () => {
      setLoading(true);
      props.prev(props.data, props.goBackToPage)
    }

  const manualValidation = () => {
    setLoading(true);
    props.next(props.data, 7)
  }

    if(loading) {
        return <Loading />
    }

    return (
        <div>
            <div className='figure' id="circle">
                <img className='icon-error'
                src={IconError}
                alt="error icon" />
            </div>
            <h1 className="text-failed-verification">Verificación fallida</h1>
            <h2 className="please-take-it-again">Algo salió mal, por favor recuerda:</h2>
            <ul className="indications-container">
                <li className="indications">Ubica el documento de identidad dentro del encuadre.</li>
                <li className="indications">Asegúrate de tener buena iluminación.</li>
                <li className="indications">Fíjate que la foto no sea borrosa.</li>
                <li className="indications" >Recuerda que el carnet y la selfie deben ser de la misma persona.</li>
            </ul>
          <div className="validation-failed-buttons">
            <button onClick={handleSubmit} className="validation-failed-button try-again-btn">Reintentar</button>
            { props.data.is_manual_verification && <button onClick={manualValidation} className="validation-failed-button manual-verification-btn is-link">Verificación manual</button> }
          </div>
        </div>
    );
}