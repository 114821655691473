import React, {useState, useEffect, useRef} from "react";
import '../../styles/capture.css';
import Loading from '../loading';
import '../../styles/slider.css';
import SwitchCameraIcon from '../../images/switch-camera-icon.png';
import {Camera} from "react-camera-pro";
import TemplateCarnet from "../../images/documento-front-genric.png";

const TakePictureCarnet = (props) => {
  const [showImgCapture, setShowImgCapture] = useState(true);
  const [loading, setLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState(null);
  const webcamRef = React.useRef(null);
  const INITIAL_FACING_MODE = "environment";
  const divRef = useRef(null);
  const [camara_horizontal, setCamara_horizontal] = useState(false);

  const capture = React.useCallback(
    () => {
      setImgSrc(webcamRef.current.takePhoto());
      setShowImgCapture(false);
    },[webcamRef]
  );

  useEffect(() => {
    const timer = setTimeout(() => { setLoading(false); }, 3000);
    if (imgSrc) {
      props.data.carnet = imgSrc;
      props.data.frontal_image = imgSrc.split(',')[1];
      props.next(props.data, 1);
    }
    return () => clearTimeout(timer);
  }, [imgSrc, props]);

  const handleResize = () => { // para definir las proporciones del template
    let w = 1;
    let h = 1;
    if (divRef.current) {
      w = divRef.current.clientWidth;
      h = divRef.current.clientHeight;
    }
    if (w / h < 4117 / 2569) { // si el alto es mayor que el ancho segun proporcion carnet (CAMARA VERTICAL)
      setCamara_horizontal(false)
    } else {
      setCamara_horizontal(true);
    }
  };

  useEffect(() => {
    handleResize() // en cualquier re-render calculamos el template
  } ); // sin dependencias se dispara en el cambio de todos los states, si cambia setCamara_horizontal, a lo más se dispara 2 veces, no ifinito

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize); // resize si se cambia el tamaño de ventana
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderTitle = () => {
    if(props.data.is_manual_verification){
      return <h1 className='title-capture'>Escanea el frente de su documento de identidad</h1>
    }else{
      return <h1 className='title-capture'>Escanea el frente de tu documento de identidad</h1>
    }
  }

  const renderSubtitle = () => {
    if(props.data.is_manual_verification){
      return <h1 className='subtitle-capture'> Ubica su documento de identidad dentro del rectángulo.</h1>
    }else{
      return <h1 className='subtitle-capture'> Ubica tu documento de identidad dentro del rectángulo.</h1>
    }
  }

  return (
    <>
    <div className='background-capture' hidden={loading}>
      <div className="capture">
        {showImgCapture && (
          <div className="full-container-of-cam1">
            <div className="full-container-of-cam2">
              <div ref={divRef} className="wide-camera-container">
                {camara_horizontal
                  ? <img src={TemplateCarnet} alt="template" className="img-template-horizontal"/>
                  : <img src={TemplateCarnet} alt="template" className="img-template-vertical"/>
                }
                <Camera
                  ref={webcamRef}
                  facingMode={INITIAL_FACING_MODE}
                />
              </div>
              <div className="full-container-of-cam3">
                {renderTitle()}
                {renderSubtitle()}
                <div className="capture-buttons-container">
                  <button className="button button-capture" onClick={capture}>Escanear</button>
                  <img className="button-switch-camera" onClick={() => webcamRef.current.switchCamera()} src={SwitchCameraIcon} title="Cambiar cámara" alt="Cambiar cámara" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    <div hidden={!loading}>
      <Loading/>
    </div>
  </>
  );
};

export default TakePictureCarnet;