import React, { useState } from 'react';
import '../../styles/slider.css';
import BtnSlider from './BtnSlider';
import dataSlider from './dataSlider';

export default function Slider({ img, img2 }) {

  const [slideIndex, setSlideIndex] = useState(1)

  const nextSlide = () => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1)
    }
    else if (slideIndex === dataSlider.length) {
      setSlideIndex(1)
    }
  }

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1)
    }
    else if (slideIndex === 1) {
      setSlideIndex(dataSlider.length)
    }
  }

  const moveDot = index => {
    setSlideIndex(index)
  }

  return (
    <div className="container-slider">
      {dataSlider.map((obj, index) => {
        return (
          <div
            key={index}
            className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
          >
            <img alt={`${index + 1}`}
                 src={img[index]}
            />
          </div>
        )
      })}
      <BtnSlider moveSlide={nextSlide} direction={"next"} key='prev' />
      <BtnSlider moveSlide={prevSlide} direction={"prev"} key='next' />

      <div className="container-dots">
        {Array.from({ length: 2 }).map((item, index) => (

          <div
            onClick={() => moveDot(index + 1)}
            className={slideIndex === index + 1 ? "dot active" : "dot"}
            key={index}
          />

        ))}
      </div>
    </div>
  )
};
