import '../styles/navbar-organization.css';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import React, { useState, useEffect } from "react";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Redirect} from 'react-router-dom';
import { formatRut } from '../utils/formatRut';
import AdminLayout from './AdminLayout';
import {Input} from "reactstrap";
import {FormSelect, Form} from "react-bootstrap";
import Loading from "./loading";

export default function NavBarOrganization() {
  var { currentUser, handleUserLogout } = useAuth();
  const style1 = {width: "100%",backgroundColor: "#EF7474"};
  const style2 = {width: "100%",backgroundColor: "#E8C35D"};
  const style3 = {width: "100%",backgroundColor: "#4236FF"};

  const [verification_list, setVerifications] = useState(null);
  const [statusToken, setStatusToken] = useState(null);

  const [selectedYear, setSelectedYear] = useState("all")
  const [selectedMonth, setSelectedMonth] = useState("all")
  const [rut, setRut] = useState("")
  const [rutFocus, setRutFocus] = useState(true)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function GetVerificationUser(){
      let formdata = new FormData()
      formdata.append('commerce_id', currentUser.user.commerce_id)
      formdata.append('month', selectedMonth)
      formdata.append('year', selectedYear)
      formdata.append('rut', rut)
      try {
        const res = await axios({
          url: process.env.REACT_APP_BACKEND_URL + "/commerce/verification_table/",
          method: 'POST',
          mode: "no-cors",
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer "+currentUser.access
          },
          data: formdata
        })
        return res.data;
      } catch (e) {
        if (e.response) {
          return e.response;
        }
      }
    }
    const User = async () => {
      if(currentUser){
        const response2 = await GetVerificationUser();
        if(response2.status === 500 || response2.length === 0){
          setLoading(false)
          setVerifications([])
        }
        else{
          if (response2.status === 401){
            setStatusToken("Vencido")
            return <Redirect to="/login" />;
          }
          else{
            response2.sort(function(a,b){
              return new Date(b.date) - new Date(a.date);
            });
            setLoading(false)
            setVerifications(response2)
          }
        }
      } else {
        return <Redirect to="/login" />;
      }
    };
    User()
  },[currentUser, rut, selectedMonth, selectedYear]);

  if(currentUser === undefined || statusToken === "Vencido"){
    handleUserLogout()
    return <Redirect to="/login" />;
  }
  if(currentUser.type !== "commerce"){
    return <Redirect to="/home" />;
  }

  const exportToCSV = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  function avarageAcc(ac1,ac2,ac3){
    return (ac1+ac2+ac3)/3
  }

  function round_confidence(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  function status_params(x) {
    if(x.nivel === 1){
      return(
        <div className='container-status-bar' style={{"width": "60px",}}>
          <div className="meter">
            <span style={style1} />
          </div>
          <div className="meter" />
          <div className="meter" />
          <div className="meter" />
        </div>
      )
    }
    if(x.nivel === 2 && x.estado === "Incompleta"){
      return(
        <div className='container-status-bar' style={{"width": "60px",}}>
          <div className="meter">
            <span style={style2} />
          </div>
          <div className="meter">
            <span style={style2} />
          </div>
          <div className="meter">
            <span style={style2} />
          </div>
          <div className="meter" />
        </div>
      )
    }
    if(x.nivel === 2 && x.estado === "Fallida"){
      return(
        <div className='container-status-bar' style={{"width": "60px",}}>
          <div className="meter">
            <span style={style1} />
          </div>
          <div className="meter">
            <span style={style1} />
          </div>
          <div className="meter" />
          <div className="meter" />
        </div>
      )
    }
    if(x.nivel === 3){
      return(
        <div className='container-status-bar' style={{"width": "60px",}}>
          <div className="meter">
            <span style={style3} />
          </div>
          <div className="meter">
            <span style={style3} />
          </div>
          <div className="meter">
            <span style={style3} />
          </div>
          <div className="meter">
            <span style={style3} />
          </div>
        </div>
      )
    }
  }
  function status_color(x) {
    if(x.nivel === 1){
      return({"color": "#EF7474",fontWeight: "500",})
    }
    if(x.nivel === 2 & x.estado !== "Fallida"){
      return({"color": "#E8C35D",fontWeight: "500",})
    }
    if(x.nivel === 2 & x.estado === "Fallida"){
      return({"color": "#EF7474",fontWeight: "500",})
    }
    if(x.nivel === 3){
      return({"color": "#4236FF",fontWeight: "500",})
    }
  }
  const formatDate = (dateString) => {
    const options = {month: "long", day: "numeric", year: "numeric",hour: "numeric", minute: "numeric", seconds:"numeric"}
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  function TableVerification() {
    const handleRutChange = (e) => {
      setRutFocus(true)
      setRut(e.target.value)
    }
    const handleMonthChange = (e) => {
      setRutFocus(false)
      setSelectedMonth(e.target.value)
    }
    const handleYearChange = (e) => {
      setRutFocus(false)
      setSelectedYear(e.target.value)
    }
    return(
      <div className="container-background-table">
        <div className="container-all-table">
          <div className = "container-table-info">
            <div className='container-table-filters'>
              <div className="s006">
                <Form id="filter-form">
                  <div className="inner-form">
                    <div className="input-field">
                      <div className="btn-search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                        </svg>
                       </div>
                      <Input name="rut" id="search-verification" type="text" autoFocus={rutFocus} placeholder="Buscar por nombre o RUT" defaultValue={rut} onChange={handleRutChange} />
                    </div>
                    <div className='container-select-filters'>
                      <FormSelect as="select" name="month" id="container-select-filter-month" defaultValue={selectedMonth} onChange={handleMonthChange}>
                        <option value="01" className="input-value-filter">Enero</option>
                        <option value="02">Febrero</option>
                        <option value="03">Marzo</option>
                        <option value="04">Abril</option>
                        <option value="05">Mayo</option>
                        <option value="06">Junio</option>
                        <option value="07">Julio</option>
                        <option value="08">Agosto</option>
                        <option value="09">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                        <option value="all">Todos los meses</option>
                      </FormSelect>
                      <FormSelect as="select" name="year" id="container-select-filter-year" defaultValue={selectedYear} onChange={handleYearChange}>
                        <option value="2020" className="input-value-filter">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="all">Todos los años</option>
                      </FormSelect>
                    </div>
                  </div>
                </Form>
                <a className= "text-a-table" href="/#" onClick={(e) => { e.preventDefault(); exportToCSV(verification_list,"verifications")}}>
                  <h3 className="text-link-table">Descargar Detalle</h3>
                </a>
              </div>
            </div>
            <div className="content">
              <div className="table-responsive">
                <table className="table custom-table">
                  <thead>
                    <tr style={{backgroundColor: "#f9fbff",}}>
                      <th style={{"width" : "3px"}} scope="col">
                        {/*<label style={{"width" : "30px"}} className="control control--checkbox">*/}
                        {/*  <input type="checkbox" className="js-check-all"/>*/}
                        {/*  <div className="control__indicator" />*/}
                        {/*</label>*/}
                      </th>
                      <th style={{"width" : "70px"}} scope="col">
                        ID
                      </th>
                      <th scope="col">
                        Fecha
                      </th>
                      <th scope="col">
                        Nombre
                      </th>
                      <th scope="col">
                        Ubicación
                      </th>
                      <th style={{"width" : "40px"}} scope="col">
                        ID Accuracy
                      </th>
                      <th style={{"width" : "40px"}} scope="col">
                        Estado
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="loading-row" colSpan="8">
                          <Loading />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {verification_list && verification_list.length ? verification_list.map(ver =>
                          <tr key={ver.verification_id}>
                            <th scope="row">
                              {/*<label className="control control--checkbox">*/}
                              {/*  <input type="checkbox"/>*/}
                              {/*  <div className="control__indicator" />*/}
                              {/*</label>*/}
                            </th>
                            <td  style={{"color": "#4236FF",}}>
                              <a href={"/verifications/"+ver.verification_id}>{ver.verification_id}</a>
                            </td>
                            <td>
                              {formatDate(ver.date)}
                            </td>
                            <td>
                              {ver.user_firts_names +" "+ ver.user_last_names}
                              <p className='text-rut-table'>{formatRut(ver.rut)}</p>
                            </td>
                            <td>
                              {ver.location}
                            </td>
                            <td>
                              {round_confidence(avarageAcc(ver.confidence_type_1,ver.confidence_type_2,ver.confidence_type_3))}
                            </td>
                            <td style={{width: "0px",marginRight:"-100px"}}>
                              <div className='container-status-bar' style={{"width": "10px",}}>
                                {status_params(ver)}
                              </div>
                            </td>
                            <td className= "text-estatus-table" style={status_color(ver)}>
                              {ver.estado}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td className="loading-row" colSpan="8">
                              <div><strong>No se encontraron verificaciones</strong></div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <AdminLayout>
      <TableVerification />
    </AdminLayout>
  );
}

