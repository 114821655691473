import React, {Suspense} from 'react';
import {createCache, createResource} from 'simple-cache-provider';
import Loading from "./loading";

const SuspenseImage = ({src, alt, ...rest}) => {
  const cache = createCache()
  const ImageResource = createResource(
    src =>
      new Promise(resolve => {
        const img = new Image();
        img.onload = () => resolve(src);
        img.src = src;
      })
  );
  return (
    <Suspense delayMs={1000} fallback={<Loading/>}>
      <img src={ImageResource.read(cache, src)} alt={alt} {...rest} />
    </Suspense>
  )
}

export default SuspenseImage;