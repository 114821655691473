import React, {useState} from 'react';
import GertyLogo from '../../images/gerty-blue.png';
import '../../styles/validation.css'
import Loading from '../loading';

export default function Introduccion(props) {

  const[loading, setLoading] = useState('');

  const renderDni = () => {
    if (!props.data.dni_type || props.data.dni_type === "rut") {
      return `${props.data.formatted_rut}`
    } else {
      return `${props.data.dni}`
    }
  }

  const handleSubmit = () => {
    props.next(props.data)
    setLoading(true);
  }
  if(loading){
    return <Loading />
  }

  const renderTitle = () => {
    if(props.data.is_manual_verification){
      return <h1 className="titulo-verificar-pendiente" >Verifiquemos la identidad de {renderDni()}</h1>
    }else{
      return <h1 className="titulo-verificar-pendiente" >Verifica tu identidad</h1>
    }
  }

  const renderMessage = () => {
    return <h3 className="titulo-centro-verificar-pendiente-validar">Necesitamos <strong>su documento de identidad</strong> y una <strong>foto de su rostro</strong></h3>
  }

  return (
    <div>
      <img className='gerty-verificate-validar'
           src={GertyLogo}
           alt="GertyId Logo" />
      {renderTitle()}
      {renderMessage()}
      <div><button className="button is-link" id="button-signin" onClick={handleSubmit}>Continuar</button></div>
    </div>
  );
}

