import '../styles/view-verification.css';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { formatRut } from '../utils/formatRut';
import { Redirect } from 'react-router-dom';
import Loading from '../components/loading';
import AdminLayout from "../components/AdminLayout";
import DisplayAttempts from '../components/backOffice/DisplayAttempts';

export default function ViewVerification() {
  var { currentUser, handleUserLogout } = useAuth();
  const style1 = { width: "100%", backgroundColor: "#EF7474", };
  const style2 = { width: "100%", backgroundColor: "#E8C35D", };
  const style3 = { width: "100%", backgroundColor: "#4236FF", };
  const [verification_id, setVerification] = useState(null);
  const [verification_date, setDate] = useState(null);
  const [verification_location, setLocation] = useState(null);
  const [verification_first_name, setFirstName] = useState(null);
  const [verification_last_name, setLastname] = useState(null);
  const [verification_level, setLevel] = useState(null);
  const [verification_status, setStatus] = useState(null);
  const [verification_rut, setRut] = useState(null);
  const [verification_ac1, setAc1] = useState("N/A");
  const [verification_msg, setMsg] = useState(null);
  const [verification_ac2, setAc2] = useState("N/A");
  const [verification_ac3, setAc3] = useState("N/A");
  const [verificationExists, setVerificationExists] = useState(false);
  const [carnetImage, setCarnetImage] = useState("");
  const [backsideImage, setBacksideImage] = useState("");
  const [attemptImages, setAttemptImages] = useState(null);
  const [loading, setLoading] = useState(true);

  function avarageAcc(ac1, ac2, ac3) {
    if (ac1 !== "N/A" || ac2 === "N/A" || ac3 === "N/A") {
      return ac1;
    }
    else {
      return (ac1 + ac2 + ac3) / 3
    }
  }

  function circleAccColor(status) {
    if (status === "Exitosa") {
      return (<span className="checkmark"><div className="checkmark_stem" />
                <div className="checkmark_kick" /></span>)
    }
    else if (status === "Incompleta") {
      return (<div id="circle-verification-accuracy" />)
    }
    else {
      return (<div id="circle-verification-accuracy-red" />)
    }
  }


  useEffect(() => {
    const getVerification = async () => {
      try {
        const id_ = parseInt(window.location.href.split("/").slice(-1)[0])
        let formdata = new FormData();
        formdata.append('commerce_id', currentUser.user.commerce_id);
        formdata.append('ver_id', id_);
        const res = await axios({
          url: process.env.REACT_APP_BACKEND_URL + "/commerce/get_verification/",
          method: 'POST',
          mode: 'no-cors',
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + currentUser.access,
          },
        })
        console.log(res.data);
        return res.data;
      } catch (e) {
        if (e.response) {
          return e.response;
        }
      }
    }

    const getLevel = (verification) => {
      if (!verification.is_face_successful && !verification.is_successful){
        return 1;
      } else if (verification.is_face_successful && !verification.is_successful){
        return 2;
      } else {
        return 3;
      }
    }

    const getAttemptsInfo = (attempts) => {
      if (attempts.length > 0) {
        const carnet_attempts = attempts.filter(attempt => attempt.type === "face1-carnet");
        const face_attempts = attempts.filter(attempt => attempt.type === "face1-face2");
        const emotion_attempts = attempts.filter(attempt => attempt.type === "face2-emotion");
        if (carnet_attempts.length > 0){
          const confidence_carnet = Math.max.apply(Math, carnet_attempts.map(function (o) { return o.confidence; }));
          setAc1(confidence_carnet);
        }
        if (face_attempts.length > 0){
          const confidence_face = Math.max.apply(Math, face_attempts.map(function (o) { return o.confidence; }));
          setAc2(confidence_face)
        }
        if (emotion_attempts.length > 0){
          const confidence_emotion = Math.max.apply(Math, emotion_attempts.map(function (o) { return o.confidence; }));
          setAc3(confidence_emotion);
        }

        setMsg("Falló en la comparación de la foto con el carnet");
        if (verification_level === 1 && carnet_attempts.length > 0){
          setMsg("Falló en la comparación de la foto con el carnet");
          setStatus("Fallida");
        } else if (verification_level === 2 && face_attempts.length > 0 && emotion_attempts.length === 0){
          setMsg("No se reconoce a quién se verificó con el carnet");
          setStatus("Fallida");
        } else if (verification_level === 2 && emotion_attempts.length > 0){
          setMsg("Falló en verificar la emoción pedida");
          setStatus("Fallida");
        } else {
          if (verification_level === 1){
            setStatus("Pendiente");
          } else if (verification_level === 2){
            setStatus("Incompleta");
            setMsg("Validación de emoción pendiente")
          } else {
            setStatus("Exitosa");
          }
        }
      } else if (verification_level === 1){
        setStatus("Pendiente");
      }
    }
    const getVerificationData = async () => {
      if (currentUser && currentUser.type === "commerce") {
        const verificationData = await getVerification();
        if (verificationData.verification) {
          setVerification(verificationData.verification.verification_id);
          setDate(verificationData.verification.timestamp);
          setLocation(verificationData.verification.location);

          if (verificationData.user_info) {
            if (verificationData.user_info.first_names && verificationData.user_info.last_names){
              setFirstName(verificationData.user_info.first_names);
              setLastname(verificationData.user_info.last_names);
            }
            else {
              if (verificationData.verification.dm_created && verificationData.verification.dm_created === true) {
                setFirstName("Usuario Dental Medica")
              }
            }
            setRut(formatRut(verificationData.user_info.rut));
          }
          if (verificationData.carnet_image) {
            setCarnetImage(verificationData.carnet_image);
          }
          if (verificationData.backside_image) {
            setBacksideImage(verificationData.backside_image);
          }
          if (verificationData.attempt_images) {
            setAttemptImages(verificationData.attempt_images);
          }
          setLevel(getLevel(verificationData.verification));
          getAttemptsInfo(verificationData.attempts);
          setVerificationExists(true);
        } else {
          setVerificationExists(false);
        }
      }
      setLoading(false);

    }
    getVerificationData();
  }, [currentUser, verification_level]);

  function round_confidence(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  function status_params(x, status) {
    if (x === 1) {
      return (<div className='container-status-bar' style={{ "width": "60px", }}>
        <div className="meter">
          <span style={style1} />
        </div>
        <div className="meter">
          <span style={style1} />
        </div>
        <div className="meter" />
        <div className="meter" />
      </div>)
    }
    if (x === 2 & status === "Incompleta") {
      return (
        <div className='container-status-bar' style={{ "width": "60px", }}>
          <div className="meter">
            <span style={style2} />
          </div>
          <div className="meter">
            <span style={style2} />
          </div>
          <div className="meter">
            <span style={style2} />
          </div>
          <div className="meter" />
        </div>
      )
    }
    if (x === 2 & status === "Fallida") {
      return (<div className='container-status-bar' style={{ "width": "60px", }}>
        <div className="meter">
          <span style={style1} />
        </div>
        <div className="meter">
          <span style={style1} />
        </div>
        <div className="meter" />
        <div className="meter" />
      </div>)
    }
    if (x === 3) {
      return (<div className='container-status-bar' style={{ "width": "60px", }}>
        <div className="meter">
          <span style={style3} />
        </div>
        <div className="meter">
          <span style={style3} />
        </div>
        <div className="meter">
          <span style={style3} />
        </div>
        <div className="meter">
          <span style={style3} />
        </div>
      </div>)
    }
  }

  function status_string(level, msg, status) {
    if (status === "Exitosa") {
      return ([<h1 style={{ "color": "#4236FF", fontSize: "16px" }} key='status'>{status}</h1>, <h2 key='message'>{msg}</h2>])
    }

    else if (status === "Incompleta") {
      return ([<h1 style={{ "color": "#E8C35D", fontSize: "16px" }} key='status'>{status}</h1>, <h2 key='message'>{msg}</h2>])
    }
    else {
      return ([<h1 style={{ "color": "#EF7474", fontSize: "16px" }} key='status'>{status}</h1>, <h2 key='message'>{msg}</h2>])
    }
  }


  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", seconds: "numeric" }
    const date = new Date(dateString).toLocaleDateString(undefined, options)
    return date + " ubicado en " + verification_location
  }


  function VerificationInfo() {
    return (
      <div className='container-verification-info'>
        <div className='container-verification-info-header'>
          <h1>ID</h1>
          <h5>{verification_id}</h5>
          <h3>{formatDate(verification_date)}</h3>
          <h2>ID Accuracy</h2>
          <h6>{round_confidence(avarageAcc(verification_ac1, verification_ac2, verification_ac3))}</h6>
          <div className='container-right-accuracy'>
            <div className='container-circle-text'>
              {circleAccColor(verification_status)}
              <h4>DNI vs rostro {round_confidence(verification_ac1)}</h4>
            </div>
            <div className='container-circle-text'>
              {circleAccColor(verification_status)}
              <h4>Rostro vs emoción  {round_confidence(verification_ac2)}</h4>
            </div>
            <div className='container-circle-text'>
              {circleAccColor(verification_status)}
              <h4>Emoción {round_confidence(verification_ac3)}</h4>
            </div>
          </div>
        </div>
        <div className='container-verification-info-sub-header-container'>
          <div className='container-verification-info-sub-header'>
            <h1>Estado</h1>
            <div className='container-status-bar-verification'>
              {status_params(verification_level, verification_status)}
            </div>
            {status_string(verification_level, verification_msg, verification_status)}
          </div>
        </div>
        <div className='container-verification-info-down'>
          <div className='container-verification-info-down-left'>
            <h1>Información</h1>
            <div className='container-verification-info-down-left-detail'>
              <h3>{verification_first_name}</h3>
              <h3>{verification_last_name}</h3>
              <h3>{verification_rut}</h3>
            </div>
          </div>
          <div className='container-verification-info-down-right'>
            <DisplayAttempts carnet_image={carnetImage} backside_image={backsideImage} attempt_images={attemptImages} first_name={verification_first_name} last_name={verification_last_name}/>
          </div>
        </div>
      </div>
    )
  }

  if (!currentUser) {
    handleUserLogout()
    return <Redirect to="/login" />;
  }
  if (currentUser.type !== "commerce") {
    return <Redirect to="/home" />;
  }

  if (!loading && verificationExists === false) {
    return <Redirect to="/verifications" />;
  }

  return (
    <AdminLayout title={"Detalle de Verificación"}>
      {loading ? <Loading /> : <VerificationInfo />}
    </AdminLayout>
  );
}