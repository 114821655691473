import axios from 'axios';

let token = ""

const getAccessToken = async () => {
  try {
    const res = await axios({
      url: process.env.REACT_APP_BACKEND_URL + `/api/token/`,
      method: 'POST',
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        "email": process.env.REACT_APP_TOKEN_USERNAME,
        "password": process.env.REACT_APP_TOKEN_PASSWORD
      }
    })
    return res.data.access;
  } catch (e) {
    return "";
  }
}

const accessToken = async () => {
  return token === "" ? await getAccessToken() : token
}

export default accessToken()