import React from "react";
import '../../styles/capture.css';
import '../../styles/slider.css';
import TakePictureCarnet from '../validacionRut/CaptureCarnetFront';
import cedula_nueva from '../../images/dnis/mexico_nuevo.png';
import cedula_vieja from '../../images/dnis/mexico_antiguo_1.png';
import cedula_vieja_2 from '../../images/dnis/mexico_antiguo_2.png';

function ChoiceDocMexico({props, setIsCountrySelected}) {

  // de momento para extranjeros usaremos el sistema antiguo
  const handleOld = () => {
    setIsCountrySelected(true)
    props.setCarnetNuevo(-1)
  }
  if (props.carnetNuevo === -1) {
    return TakePictureCarnet(props)
  }

  return (
    <>
      <h1 className="country_select__title">¿Qué INE tiene?</h1>
      <div className="country_select__container">
        <img className="button-img" onClick={handleOld} src={cedula_nueva} alt={"Mexico INE nuevo"}/>
        <img className="button-img" onClick={handleOld} src={cedula_vieja} alt={"Mexico INE antiguo"}/>
        <img className="button-img" onClick={handleOld} src={cedula_vieja_2} alt={"Mexico INE antiguo 2"}/>
      </div>
    </>
  )

}

export default ChoiceDocMexico;