
import '../styles/table-verification.css';
import { Redirect} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import NavBarOrganization from '../components/navbar-organization'

export default function TableVerification () {
    var { currentUser } = useAuth();
    if (currentUser) {
        if (currentUser.type === "commerce") {
            return (   
                <div className='background-table-verification'>
                    <NavBarOrganization></NavBarOrganization>
                </div>
            )
        } else {
            return (
                <Redirect to="/home"></Redirect>
            )
        }
    } else {
        return (
            <Redirect to='/login'/>
        )
    }
}
