import React from 'react';
import '../../styles/login-rut.css';
import GertyLogo from '../../images/gerty-blue.png';
import IconSmile from '../../images/icons-smile.svg';
import '../../styles/validation.css'
import GertyCloud from '../../images/gerty-cloud.svg';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import DownloadAppSection from "../DownloadAppSection";

export default function NoVerification() {
    const { currentUser } = useAuth();

    return (
        <div className="background-no-pending">
            <img className='gerty-pending'
                src={GertyLogo}
                alt="GertyId Logo" />
            <img className='no-pending-icon'
                src={IconSmile}
                alt="Smile" />
            <h1 className="no-verification" >No tienes<br></br> verificaciones pendientes</h1>
            <img className='gerty-cloud'
                    src={GertyCloud}
                    alt="Logo Gerty cloud" />
            <DownloadAppSection/>
            {currentUser ? ( 
              <div className="button is-link" id="button-no-pending">
                <Link to="/">Volver a inicio</Link>
              </div>

            ): (<div></div>
            )}   
        </div>
      );
}