import React from "react";
import '../../styles/capture.css';
import '../../styles/slider.css';
import TakePictureCarnet from '../validacionRut/CaptureCarnetFront';
import cedula_nueva from '../../images/dnis/colombia_nuevo.png';
import cedula_vieja from '../../images/dnis/colombia_antiguo.png';

function ChoiceDocColombia({props, setIsCountrySelected}) {

  // de momento para extranjeros usaremos el sistema antiguo
  const handleOld = () => {
    setIsCountrySelected(true)
    props.setCarnetNuevo(-1)
  }
  if (props.carnetNuevo === -1) {
    return TakePictureCarnet(props)
  }

  return (
    <>
      <h1 className="country_select__title">¿Qué Cédula de Ciudadanía tiene?</h1>
      <div className="country_select__container">
        <img className="button-img" onClick={handleOld} src={cedula_nueva} alt={"Colombia CC nuevo"}/>
        <img className="button-img" onClick={handleOld} src={cedula_vieja} alt={"Colombia CC antiguo"}/>
      </div>
    </>
  )

}

export default ChoiceDocColombia;