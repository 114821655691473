import React from 'react';
import IconLoading from '../images/icon-loading.png';
import GertyBlue from '../images/gerty-blue.png';
import '../styles/alert-info.css'

export default function AlertInfoRut(props) {
  const renderDni = () => {
    if (!props.data.dni_type || props.data.dni_type === "rut") {
      return `rut ${props.data.formatted_rut}`
    } else {
      return `identificador ${props.data.dni}`
    }
  }
  const renderMessage = () => {
    if(props.data.is_manual_verification){
      return <p>Estamos validando su identidad, esto puede tardar unos segundos</p>
    }else{
      return <p>Estamos validando tu identidad, esto puede tardar unos segundos</p>
    }
  }
  return (
    <div className="alert-info-rut">
      <img className='gerty-info-rut'
        src={GertyBlue}  
        alt="gerty logo"
        width="50%"
        height="50%"
        
      />
      <h1 className='verifying-h1'>Verificando {renderDni()}</h1>
      <img className='icon-loading'
        src={IconLoading}  
        alt="loading icon"
      />
      {renderMessage()}
    </div>
  )
}