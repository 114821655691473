import React from "react";
import RenderQRCode from "../components/QRCode";
import "../styles/locations.css";
import "../styles/dashboard_alert.css";
import DownloadImgModal from "./DownloadImgModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import Sweetalert2 from "sweetalert2";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import {useStateIfMounted} from "use-state-if-mounted";

export default function Location(props) {
  const { currentUser, handleUserLogout } = useAuth();
  const currentLocation = props.activeLocation
  const QRValue = window.location.origin+"/v/"+currentLocation.id
  const [modalShow, setModalShow] = useStateIfMounted(false)
  const MyAlert = withReactContent(Sweetalert2)
  const [editingName, setEditingName] = useStateIfMounted(false)
  const onModalHide = () => {
    setModalShow(false)
  }
  const renderTitle = () => {
    return (
      <div className={"modal-title-section"}>
        <div className={"modal-title-section__title"}>
          Código QR
        </div>
        <div className={"modal-title-section__subtitle"}>
          {currentLocation.name}
        </div>
      </div>
    )
  }
  const sentUpdateRequest = async (key, value) => {
    let form_data = new FormData(),
      token = currentUser.access,
      commerce_id = currentUser.user.commerce_id,
      id = currentLocation.id
    form_data.append(key, value)
    try {
      return await axios({
        url: process.env.REACT_APP_BACKEND_URL + "/commerce/" + commerce_id + "/branches/" + id + "/patch/",
        method: 'PATCH',
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + token
        },
        data: form_data
      })
    } catch (e) {
      if (e.response) {
        return e.response;
      }
    }
  }
  const updateLocation = async (key, value, setErrors) => {
    if(currentUser){
      const response2 = await sentUpdateRequest(key, value);
      if (response2.status !== 200){
        if (response2.status !== 400 && response2.status !== 500) {
          return handleUserLogout();
        }
        return setErrors !== undefined && setErrors({ new_location_name: response2.data.error })
      }else{
        setEditingName(false)
        return props.afterUpdate(response2.data)
      }
    } else {
      return handleUserLogout();
    }
  }
  const onActiveDeactiveError = (_) => {
    let title = 'No se pudo actualizar la ubicación',
      message_1 = 'Intente nuevamente',
      confirmBtnMsg = 'Ok',
      customClass = {
        htmlContainer: 'dashboard-alert__container',
        popup: 'dashboard-alert__popup',
        closeButton: 'dashboard-alert__closeButton',
        confirmButton: 'dashboard-alert__confirmButton',
        cancelButton: 'dashboard-alert__cancelButton',
        loader: 'dashboard-alert__loader',
        title: 'dashboard-alert__title'
      }
    MyAlert.fire({
      title: "",
      html: <div><h4>{title}</h4><p>{message_1}</p></div>,
      showCloseButton: true,
      confirmButtonText: confirmBtnMsg,
      focusConfirm: true,
      customClass: customClass,
      icon: 'error',
    })
  }
  const ActivateDeactivateMsg = ($type) => {
    let title = 'Desactivar ubicación',
      message_1 = '¿Estás seguro de que quieres desactivar esta ubicación?',
      message_2 = 'Su código QR quedará desactivado',
      confirmBtnMsg = 'Si, deshabilitar',
      customClass = {
        htmlContainer: 'dashboard-alert__container',
        popup: 'dashboard-alert__popup',
        closeButton: 'dashboard-alert__closeButton',
        confirmButton: 'dashboard-alert__confirmButton',
        cancelButton: 'dashboard-alert__cancelButton',
        loader: 'dashboard-alert__loader',
        title: 'dashboard-alert__title'
      }
    if ($type === 'activate') {
      title = 'Activar ubicación'
      message_1 = '¿Estás seguro de que quieres activar esta ubicación?'
      message_2 = 'Su código QR quedará activado'
      confirmBtnMsg = 'Si, habilitar'
    }
    MyAlert.fire({
      title: title,
      html: <div><p>{message_1}</p><p>{message_2}</p></div>,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: confirmBtnMsg,
      focusConfirm: true,
      customClass: customClass,
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateLocation("active", $type === 'activate', onActiveDeactiveError)
      }
    })
  }
  const ActivateLocationBtn = () => {
    return (
      <div className={"location-footer__deactivate_location"} onClick={() => ActivateDeactivateMsg('activate')}>
        Activar ubicación
      </div>
    )
  }
  const DeactivateLocationBtn = () => {
    return (
      <div className={"location-footer__deactivate_location"} onClick={() => ActivateDeactivateMsg('deactivate')}>
        Desactivar ubicación
      </div>
    )
  }
  const sendNewName = async (values, setErrors) => {
    let {new_location_name} = values
    await updateLocation("name", new_location_name, setErrors)
  }
  const EditNameForm = () => {
    return (
      <>
        <Formik
          initialValues={
            {
              new_location_name: currentLocation.name
            }
          }
          validationSchema={
            Yup.object({
              new_location_name: Yup.string()
                .required('Este campo es obligatorio')
            })
          }
          onSubmit={
            async (values, { setErrors }) => {
              await sendNewName(values, setErrors)
            }
          }
        >
          {({ errors, touched, resetForm }) => (
            <Form className={"location-title-section__edit_form" + (errors.new_location_name && touched.new_location_name ? " input-has-error" : "")}>
              <div className={"location-title-section__edit_form_body"}>
                <Field
                  type="text"
                  className="location-title-section__edit_input"
                  name="new_location_name"
                  placeholder="Nombre de la sucursal"
                />
                <div className={"location-title-section__btns_container"}>
                  <button type={"submit"} className={"location-title-section__confirm_btn"}>Aceptar</button>
                  <div className={"location-title-section__cancel_btn"} onClick={() => {setEditingName(false); resetForm()}}>Cancelar</div>
                </div>
              </div>
              {
                errors.new_location_name && touched.new_location_name && (
                  <div className="location-title-section__error">{errors.new_location_name}</div>
                )
              }
            </Form>
          )}
        </Formik>
      </>
    )
  }
  return (
    <div className={"location-container"}>
      <div className={"location-body-section"}>
        <div className={"location-title-section"}>
          {editingName ?
            (
              <EditNameForm />
            ) :
            (
              <>
                <div className={"location-title-section__name"}>
                  {currentLocation.name}
                </div>
                <FontAwesomeIcon icon={faPen} onClick={() => setEditingName(true)} className="location-title-section__edit_icon" />
              </>
            )
          }
        </div>
        <div className={"location-qr-section"}>
          <div className={"location-qr-section__title"}>
            Código QR
          </div>
          <div className={"location-qr-section__message"}>
            Usa este código para que tus clientes validen su identidad en esta ubicación
          </div>
          <div className={"location-qr-section__container"}>
            <RenderQRCode value={QRValue} size={190} />
          </div>
          <div className={"location-qr-section__download_qr"} onClick={() => setModalShow(true)}>
            Descargar código
          </div>
        </div>
      </div>
      <div className={"location-footer-section"}>
        <div className={"location-footer__created_at"}>
          {currentLocation.created_at}
        </div>
        {currentLocation.active ? <DeactivateLocationBtn /> : <ActivateLocationBtn />}
      </div>
      <DownloadImgModal
        show={modalShow}
        onHide={() => onModalHide()}
        activeId={"qr_ubicacion"}
        title={renderTitle()}
        prevDownloadName={currentLocation.name.trim().replace(" ", "_").toLowerCase()}
      >
        <RenderQRCode value={QRValue} size={350} id={"qr_ubicacion"}  />
      </DownloadImgModal>
    </div>
  )
}