import React from "react";

export function SideBar(props) {
  const paths = props.paths
  const currentPathname = window.location.pathname
  const getActivePathClass = (path) => {
    return currentPathname === path ? " active" : ""
  }
  return (
    <nav className="admin-layout__sidebar" id="sidebar">
      <a className={"admin-layout__sidebar-link" + getActivePathClass(paths.overview)} href={paths.overview}>
        <div className="circle">
          <i className="fa fa-home" />
        </div>
        Nueva Verificación
      </a>
      <a className={"admin-layout__sidebar-link" + getActivePathClass(paths.verifications)} href={paths.verifications}>
        <div className="circle">
          <i className="fa fa-home" />
        </div>
        Verificaciones
      </a>
      <a className={"admin-layout__sidebar-link" + getActivePathClass(paths.locations)} href={paths.locations}>
        <div className="circle">
          <i className="fa fa-home" />
        </div>
        Ubicaciones
      </a>
    </nav>
  )
}