import Alert from 'react-bootstrap/Alert'
import React, {useEffect, useState} from 'react';
import '../styles/verification-status.css';

export default function NewVerificationStatus (props) {

  const [type, setType] = useState('');
  const [message, setMessage] = useState('');

  const setVariables = () => {
    switch (props.page) {
      case 2:
        setType("danger");
        setMessage(`Hubo un problema al crear la verificación, por favor revise que haya ingresado los datos correctamente`);
        break;
      case 1:
        setType("success");
        setMessage(`Verificación del rut ${props.rut} fue creada, se enviará al correo ${props.email}`);
        break;
      default:
        setType("info");
        setMessage("Creando verificación");
        break;
    }
  }

  useEffect(() => {
    return setVariables();
  });

  return (
    <Alert variant={type} className="state-info">
      {message}
    </Alert>
  )
}