import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import '../styles/login.css';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import GertyLogoWhite from '../images/gerty-white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';


export default function Login(props) {
  const [message, setMessage] = useState('');
  const { currentUser, handleUserLogin } = useAuth();
  const history = useHistory();
  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (newData) => {
    let formdata = new FormData()

    formdata.append('email', newData.email)
    formdata.append('password', newData.password)

    try {
      let res = await axios({
        url: process.env.REACT_APP_BACKEND_URL + "/login/",
        method: 'POST',
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formdata
      });

      if (!res.data.error) {
        handleUserLogin(res.data);
      }
      else {
        if (res.data.error === 'email') {
          history.push({pathname: "/email-code", state: {email: res.data.user.email, id: res.data.user.user_id, rut: res.data.user.rut, password: newData.password, token: res.data.access, authorization: true}})
        }
        else if (res.data.error === 'face') {
          history.push({pathname: "/capture", state: {rut: res.data.user.rut, email: res.data.user.email, password: newData.password, token: res.data.access, authorization: true, type: 'normal'}})
        }
        else if (res.data.error === 'emotion') {
          history.push({pathname: "/capture", state: {rut: res.data.user.rut, email: res.data.user.email, password: newData.password, token: res.data.access, authorization: true, type: 'normal'}})
        }
      }
    } catch (error) {
      setMessage('Email y/o contraseña inválido');
    }
  }

  if (currentUser){
    if (props.location.state && props.location.state.referrer === '/locations') {
      return <Redirect to='/locations' />;
    }
    if(currentUser.type === "commerce"){
      return <Redirect to='/verifications' />;
    }
    else{
      return <Redirect to='/home' />;
    }
  }

  return (
    <div className="background-form-login">
      <img className='gerty-white'
        src={GertyLogoWhite}
        alt="GertyId Logo" />
      {/* Por los momentos no soportaremos registro de usuarios */}
      {/*<h4 id="nuevo-en-gerty"><span id="span-login">¿Nuevo en Gerty?</span> <Link to="/register" id="nuevo-en-gerty">Crea tu cuenta</Link></h4>*/}
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={async (values) => {
          handleSubmit(values)
        }
        }
      >
        {({ errors, touched }) => (
          <Form>
            <div className="field-login">
              <Field className="input-login-email" name="email" placeholder="Email" />
              {errors.email && touched.email && (
              <div>{errors.email}</div>
              )}
              <div className="input-icons">
                <FontAwesomeIcon className="lash-icon" icon={!values.showPassword? faEyeSlash : faEye} onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword} id="arrow-icon-eye"/>
                <Field className="input-login-password"  id="pwd" name="password" placeholder="Contraseña" type={values.showPassword ? "text" : "password"}
                 />
                {errors.password && touched.password && (
                <div>{errors.password}</div>
              )}
              </div>
            </div>
            {message && (<p>{message}</p>)}
            <button className="button is-link" id="button-signin" type="submit">Inicia Sesión</button>
            {/* Por los momentos no soportaremos login con google ni recuperar contraseña */}
            {/*<h4 id="forgot-password">¿Olvidaste tu contraseña?</h4>*/}
            {/*<h4 id="o">o</h4>*/}
            {/*<GoogleSocialAuth />*/}
          </Form>
        )}
      </Formik>
    </div>
  );
}
