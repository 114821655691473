import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import '../src/styles/fonts/Avenir-Next-Font.ttf';
import { Provider, ErrorBoundary } from '@rollbar/react'; // <-- Provider imports 'rollbar' for us
import Routes from './Routes';
import reportWebVitals from './reportWebVitals';
import {OpenCvProvider} from "opencv-react";

// same configuration you would create for the Rollbar.js SDK
const _rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: 'production'
};

ReactDOM.render(
  <React.StrictMode>
    <Provider config={_rollbarConfig}>
      <ErrorBoundary>
        <OpenCvProvider>
          <Router>
            <Routes />
          </Router>
        </OpenCvProvider>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
