import React, {useCallback, useEffect, useState} from "react";
import '../../styles/validation.css';
import IconError from '../../images/icon-error.svg';
import getAccessToken from "../../utils/getAccessToken";
import axios from "axios";
import Loading from "../loading";
import EvaluationHeader from "./EvaluationHeader";
import {currencyFormat} from "../autoPhoto/utils_functions";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import { Rating } from 'react-simple-star-rating';

export default function EvaluationPaymentDetails (props) {
  const [evaluationDetails, setEvaluationDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const verification_token = props.data.token;
  const [rating, setRating] = useState(0);
  const [emailLoading, setEmailLoading] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);
  
  const starsQuantity = 5;
  
  const getEvaluationDetails = useCallback(async () => {
    setLoading(true)
    try {
      let token = await getAccessToken,
        data = new FormData()
      data.append("token", verification_token)
      data.append("reception", props.data.reception)
      let response = await axios({
        method: 'POST',
        url: process.env.REACT_APP_BACKEND_URL + "/validation/evaluation_details/",
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + token
        },
        data: data
      })
      setEvaluationDetails(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [verification_token]);
  
  const sendRating = useCallback(async (rate) => {
    try {
      setRatingLoading(true)
      let token = await getAccessToken,
        data = new FormData()
      data.append("verification_token", verification_token)
      data.append("reception", props.data.reception)
      data.append("rate", rate)
      await axios({
        method: 'POST',
        url: process.env.REACT_APP_BACKEND_URL + "/validation/evaluation_rating/",
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + token
        },
        data: data
      })
      setRatingLoading(false)
      setRating(rate)
    } catch (error) {
      setRatingLoading(false)
    }
  }, [verification_token]);
  
  const sendEmail = useCallback(async () => {
    try {
      setEmailLoading(true)
      let token = await getAccessToken,
        data = new FormData()
      data.append("verification_token", verification_token)
      data.append("reception", props.data.reception)
      data.append("email", email)
      await axios({
        method: 'POST',
        url: process.env.REACT_APP_BACKEND_URL + "/validation/evaluation_email/",
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + token
        },
        data: data
      })
      setEmailLoading(false)
    } catch (error) {
      setEmailLoading(false)
    }
  }, [verification_token, email]);
  
  const toggleArrow = () => {
    setShowEmailInput(!showEmailInput)
  }
  
  const handleRating = (rate) => {
    sendRating( (rate * starsQuantity) / 100 )
  }
  
  const handleRetry = () => {
    getEvaluationDetails()
  }
  
  const handleSubmit = () => {
    if (!btnDisabled) {
      sendEmail()
    }else if (!showEmailInput) {
      toggleArrow()
    }
  }
  
  const checkEmail = (value) => {
    var validRegex = /^[^@]+@[^\.]+\..+$/;
    setBtnDisabled(!value.match(validRegex))
  }
  
  const handleChange = (e) => {
    let value = e.target.value
    setEmail(value)
    checkEmail(value)
  }
  
  useEffect(() => {
    getEvaluationDetails()
  }, [getEvaluationDetails])
  
  if (loading) {
    return (
      <div className="container-validation">
        <div className="container">
          <Loading/>
        </div>
      </div>
    )
  }
  
  let evaluation = (evaluationDetails && evaluationDetails.evaluation ? evaluationDetails.evaluation : null),
    provider = (props.data.commerce_name ? props.data.commerce_name : ""),
    date = moment().format("DD/MM/YYYY hh:mm"),
    evaluation_total_refund = 0,
    evaluation_copayment = 0,
    document_gertys = [],
    document_bills = [],
    document_insurances = []
  
  if (evaluation) {
    evaluation_total_refund = evaluation.total_refund + evaluation.total_deductible
    evaluation_copayment = evaluation.copayment
    document_gertys = [
      evaluation.id
    ]
    if (evaluation.documents) {
      document_bills = evaluation.documents.filter((documents) => !!documents.number).map((documents) => documents.number)
    }
    if (evaluation.insurances) {
      document_insurances = evaluation.insurances.map((insurance) => insurance.confirmation_id)
    }
  }
  
  return (
    <div className={"evaluation-details evaluation-details--payment container-validation" + (showEmailInput ? "" : " evaluation-details__footer-closed")}>
      <EvaluationHeader/>
      { evaluation ? (
        <div className="container">
          <div className={"evaluation-details--container"}>
            <div className={"evaluation-details--body"}>
              <div className={"evaluation-details--header-message"}>
                <div className={"evaluation-details--header-message__name"}>
                  Pago exitoso
                </div>
                <div className={"evaluation-details--header-message__message"}>
                  Resumen de tu transacción
                </div>
              </div>
              <div className={"evaluation-details--details"}>
                <div className={"evaluation-details--details__provider"}>
                  {provider}
                </div>
                <div className={"evaluation-details--details-row evaluation-details--details__date"}>
                  <div className={"evaluation-details--details-col"}>
                    {date}
                  </div>
                </div>
                <div className={"evaluation-details--details-row evaluation-details--details__copayment"}>
                  <div className={"evaluation-details--details-col"}>
                    Monto pagado
                  </div>
                  <div className={"evaluation-details--details-col"}>
                    {currencyFormat(evaluation_copayment)}
                  </div>
                </div>
              </div>
            </div>
            <div className={"evaluation-details--resume"}>
              <div className={"evaluation-details--resume-row"}>
                <div className={"evaluation-details--resume-col"}>
                  Cobertura Seguros
                </div>
                <div className={"evaluation-details--resume-col"}>
                  {currencyFormat(evaluation_total_refund)}
                </div>
              </div>
              {document_bills.length > 0 && (
                <div className={"evaluation-details--resume-row"}>
                  <div className={"evaluation-details--resume-col"}>
                    Folio Boleta
                  </div>
                  <div className={"evaluation-details--resume-col"}>
                    {document_bills.map((document_bill) => (
                      <div key={document_bill} className={"evaluation-details--resume-col-item"}>
                        {document_bill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {document_insurances.length > 0 && (
                <div className={"evaluation-details--resume-row"}>
                  <div className={"evaluation-details--resume-col"}>
                    Folio Seguro
                  </div>
                  <div className={"evaluation-details--resume-col"}>
                    {document_insurances.map((document_insurance) => (
                      <div key={document_insurance} className={"evaluation-details--resume-col-item"}>
                        {document_insurance}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {document_gertys.length > 0 && (
                <div className={"evaluation-details--resume-row"}>
                  <div className={"evaluation-details--resume-col"}>
                    Folio Gerty
                  </div>
                  <div className={"evaluation-details--resume-col"}>
                    {document_gertys.map((document_gerty) => (
                      <div key={document_gerty} className={"evaluation-details--resume-col-item"}>
                        {document_gerty}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className={"evaluation-details--rating"}>
              {ratingLoading ?
                <Loading/>
              :
                (
                  <>
                    {rating === 0 ?
                      (
                        <>
                          <div className={"evaluation-details--rating-message"}>
                            Evalúa tu experiencia
                          </div>
                          <div className={"evaluation-details--rating-stars"}>
                            <Rating size={45} iconsCount={starsQuantity} onClick={handleRating} ratingValue={rating} /* Available Props */ />
                          </div>
                        </>
                      )
                    :
                      <div className={"evaluation-details--rating-thanks"}>
                        ¡Muchas gracias!
                      </div>
                    }
                  </>
                )
              }
            </div>
          </div>
          <div className={"evaluation-details--footer"}>
            <div className={"evaluation-details--footer__arrow"}>
              <FontAwesomeIcon onClick={toggleArrow} icon={showEmailInput ? faChevronDown : faChevronUp} />
            </div>
            <div className={"evaluation-details--footer__message"}>
              Ingresa tu email si quieres una copia de los detalles de tu transacción.
            </div>
            <div className={"evaluation-details--footer__email-input"}>
              <input type={"email"} value={email} onChange={handleChange}/>
            </div>
            <div className={"evaluation-details--actions"}>
              <button onClick={handleSubmit} className={"evaluation-details--actions-btn" + (btnDisabled ? " evaluation-details--actions-btn__disabled" : "")}>
                {emailLoading ? (
                  <Loading/>
                ) : (
                  <>
                    Enviar detalle por email
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        // No tenemos información
        <div className="container with-no-evaluation">
          <div className='figure' id="circle">
            <img className='icon-error'
                 src={IconError}
                 alt="error icon" />
          </div>
          <h1 className="text-complete-verification">Lo sentimos</h1>
          <h4 className="verification-complete-paragraph">No hemos podido obtener la bonificación de tu seguro</h4>
          <div className="validation-failed-buttons">
            <button onClick={handleRetry} className="validation-failed-button try-again-btn w-100">Reintentar</button>
          </div>
        </div>
      )}
    </div>
  );
}