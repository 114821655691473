import '../styles/navbar-organization.css';
import '../styles/admin_layout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import {TopBar} from "./adminLayout/TopBar";
import {SideBar} from "./adminLayout/SideBar";
import Loading from "./loading";

export default function AdminLayout(props) {

  const paths = {
    login: "/login",
    verifications: "/verifications",
    overview: "/overview",
    locations: "/locations"
  }

  const getTitleByURI = () => {
    switch (window.location.pathname) {
      case "/verifications":
        return "Verificaciones";
      case "/overview":
        return "Nueva Verificación";
      case "/locations":
        return "Ubicaciones";
      default:
        return "Resumen";
    }
  }

  const renderTitle = () => {
    if (props.title === undefined) {
      return getTitleByURI()
    }
    return props.title
  }

  const renderHelp = () => {
    if (props.helpSection === undefined) {
      return (
        <a className="help-text" href="/#">¿Necesitas ayuda?</a>
      )
    }
    return (
      props.helpSection
    )
  }

  return (
    <div className="admin-layout">
      <TopBar paths={paths} />
      <div className="admin-layout__body">
        <SideBar paths={paths} />
        <div className={"admin-layout__content" + (props.loading === true ? " admin-layout__content_loading" : "")}>
          {props.loading === true ? <Loading /> : (
            <>
              <div className="admin-layout__content-title-container">
                <div className="admin-layout__content-title-title">
                  {renderTitle()}
                </div>
                <div className="admin-layout__content-title-help">
                  {renderHelp()}
                </div>
              </div>
              <div className="admin-layout__content-content">
                {props.children}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

