import React, {useEffect, useState} from "react";
import '../../styles/capture.css';
import '../../styles/slider.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChoiceOldOrNew from "./choice_old_or_new";
import GertyLogo from "../../images/gerty-blue.png";
import FlagButton from "./flag_button";
import ChoiceDocMexico from "./choice_doc_mexico";
import ChoiceDocColombia from "./choice_doc_colombia";
import ChoiceDocEcuador from "./choice_doc_ecuador";

function ChoiceCountry(props) {
  const [country, setCountry] = [props.country, props.setCountry];
  const [isCountrySelected, setIsCountrySelected] = useState(false);

  const choiceHandler = (country) => {
    props.data.document_country = country
    setCountry(country)
  }

  let displayChosenCountry = null
  if (country === "CL") {
    displayChosenCountry = <ChoiceOldOrNew props={props} setIsCountrySelected={setIsCountrySelected}/>
  } else if (country === "CO") {
    displayChosenCountry = <ChoiceDocColombia props={props} setIsCountrySelected={setIsCountrySelected}/>
  } else if (country === "EC") {
    displayChosenCountry = <ChoiceDocEcuador props={props} setIsCountrySelected={setIsCountrySelected}/>
  } else if (country === "MX") {
    displayChosenCountry = <ChoiceDocMexico props={props} setIsCountrySelected={setIsCountrySelected}/>
  }

  useEffect(() => {
    if (props.carnetNuevo !== 0) { // si ya se selecciono un metodo
      setIsCountrySelected(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        !isCountrySelected ?
          <>
            <img className='gerty-verificate-validar'
                 src={GertyLogo}
                 alt="GertyId Logo"/>
            <div>
              <h1 className="title-capture">Selecciona la nacionalidad del documento de identidad</h1>
            </div>

            <div className="flag-container">
              <FlagButton isSelected={"CL" === country} onClick={() => choiceHandler("CL")}
                          countryName="chile"/>
              <FlagButton isSelected={"CO" === country} onClick={() => choiceHandler("CO")}
                          countryName="colombia"/>
              <FlagButton isSelected={"EC" === country} onClick={() => choiceHandler("EC")}
                          countryName="ecuador"/>
              <FlagButton isSelected={"MX" === country} onClick={() => choiceHandler("MX")}
                          countryName="méxico"/>
            </div>
          </>
          :
          null
      }
      {displayChosenCountry}
    </>
  )
}

export default ChoiceCountry;