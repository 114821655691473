// import {useOpenCv} from "opencv-react";
import {Contour_correct_size_proportion} from "./utils_functions";

function Detect_blue_aside_red(cv, cnt, hsv) {
    // const { loaded, cv } = useOpenCv();

    let rect = cv.boundingRect(cnt);

    let xmin = rect.x
    // let xmax = xmin + rect.width - 1
    let ymin = rect.y
    // let ymax = ymin + rect.height - 1
    let lw = rect.width
    let lh = rect.height
    // # margin = Math.floor(lh / 5)
    let margin = 0
    let sx1 = Math.max(xmin - margin, 0)
    let sx2 = Math.min(xmin + Math.floor(lw / 3) + margin, hsv.matSize[1])
    let sy1 = Math.max(ymin - lh - margin, 0)
    let sy2 = Math.min(ymin + margin, hsv.matSize[0])
    // # search_section = rgb[sy1:sy2, sx1:sx2]

    // esta función tiene este formato: new cv.Rect(x1, y1, width, height) (esto es al reves que en python)
    let rect_search = new cv.Rect(sx1, sy1, sx2 - sx1, sy2 - sy1);
    let search_section_hsv = hsv.roi(rect_search);
    // # search_section_hsv = hsv[max(ymin - lh, 0):ymin, xmin:min(xmin + Math.floor(lw / 3) +1, hsv.matSize[1])]
    if (ymin === 0 || xmin === hsv.matSize[1])
        return false

    // # HSV BLUE
    let lower = new cv.Mat(search_section_hsv.rows, search_section_hsv.cols, search_section_hsv.type(), [104, 0, 0, 0]);
    let upper = new cv.Mat(search_section_hsv.rows, search_section_hsv.cols, search_section_hsv.type(), [155, 255, 255, 255]);
    let section_mask = new cv.Mat();
    cv.inRange(search_section_hsv, lower, upper, section_mask)

    let [h, w]= section_mask.matSize
    // console.log(section_mask.data.reduce(function(pv, cv) { return pv + parseInt(cv); }, 0)/255)
    // sumamos los pixeles de las mascara, debiesen ser 255 los que son "true", por lo que dividimos por 255
    let total_blues = section_mask.data.reduce(function(pv, cv) { return pv + cv; }, 0) / 255
    let blue_proportion = total_blues / (h * w)
    search_section_hsv.delete()
    lower.delete()
    upper.delete()
    section_mask.delete()
    return blue_proportion > 0.33;
}

function is_good_red_contour(cv, contour, referece, width_prop, heigh_prop, w_ranges = [0.6, 1.66], h_ranges = [0.6, 1.66], self_prop = null, s_ranges = null, debug = null) {
    // console.log("is_good_red_contour");
    return Contour_correct_size_proportion(cv,contour, referece, width_prop, heigh_prop, w_ranges, h_ranges, self_prop, s_ranges, debug)
    // return ansbool
}


export default function Detect_flag(cv, img, outcanvan="",isMat=false, spected_pos = null) {
    // El componente donde se llama esta funcion, se le debe pasar cv desde "const {cv} = useOpenCv();"
    // TODO: revisar formato input, en estos momentos img se espera que sea formato cv.Mat
    // opcionalmente podría ser formato "ImageData" revisar https://docs.opencv.org/3.4/df/d24/tutorial_js_image_display.html
    // img = cv.matFromImageData(imgData)
    // const { loaded, cv } = useOpenCv();

    if (!isMat) {
        if (typeof(img) === "string" ){
            img = cv.imread(img);
        }
        else {
            console.log("Error input Detect_flag, es un Mat en vez de string?")
            return false
        }
    }

    let [h, w] = img.matSize
    let hbound = Math.floor(h / 2)
    let wbound = Math.floor(w / 2)
    // let hsv = new cv.Mat();
    // esta función tiene este formato: new cv.Rect(x1, y1, width, height) (esto es al reves que en python)
    let rect = new cv.Rect(0, 0, wbound, hbound);
    let hsv = img.roi(rect);  // aqui se recorta la imagen a analizar al cuarto arriba izquierda
    cv.cvtColor(hsv, hsv, cv.COLOR_RGBA2RGB);
    cv.cvtColor(hsv, hsv, cv.COLOR_RGB2HSV);

    let lower = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), [0, 55, 40, 0]);
    let upper = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), [7, 255, 255, 255]);
    let hsv_mask1 = new cv.Mat();
    cv.inRange(hsv, lower, upper, hsv_mask1)

    lower.delete()
    upper.delete()
    lower = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), [170, 55, 40, 0]);
    upper = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), [255, 255, 255, 255]);
    let hsv_mask2 = new cv.Mat();
    cv.inRange(hsv, lower, upper, hsv_mask2)
    let hsv_mask = new cv.Mat()
    cv.bitwise_or(hsv_mask1, hsv_mask2, hsv_mask) // unimos ambos filtros para el rojo hsv

    let closing_hsv = new cv.Mat();
    let M = cv.Mat.ones(3, 3, cv.CV_8U);
    // You can try more different parameters
    cv.morphologyEx(hsv_mask, closing_hsv, cv.MORPH_CLOSE, M);

    let contours = new cv.MatVector();
    let hierarchy = new cv.Mat();
    // en python se usaba RETR_TREE en vez de RETR_LIST, RETR_LIST debiese ser más rapido, no usa el hierarchy
    cv.findContours(closing_hsv, contours, hierarchy, cv.RETR_LIST, cv.CHAIN_APPROX_SIMPLE);

    let good_red = [];
    for (let i = 0; i < contours.size(); ++i) {
        let cnt = contours.get(i);
        // if (is_good_red_contour(cnt, w, 290 / 4117, 96 / 4117,undefined,undefined,self_prop = 96 / 290, s_ranges = [0.6, 1.9])) {
        if (is_good_red_contour(cv, cnt, w, 290 / 4117, 96 / 4117,undefined,undefined, 96 / 290, [0.6, 1.9])) {
            // # cv2.drawContours(good_red_mask, [contour], -1, 1, -1)
            good_red.push(cnt)
            continue
        }
        cnt.delete()
    }
    let blue_and_red = [];
    for (let i = 0; i < good_red.length; ++i) {
        if (Detect_blue_aside_red(cv, good_red[i], hsv)) {
            // # cv2.drawContours(good_blue_mask, [good_red[i]], -1, 1, -1)
            blue_and_red.push(good_red[i])
            continue
        }
        good_red[i].delete()
    }
    hsv.delete()
    lower.delete()
    upper.delete()
    hsv_mask1.delete()
    hsv_mask2.delete()
    hsv_mask.delete()
    closing_hsv.delete()
    M.delete()
    contours.delete()
    hierarchy.delete()

    if (blue_and_red.length === 1) {
        // console.log("bandera detected")

        let rect = cv.boundingRect(blue_and_red[0]);

        let xmin = rect.x
        let x = xmin + rect.width - 1
        let ymin = rect.y
        let y = ymin + rect.height - 1

        // # dprint("x, y" , x, y)

        // cv.circle(img, {x: x, y: y}, radius=2, [255,255,0, 255],thickness=-1)
        cv.circle(img, {x: x, y: y}, 2, [255,255,0, 255],-1)
        if (spected_pos){ // para debugear
            cv.rectangle(img, spected_pos.p1, spected_pos.p2, [155,155,255, 55],2)
            cv.circle(img, {x: (spected_pos.p1.x + spected_pos.p2.x) /2, y: (spected_pos.p1.y + spected_pos.p2.y) /2}, 4, [100,255,100, 255],-1)

        }
        if (outcanvan) { // si en el html no hay un img o <canvas> con este id va a crashear
            cv.imshow(outcanvan, img)
        }
        blue_and_red[0].delete()
        // console.log("leght good_red", good_red.length)
        // good_red[0].delete()
        //
        // for (let i = 0; i < good_red.length; ++i) {
        //     try {
        //      good_red[i].delete()
        //         console.log("no hubo error ", i)
        //     } catch (e) {
        //         console.log(e)
        //     }
        //
        // }
        return [x, y];
    } else if (blue_and_red.length > 1) {
        // console.log("varias banderas detectadas, probar de nuevo");

        for (let i = 0; i < blue_and_red.length; ++i) {
            blue_and_red[i].delete()
        }
        // # print(blue_and_red)
    } else {
        // pass
        // console.log("no se encontró bandera")
    }
    return false;
}