// import {useOpenCv} from "opencv-react";
import {Contour_correct_size_proportion, euclidian_dist} from "./utils_functions";

let QR_W_PROP = 19/705;
let QR_H_PROP = 19/705;
let W_TOLERANCE = [0.25, 1.2];
let H_TOLERANCE = [0.25, 1.2];
let QR_HW_PROP = 1;
let HW_TOLERANCE = [0.7, 1.428];


export default function Detect_qr_pos(cv, img, isMat = false,outcanvan="", tresh = 160, mode = null, qr_width_aprox = 0.85, average_const = 0, op1_size = 1, closing_size = 6, op2_size = 2) {
    // retorna [p1,p2,p3] coordenadas top-left, bot-left y top-righ del qr respectivamente
    // El componente donde se llama esta funcion, se le debe pasar cv desde "const {cv} = useOpenCv();"
    // TODO: revisar formato input, en estos momentos img se espera que sea formato cv.Mat
    // opcionalmente podría ser formato "ImageData" revisar https://docs.opencv.org/3.4/df/d24/tutorial_js_image_display.html
    // img = cv.matFromImageData(imgData)
    // const {cv} = useOpenCv();

    if (!isMat) {
        if (typeof (img) === "string") {
            img = cv.imread(img);
        } else {
            console.log("Error input Detect_qr_pos, es un Mat en vez de string?")
            return false
        }
    }

    let [h, w] = img.matSize;
    let hbound = Math.floor(h / 2)
    let wbound = Math.floor(w / 2)

    let rect = new cv.Rect(0, 0, wbound, hbound);
    let gray = img.roi(rect);  // aqui se recorta la imagen a analizar al cuarto arriba derecha
    cv.cvtColor(gray, gray, cv.COLOR_RGBA2GRAY);

    // # hsv_mask= cv.Mat.ones((h,w))
    // # hsv_mask = hsv_mask[:hbound, wbound:]

    let hsv = img.roi(rect)
    cv.cvtColor(hsv, hsv, cv.COLOR_RGBA2RGB);
    cv.cvtColor(hsv, hsv, cv.COLOR_RGB2HSV);
    let lower = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), [0, 0, 0, 0]);
    let upper = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), [41, 255, 255, 255]);
    let hsv_mask1 = new cv.Mat();
    cv.inRange(hsv, lower, upper, hsv_mask1)

    let kernelcl = cv.Mat.ones(closing_size, closing_size, cv.CV_8U)


    // # qr_width_aprox = Math.floor(19/705 * w * 0.6)

    qr_width_aprox = Math.floor(19/705 * w * qr_width_aprox)
    // # console.log(qr_width_aprox)
    qr_width_aprox += qr_width_aprox % 2 === 0  // # debe ser impar
    // average_const = average_const
    let threshold_gauss = new cv.Mat();
    let adapt_thresh = new cv.Mat();
    cv.adaptiveThreshold(gray, adapt_thresh, 255, cv.ADAPTIVE_THRESH_MEAN_C, cv.THRESH_BINARY_INV, qr_width_aprox, average_const);
    cv.adaptiveThreshold(gray, threshold_gauss, 255, cv.ADAPTIVE_THRESH_GAUSSIAN_C, cv.THRESH_BINARY_INV, qr_width_aprox, average_const);

    // let closing = new cv.Mat();
    // cv.morphologyEx(adapt_thresh, closing, cv.MORPH_CLOSE, kernelcl);

    // segment[hbound, wbound:] = 255 //TODO: agregar esto para asegurar filtrado de algunos contornos
    // segment[:hbound, wbound] = 255

    gray.delete();
    hsv.delete();
    lower.delete();
    upper.delete();
    hsv_mask1.delete();
    kernelcl.delete();
    // closing.delete();
    function analisis_on_segment(segment_analyzed, img) {
        let contours = new cv.MatVector();
        let hierarchy = new cv.Mat();
        // en python se usaba RETR_TREE en vez de RETR_LIST, RETR_LIST debiese ser más rapido, no usa el hierarchy
        cv.findContours(segment_analyzed, contours, hierarchy, cv.RETR_LIST, cv.CHAIN_APPROX_SIMPLE);
        // dprint("contours find")
        // dprint(len(contours))
        let good_contour = {}

        for (let i = 0; i < contours.size(); ++i) {
            let contour = contours.get(i);
            if (!Contour_correct_size_proportion(cv, contour, w, QR_W_PROP, QR_H_PROP, W_TOLERANCE, H_TOLERANCE, QR_HW_PROP, HW_TOLERANCE)) {
                contour.delete();
                continue
            }
            let x
            let y
            let M = cv.moments(contour, false);
            if (M.m00 !== 0) {  // esta comparacion debe hacerse con != y no !==, solo por desconocer el formato de m00
                x = Math.floor(M.m10 / M.m00)
                y = Math.floor(M.m01 / M.m00)
            } else {
                continue
            }
            if (!segment_analyzed.data[y * segment_analyzed.cols + x]) {
                continue
            }

            let onecontour = new cv.MatVector();
            onecontour.push_back(contour);
            // debug visual
            // cv.drawContours(img, onecontour, -1, [255, 0, 0, 255], 1, cv.LINE_8); //<<
            // cv2.drawContours(mask, [contour], -1, 1, -1) //<<
            onecontour.delete()
            let flag = 0
            for (let other in good_contour) {
                let other_aux = other.split(",").map(x => parseInt(x))
                if (euclidian_dist([x, y], other_aux) < 8 / 705 * w) {
                    good_contour[other]++;
                    flag = 1;
                    break
                }
            }
            if (flag === 0) {
                good_contour[x + "," + y] = 1
            }
        }
        // esto es para encontrar los cuadrados dentro de los cuadrados
        let is_qr_point = Object.keys(good_contour).filter((x) => good_contour[x] >= 2)
        // debug visual
        // for (let i = 0; i < is_qr_point.length; i++) {
        //     let [x, y] = is_qr_point[i].split(",").map(x => parseInt(x))
        //     cv.circle(img, {x: x, y: y}, 2, [0, 255, 255, 255], -1)
        // }

        segment_analyzed.delete(); //<<
        contours.delete(); //<<
        hierarchy.delete(); //<<
        // # displaying the image after drawing contours
        // # dprint("len(good_contour)")
        // # dprint(len(good_contour))
        return is_qr_point // ["3,2" , "33,55"]
    }
    let flag = 0;
    let aux = [adapt_thresh, threshold_gauss]
    for (let i=0; i < aux.length; i++ ) {
        let qr_points = analisis_on_segment(aux[i],img)
        if (qr_points.length >= 3) {
            // dprint("qr detected?")
            let l = qr_points.length
            // para todos los trios buscamos que se cumplan las dimensiones del qr
            for (let i = 0; i < l; i++) {
                for (let j = i + 1; j < l; j++) {
                    for (let k = j + 1; k < l; k++) {
                        let points = [qr_points[i].split(",").map(x => parseInt(x)),
                            qr_points[j].split(",").map(x => parseInt(x)),
                            qr_points[k].split(",").map(x => parseInt(x))]

                        let avrg_x = (points[0][0] + points[1][0] + points[2][0]) / 3
                        let avrg_y = (points[0][1] + points[1][1] + points[2][1]) / 3

                        let p_inferior = points.filter((x) => x[1] > avrg_y)[0]
                        let p_supperiores = points.filter((x) => x[1] < avrg_y)
                        let p_principal = p_supperiores.filter((x) => x[0] < avrg_x)[0]
                        let p_derecho = p_supperiores.filter((x) => x[0] > avrg_x)[0]

                        if (p_inferior === undefined || p_principal === undefined || p_derecho === undefined)
                            continue
                        let dists = [euclidian_dist(p_inferior, p_derecho),
                            euclidian_dist(p_inferior, p_principal),
                            euclidian_dist(p_principal, p_derecho)]
                        // aplicamos pitagoras dividiendo para comparar la similitud
                        // margenes de error 10%^2

                        let pitagori_relatio = dists[0] ** 2 / (dists[1] ** 2 + dists[2] ** 2)
                        let isoseles_ratio = dists[1] / dists[2]
                        let side_qr_prop = dists[2]/(95/705*w)
                        function radians_to_degrees(radians) {
                            let pi = Math.PI;
                            return radians * (180 / pi);
                        }
                        let verticalidad = radians_to_degrees(Math.atan2(p_inferior[1]-p_principal[1],p_inferior[0]-p_principal[0]))

                        if (0.81 <= pitagori_relatio && pitagori_relatio <= 1.21 && 0.7 < isoseles_ratio && isoseles_ratio <= 1.42 &&
                        0.8 <= side_qr_prop && side_qr_prop <= 1.2 && 85 <= verticalidad && verticalidad <= 95) {
                            let margin = Math.floor(11 / 705 * w)
                            p_principal = [p_principal[0] - margin, p_principal[1] - margin]
                            p_inferior = [p_inferior[0] - margin, p_inferior[1] + margin]
                            p_derecho = [p_derecho[0] + margin, p_derecho[1] - margin]
                            // console.log("ENCONTRADO")
                            // debug visual
                            let qr_x = Math.min(p_principal[0], p_inferior[0], p_derecho[0])
                            let qr_x2 = Math.max(p_principal[0], p_inferior[0], p_derecho[0])
                            let qr_y = Math.min(p_principal[1], p_inferior[1], p_derecho[1])
                            let qr_y2 = Math.max(p_principal[1], p_inferior[1], p_derecho[1])
                            cv.rectangle(img, {x: qr_x, y: qr_y}, {x: qr_x2, y: qr_y2}, [255, 0, 255, 255], 2)
                            flag = 1
                            if (outcanvan) {
                                cv.imshow(outcanvan, img)
                            }
                            return [p_principal, p_inferior, p_derecho]
                        }
                    }
                }
            }
        }
    }
    if (outcanvan) {
        cv.imshow(outcanvan, img)
    }
    if (flag === 0) {
        // console.log("no se detectó qr")
    }
    return false
}

