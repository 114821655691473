 /* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import { Redirect, useParams, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import '../styles/login-rut.css';
import useAuth from '../hooks/useAuth';
import NoVerification from '../components/validacion/NoVerification';
import axios from 'axios';
import { Label } from 'reactstrap';
import Loading from '../components/loading';
import { format } from 'rut.js';
import ValidarIdentidadFotosAutomaticas from "../components/autoPhoto/ValidarIdentidadFotosAutomaticas";
import getAccessToken from "../utils/getAccessToken";

export default function LoginRut(props) {
  const[verification, setVerification] = useState(false);
  const[pending, setPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { currentUser } = useAuth();
  const [rutFormat, setRutFormat] = useState('');
  const { id } = useParams();
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const cleanRut = (r) => {
    return r.replace("-", "").replace("k", "k");
  }
  const useGetTokenByQuery = () => {
    let token = useQuery().get("t");
    return token ? token : "";
  }
  const [tokenQuery] = useState(useGetTokenByQuery());
  const [tryToken, setTryToken] = useState(true);
  const [data, setData]= useState({
    rut: '',
    dni: '',
    dni_type: '',
    commerce_name: '',
    is_guest: '',
    is_manual_verification: false,
    is_face_successful: false,
    emotion: '',
    emotion_english: '',
    branch_id: id,
    token: tokenQuery,
    reception: props.reception,
  });

  const translateEmotion = (emotion) => {
    let translatedEmotion = "";

    if (emotion === "SAD") {
      translatedEmotion = "triste"
    }
    else if (emotion === "HAPPY") {
      translatedEmotion = "feliz"
    }
    else if (emotion === "ANGRY") {
      translatedEmotion = "enojado"
    }
    else if (emotion === "SURPRISED") {
      translatedEmotion = "sorprendido"
    }
    return translatedEmotion
  }

  const handleChange = (text) => {
    data.rut = data.rut.replace("-", "")
    if (text.nativeEvent.data) {
      if (data.rut.length <= 8) {
        if (!isNaN(text.nativeEvent.data) || (text.nativeEvent.data === "k" && data.rut.length >= 7) || (text.nativeEvent.data === "K" && data.rut.length >= 7)) {
          if (data.rut.charAt(7) !== "k" && data.rut.charAt(7) !== "K") {
            data.rut += text.nativeEvent.data
          }
        }
      }
    }
    else {
      data.rut = data.rut.substring(0, data.rut.length - 1);
      setData(prev => ({...prev, ...data}))
    }

    if (data.rut) {
      setRutFormat(format(data.rut))
    }
    else {
      setRutFormat('')
    }
  }

  const handleSubmit = async (newData) => {
    let formdata = new FormData()
    
    data.rut = data.rut.replace("-", "");
    
    let rut_length = data.rut.length - 1;

    data.rut =data.rut.substring(0, rut_length) + "-" + data.rut.substring(rut_length)
    data.dni =data.rut
    let verification_token = null
    if (tokenQuery !== "") {
      verification_token = tokenQuery
    }
    formdata.append('rut', data.rut)
    formdata.append('dni', data.dni)
    formdata.append('dni_type', data.dni_type)
    formdata.append('branch_id', data.branch_id)
    formdata.append('verification_token', verification_token)
    formdata.append('reception', data.reception)
    try {
      let res = await axios({
        url: process.env.REACT_APP_BACKEND_URL + "/validation/rut/",
        method: 'POST',
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formdata
      });
      let verification = res.data.verification
      data.commerce_name = verification.commerce_name
      data.is_face_successful = verification.is_face_successful
      data.emotion = translateEmotion(verification.emotion)
      data.emotion_english = verification.emotion
      data.required_document = res.data.required_document
      data.usage = verification.usage
      data.redirect_to = verification.redirect_to
      setData(prev => ({...prev, ...data}))
      props.setIsCountrySelected(true)
      setVerification(true);
      setLoading(true);
    } catch (error) {
      setMessage('Rut inválido');
      if(error.response){
        if(error.response.status === 400){
          props.setIsCountrySelected(true)
          setLoading(true);
          setPending(true);          
        }
      }
    }
  }
  
  useEffect(() => {
    if (id !== undefined && tokenQuery !== "" && data.rut === "" && tryToken) {
      // Buscamos el rut por token
      async function getRutByToken () {
        setLoading(true)
        try {
          let token = await getAccessToken
          let formdata = new FormData()
          formdata.append('token', tokenQuery)
          formdata.append('branch_id', id)
          formdata.append('country_iso', "CL")
          formdata.append('reception', data.reception)
          let res = await axios({
            url: process.env.REACT_APP_BACKEND_URL + "/verification_dm/token/",
            method: 'POST',
            mode: "no-cors",
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + token
            },
            data: formdata
          });
          let res_rut = res.data.rut
          if (res_rut) {
            data.rut = cleanRut(res_rut)
            data.dni = data.rut
            data.dni_type = "rut"
            setData(prev => ({...prev, ...data}))
            handleSubmit({});
          }
        } catch (error) {
          setLoading(false)
        }
        setTryToken(false) // Para no volver a intentar
      }
      getRutByToken()
    }
  });
  
  if (id === undefined) {
    return <Redirect to="/need-info" />
  }

  if (pending) {
    return <NoVerification />
  } 

  if (verification) {
    return <ValidarIdentidadFotosAutomaticas data={data} country_selected={"CL"} />;
    // return <ValidarIdentidad data={data}/>; // versión sin detección automatica
  }
  
  if (loading) {
    return (
      <div className="container-validation">
        <div className="container">
          <Loading />
        </div>
      </div>
    )
  }


  if (currentUser){
    return <Redirect to='/' />;
  }

  return (
    <div className="background-form-login-rut">
      <div className="gerty-login-rut"></div>
        {/*<img className='gerty-login-rut'*/}
        {/*    src={GertyLogo}*/}
        {/*    alt="GertyId Logo" />*/}
        <h1 className="titulo-login-rut" >Ingresa tu rut</h1>
        <Formik
          initialValues={{
            rut: '',
          }}
          onSubmit={async (values) => {
            handleSubmit(values)
          }
          }
        >
          {({ errors, touched }) => (
            <Form>
              <div className="field-login-rut">
                <Label className="rut-label">RUT</Label>
                <Field className="input is-normal" id="input-for-rut" name="rut" placeholder="RUT" autoComplete="off" value={rutFormat} onChange={handleChange}/>
              </div>
              <div>
                <button className="button is-info" id="button-continue-login-rut" disabled={!(data.rut.length >= 8)} type="submit">Continuar</button>
              </div>
            </Form>
          )}
        </Formik>
        <p>{message}</p>
    </div>
  );
} 