import GertyLogoBlue from "../../images/gerty-blue.svg";
import GertyDropdown from "../../images/gerty-dropdown.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import useAuth from "../../hooks/useAuth";

export function TopBar(props) {
  var { currentUser, handleUserLogout } = useAuth();
  const [dropdownArrow, setDropdownArrow] = useState(faChevronDown);
  const paths = props.paths
  const toggleArrow = () => {
    setDropdownArrow(dropdownArrow === faChevronDown ? faChevronUp : faChevronDown)
  }
  return (
    <nav className="admin-layout__top_bar navbar-expand-lg">
      <a className="gerty-logo" href={paths.verifications}>
        <img className='gerty-blue-navbar'
             src={GertyLogoBlue}
             alt="GertyId Logo"
        />
      </a>
      <div className="collapse navbar-collapse show" id="navbarSupportedContent">
        <ul className="nav navbar-nav">
          <div id="dropdown-organization" className="sec-center">

            <input className="dropdown" type="checkbox" id="dropdown" name="dropdown"/>
            <label className="for-dropdown" htmlFor="dropdown" onClick={() => toggleArrow()}>
              <div className="text-a-table">
                <img className='gerty-dropdown-navbar' src={GertyDropdown} alt='dropdown'/>
              </div>
              <div className="container-user-organization-name">
                <h2 className='label-name'>{currentUser.user.name}</h2>
                <h2 className='label-correo'>{currentUser.user.email}</h2>
              </div>
              <FontAwesomeIcon icon={dropdownArrow} className="dropdown-arrow" />
            </label>
            <div className={"section-dropdown" + (dropdownArrow === faChevronDown ? " section-dropdown-desapear" : "")}>
              <a className="text-a-table" href={paths.overview}>Nueva verificación</a>
              <a className="text-a-table" href={paths.verifications}>Verificaciones</a>
              <a className="text-a-table" href={paths.locations}>Ubicaciones</a>
              <a className="text-a-table" href={paths.login} onClick={handleUserLogout}>Cerrar sesión</a>
            </div>
          </div>
        </ul>
      </div>
    </nav>
  )
}