import React, {useEffect, useState} from "react";
import '../styles/capture.css';
import '../styles/slider.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import GertyLogo from "../images/gerty-blue.png";
import FlagButton from "../components/autoPhoto/flag_button";
import LoginRut from "./LoginRut";
import LoginMexico from "./LoginMexico";
import LoginEcuador from "./LoginEcuador";
import LoginColombia from "./LoginColombia";
import getAccessToken from "../utils/getAccessToken";
import axios from "axios";
import {useLocation, useParams} from "react-router-dom";

function LoginMultiID(props) {
  const [country, setCountry] = useState("");
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const { id } = useParams();

  const choiceHandler = (country) => {
    setCountry(country)
  }

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const useGetTokenByQuery = () => {
    let token = useQuery().get("t");
    return token ? token : "";
  }
  const [tokenQuery] = useState(useGetTokenByQuery());

  let displayChosenCountry = null
  if (country === "CL") {
    displayChosenCountry = <LoginRut {...props} setIsCountrySelected={setIsCountrySelected} reception={false}/>
  } else if (country === "CO") {
    displayChosenCountry = <LoginColombia {...props} setIsCountrySelected={setIsCountrySelected} reception={false}/>
  } else if (country === "EC") {
    displayChosenCountry = <LoginEcuador {...props} setIsCountrySelected={setIsCountrySelected} reception={false}/>
  } else if (country === "MX") {
    displayChosenCountry = <LoginMexico {...props} setIsCountrySelected={setIsCountrySelected} reception={false}/>
  }

  useEffect(() => {
    // tratamos de definir el pais al cargar el componente
    if (id !== undefined && tokenQuery !== "") {
      // Buscamos el country por token
      async function getCountryByToken () {
        try {
          let token = await getAccessToken
          let formdata = new FormData()
          formdata.append('token', tokenQuery)
          formdata.append('branch_id', id)
          formdata.append('reception', false)
          let res = await axios({
            url: process.env.REACT_APP_BACKEND_URL + "/verification_dm/token/",
            method: 'POST',
            mode: "no-cors",
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + token
            },
            data: formdata
          });
          let res_rut = res.data.rut
          if (res_rut) {
            setCountry(res.data.country_iso)
          }
        } catch (error) {
          // pass
        }
      }
      getCountryByToken()
    }
  }, []);

  return (
    <div className="overflow-y">
      {
        !isCountrySelected ?
          <>
            <img className='gerty-verificate-validar'
                 src={GertyLogo}
                 alt="GertyId Logo"/>
            <div>
              <h1 className="title-capture">Selecciona país</h1>
            </div>

            <div className="flag-container">
              <FlagButton isSelected={"CL" === country} onClick={() => choiceHandler("CL")}
                          countryName="chile"/>
              <FlagButton isSelected={"CO" === country} onClick={() => choiceHandler("CO")}
                          countryName="colombia"/>
              <FlagButton isSelected={"EC" === country} onClick={() => choiceHandler("EC")}
                          countryName="ecuador"/>
              <FlagButton isSelected={"MX" === country} onClick={() => choiceHandler("MX")}
                          countryName="méxico"/>
            </div>
          </>
          :
          null
      }
      {displayChosenCountry}
    </div>
  )
}

export default LoginMultiID;