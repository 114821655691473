import React, {useCallback, useEffect, useState} from "react";
import '../../styles/validation.css';
import IconError from '../../images/icon-error.svg';
import getAccessToken from "../../utils/getAccessToken";
import axios from "axios";
import Loading from "../loading";
import EvaluationHeader from "./EvaluationHeader";
import {currencyFormat} from "../autoPhoto/utils_functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faList, faSpinner} from "@fortawesome/free-solid-svg-icons";

export default function EvaluationDetails (props) {
  const [evaluationDetails, setEvaluationDetails] = useState();
  const [loading, setLoading] = useState(true);
  const verification_token = props.data.token;
  const [showSelectPaymentMethod, setShowSelectPaymentMethod] = useState(true);
  const [showResume, setShowResume] = useState(true);
  // const [paymentMethod, setPaymentMethod] = useState('desk');
  const [paymentMethod] = useState('desk');
  const [getStatus, setGetStatus] = useState(true);
  const [retryStatus, setRetryStatus] = useState(false);
  
  const getInitialMaxTime = () => {
    return process.env.REACT_APP_MAX_SECONDS || 60
  }
  
  const [maxTime, setMaxTime] = useState(getInitialMaxTime());
  
  let evaluation = (evaluationDetails && evaluationDetails.evaluation ? evaluationDetails.evaluation : null),
    provider = (props.data.commerce_name ? props.data.commerce_name : ""),
    name = "",
    show_detail = false,
    evaluation_regular_price = 0,
    evaluation_deductible = 0,
    evaluation_discount = 0,
    evaluation_total_refund = 0,
    evaluation_copayment = 0
  
  if (evaluation) {
    evaluation_regular_price = evaluation.regular_price
    evaluation_deductible = evaluation.total_deductible
    evaluation_discount = evaluation.gerty_price
    evaluation_total_refund = evaluation.total_refund + evaluation.total_deductible
    evaluation_copayment = evaluation.copayment
    show_detail = evaluation.insurance_quota_reached
    name = evaluation.name
  }
  
  const getEvaluationDetails = useCallback(async () => {
    setLoading(true)
    try {
      let token = await getAccessToken,
        data = new FormData()
      data.append("token", verification_token)
      data.append("reception", props.data.reception)
      let response = await axios({
        method: 'POST',
        url: process.env.REACT_APP_BACKEND_URL + "/validation/evaluation_details/",
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + token
        },
        data: data
      })
      setEvaluationDetails(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [verification_token]);
  
  const handleRetry = () => {
    getEvaluationDetails()
  }
  
  const toggleArrow = () => {
    setShowSelectPaymentMethod(!showSelectPaymentMethod)
  }
  
  const toggleShowInformation = () => {
    setShowResume(!showResume)
  }
  
  const handleRetryStatus = () => {
    setRetryStatus(false)
    setMaxTime(getInitialMaxTime())
    setGetStatus(true)
  }
  
  const handleChangePaymentMethod = (e) => {
    /*
      Por los momentos no cambiará
      let selection = e.target.getAttribute('data-type')
      setPaymentMethod(selection)
    */
  }
  
  const getToggleResumeMessage = () => {
    let message = "Volver"
    if (showResume) {
      if (evaluation) {
        message = "¿Como aplica mi seguro?"
        if (show_detail) {
          message = "¿Tope? " + message
        }
        if (evaluation_deductible > 0) {
          message = "¿Deducible? " + message
        }
      }
    }
    return message
  }
  
  const getEvaluationStatus = useCallback(async  () => {
    try {
      setGetStatus(false)
      let token = await getAccessToken,
        data = new FormData()
      data.append("token", verification_token)
      data.append("reception", props.data.reception)
      let response = await axios({
        method: 'POST',
        url: process.env.REACT_APP_BACKEND_URL + "/validation/evaluation_status/",
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + token
        },
        data: data
      })
      if (!response.data || response.data.status !== 'completed') {
        setGetStatus(true)
      }else {
        props.next()
      }
    } catch (error) {
      setGetStatus(true)
    }
  }, [verification_token, props])
  
  useEffect(() => {
    if (!evaluation) {
      getEvaluationDetails()
    }else if (getStatus) {
      setTimeout(function () {
        if (maxTime > 0) {
          getEvaluationStatus()
          setMaxTime(maxTime - 1)
        }else {
          setGetStatus(false)
          setRetryStatus(true)
        }
      }, 1000);
    }
  }, [getEvaluationDetails, getStatus, getEvaluationStatus, maxTime, evaluation])
  
  if(loading) {
    return (
      <div className="container-validation">
        <div className="container">
          <Loading />
        </div>
      </div>
    )
  }
  
  return (
    <div className={"evaluation-details evaluation-details--pre-payment container-validation" + (showSelectPaymentMethod ? "" : " evaluation-details__footer-closed")}>
      <EvaluationHeader/>
      { evaluation ? (
        <div className="container">
          
          <div className={"evaluation-details--container"}>

            {showResume ? (
              <div className={"evaluation-details--body evaluation-details--body__resume"}>
                <div className={"evaluation-details--header-message"}>
                  <div className={"evaluation-details--header-message__name"}>
                    Hola {name}
                  </div>
                  <div className={"evaluation-details--header-message__message"}>
                    Tienes una atención por pagar
                  </div>
                </div>
                <div className={"evaluation-details--details"}>
                  <div className={"evaluation-details--details__provider"}>
                    {provider}
                  </div>
                  { evaluation_regular_price !== evaluation_discount && (
                    <div className={"evaluation-details--details-row evaluation-details--details__regular-price"}>
                      <div className={"evaluation-details--details-col"}>
                        Valor original
                      </div>
                      <div className={"evaluation-details--details-col"}>
                        {currencyFormat(evaluation_regular_price)}
                      </div>
                    </div>
                  )}
                  { evaluation_discount > 0 && (
                    <div className={"evaluation-details--details-row evaluation-details--details__discount"}>
                      <div className={"evaluation-details--details-col"}>
                        Valor convenio
                      </div>
                      <div className={"evaluation-details--details-col"}>
                        {currencyFormat(evaluation_discount)}
                      </div>
                    </div>
                  )}
                  <div className={"evaluation-details--details-row evaluation-details--details__total-refund"}>
                    <div className={"evaluation-details--details-col"}>
                      Seguro Complementario
                      { show_detail && (
                        <div className={"evaluation-details--gerty-details-detail"}>
                          Superaste el tope póliza
                        </div>
                      )}
                    </div>
                    <div className={"evaluation-details--details-col"}>
                      { ( evaluation_total_refund > 0 ? "-" : "") + currencyFormat(evaluation_total_refund)}
                    </div>
                  </div>
                  { evaluation_deductible > 0 && (
                    <div className={"evaluation-details--details-row evaluation-details--details__deductible"}>
                      <div className={"evaluation-details--details-col"}>
                        Deducible
                      </div>
                      <div className={"evaluation-details--details-col"}>
                        {currencyFormat(evaluation_deductible)}
                      </div>
                    </div>
                  )}
                  <div className={"evaluation-details--details-row evaluation-details--details__copayment"}>
                    <div className={"evaluation-details--details-col"}>
                      Total a pagar
                    </div>
                    <div className={"evaluation-details--details-col"}>
                      {currencyFormat(evaluation_copayment)}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={"evaluation-details--body evaluation-details--body__detail"}>
                <div className={"evaluation-details--header-message"}>
                  <div className={"evaluation-details--header-message__name"}>
                    ¿Cómo aplica mi seguro?
                  </div>
                </div>
                <div className={"evaluation-details--details"}>
                  <div className={"evaluation-details--details-row evaluation-details--details__regular-price"}>
                    <div className={"evaluation-details--details-col"}>
                      <div className={"evaluation-details--details-col-title"}>
                        Valor original
                      </div>
                      <div className={"evaluation-details--details-col-message"}>
                        Es el valor lista de la clínica.
                      </div>
                    </div>
                    <div className={"evaluation-details--details-col"}>
                      {currencyFormat(evaluation_regular_price)}
                    </div>
                  </div>
                  { evaluation_discount > 0 && (
                    <div className={"evaluation-details--details-row evaluation-details--details__discount"}>
                      <div className={"evaluation-details--details-col"}>
                        <div className={"evaluation-details--details-col-title"}>
                          Valor convenio
                        </div>
                        <div className={"evaluation-details--details-col-message"}>
                          Es el nuevo precio de tu atención. Aplican descuentos de la clínica y los que tiene convenidos Gerty.
                        </div>
                      </div>
                      <div className={"evaluation-details--details-col"}>
                        {currencyFormat(evaluation_discount)}
                      </div>
                    </div>
                  )}
                  <div className={"evaluation-details--details-row evaluation-details--details__total-refund"}>
                    <div className={"evaluation-details--details-col"}>
                      <div className={"evaluation-details--details-col-title"}>
                        Seguro Complementario
                      </div>
                      <div className={"evaluation-details--details-col-message"}>
                        Esto es lo que cubre tu seguro y se aplica sobre el Valor Convenio.
                      </div>
                    </div>
                    <div className={"evaluation-details--details-col"}>
                      { ( evaluation_total_refund > 0 ? "-" : "") + currencyFormat(evaluation_total_refund)}
                    </div>
                  </div>
                  { show_detail > 0 && (
                    <div className={"evaluation-details--details-row evaluation-details--details__deductible"}>
                      <div className={"evaluation-details--details-col"}>
                        <div className={"evaluation-details--details-col-title"}>
                          Superaste el tope póliza
                        </div>
                        <div className={"evaluation-details--details-col-message"}>
                          Tu seguro tiene un monto máximo que cubre durante la vigencia. Ya alcanzaste ese tope.
                        </div>
                      </div>
                      <div className={"evaluation-details--details-col"}>
                        &nbsp;
                      </div>
                    </div>
                  )}
                  { evaluation_deductible > 0 && (
                    <div className={"evaluation-details--details-row evaluation-details--details__deductible"}>
                      <div className={"evaluation-details--details-col"}>
                        <div className={"evaluation-details--details-col-title"}>
                          Deducible
                        </div>
                        <div className={"evaluation-details--details-col-message"}>
                          Es el monto que tienes que pagar antes de que parta tu cobertura de seguro.
                        </div>
                      </div>
                      <div className={"evaluation-details--details-col"}>
                        {currencyFormat(evaluation_deductible)}
                      </div>
                    </div>
                  )}
                  <div className={"evaluation-details--details-row evaluation-details--details__copayment"}>
                    <div className={"evaluation-details--details-col"}>
                      Total a pagar
                    </div>
                    <div className={"evaluation-details--details-col"}>
                      {currencyFormat(evaluation_copayment)}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={"evaluation-details--toggle-resume"}>
              <div className={"evaluation-details--toggle-resume_icons"}>
                <FontAwesomeIcon icon={faList} />
              </div>
              <div onClick={toggleShowInformation} className={"evaluation-details--toggle-resume_message"}>
                {getToggleResumeMessage()}
              </div>
            </div>
            
          </div>

          <div className={"evaluation-details--footer"}>
            <div className={"evaluation-details--footer__arrow"}>
              <FontAwesomeIcon onClick={toggleArrow} icon={showSelectPaymentMethod ? faChevronDown : faChevronUp} />
            </div>
            <div className={"evaluation-details--footer__message"}>
              Elige tu forma de pago
            </div>
            <div className={"evaluation-details--footer__choice-container evaluation-details--footer__email-input"}>
              {/*<div onClick={handleChangePaymentMethod} data-type={"healthpay"} className={"evaluation-details--footer__choice evaluation-details--footer__choice-disabled" + (paymentMethod === "healthpay" ? " evaluation-details--footer__choice--selected" : "")}>*/}
              {/*  Healthpay*/}
              {/*</div>*/}
              <div onClick={handleChangePaymentMethod} data-type={"desk"} className={"evaluation-details--footer__choice" + (paymentMethod === "desk" ? " evaluation-details--footer__choice--selected" : "")}>
                En recepción
              </div>
            </div>
            <div className={"evaluation-details--actions"}>
              {/*<button onClick={handleSubmit} className="evaluation-details--actions-btn">Pagar</button>*/}
              {retryStatus ? (
                  <button onClick={handleRetryStatus} className="evaluation-details--actions-btn evaluation-details--actions-btn__error">Reintentar verificar tu pago</button>
                ) : (
                  <div className="evaluation-details--actions-btn evaluation-details--actions-btn__centered">
                    <FontAwesomeIcon icon={faSpinner} className={'fa-spin'} />
                    <div className="evaluation-details--actions-btn_text">Esperando tu pago</div>
                  </div>
                )
              }
            </div>
          </div>
          
        </div>
      ) : (
        // No tenemos información
        <div className="container with-no-evaluation">
          <div className='figure' id="circle">
            <img className='icon-error'
                 src={IconError}
                 alt="error icon" />
          </div>
          <h1 className="text-complete-verification">Lo sentimos</h1>
          <h4 className="verification-complete-paragraph">No hemos podido obtener la bonificación de tu seguro</h4>
          <div className="validation-failed-buttons">
            <button onClick={handleRetry} className="validation-failed-button try-again-btn w-100">Reintentar</button>
          </div>
        </div>
      )}
    </div>
  );
}