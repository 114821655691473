import * as React from 'react';
import {Modal} from "react-bootstrap";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import useAuth from "../hooks/useAuth";
import axios from "axios";
import "../styles/new_location_modal.css";

const NewLocationModal = (props) => {
  const { currentUser, handleUserLogout } = useAuth();
  const sendNewLocation = async (values) => {
    let {name} = values,
      form_data = new FormData(),
      token = currentUser.access,
      commerce_id = currentUser.user.commerce_id
    form_data.append("name", name)
    try {
      return await axios({
        url: process.env.REACT_APP_BACKEND_URL + "/commerce/" + commerce_id + "/branch/",
        method: 'POST',
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + token
        },
        data: form_data
      })
    } catch (e) {
      if (e.response) {
        return e.response;
      }
    }
  }
  const createLocation = async (values, setErrors) => {
    if(currentUser){
      const response2 = await sendNewLocation(values);
      if (response2.status !== 200){
        if (response2.status !== 400 && response2.status !== 500 && response2.status !== 404) {
          return handleUserLogout();
        }
        return setErrors({ name: (response2.data.error !== undefined ? response2.data.error : "Ocurrió un error inesperado") })
      }else{
        return props.afterCreate(response2.data)
      }
    } else {
      return handleUserLogout();
    }
  }
  return (
    <>
      <Modal
        className={"new-location-modal"}
        show={props.show}
        onHide={props.onHide}
        style={{paddingRight: 0}}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Nueva Ubicación
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={
              {
                name: "",
                active: true,
                address: ""
              }
            }
            validationSchema={
              Yup.object({
                name: Yup.string()
                  .required('Este campo es obligatorio')
              })
            }
            onSubmit={
              async (values, { setErrors }) => {
                await createLocation(values, setErrors)
              }
            }
          >
            {({ errors, touched, resetForm }) => (
              <Form className={"new-location-modal__new_form" + (errors.name && touched.name ? " input-has-error" : "")}>
                <div className={"new-location-modal__new_form_body"}>
                  <Field
                    type="text"
                    className="new-location-modal__edit_input"
                    name="name"
                    placeholder="Nombre de la sucursal"
                  />
                  <button type={"submit"} className={"new-location-modal__confirm_btn"}>Guardar</button>
                </div>
                {
                  errors.name && touched.name && (
                    <div className="new-location-modal__error">{errors.name}</div>
                  )
                }
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default NewLocationModal;