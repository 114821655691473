import React from 'react';
import '../styles/index.css';
import 'bulma/css/bulma.min.css';

const Layout =  (props) => {
    return(
        <div className="main-wrapper" align="center">
            <div className="main-div" align="center">
                {props.children}
            </div>
        </div>
    )
}

export default Layout