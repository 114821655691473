import React, {useEffect, useState} from 'react';
import '../styles/resume.css';
import { Formik, Form, Field } from 'formik';
import '../styles/navbar-organization.css';
import * as Yup from 'yup';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import { format } from 'rut.js';
import NewVerificationStatus from '../components/NewVerificationStatus';
import AdminLayout from "../components/AdminLayout";
import {useStateIfMounted} from "use-state-if-mounted";

function ResumeContent() {
  var { currentUser, handleUserLogout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [setMessage] = useState('');
  const staff = "444";
  const [rutFormat, setRutFormat] = useState('');
  const [validatedRutFormat, setValidatedRutFormat] = useState('');
  const [data, setData] = useState({
    rut: '',
    rut_validated: '',
  })
  const [rutStatus, setRutStatus] = useState('');
  const [page, setPage] = useState(-1);
  const [email, setEmail] = useState('');
  const [locations, setLocations] = useStateIfMounted([]);

  const [loadedLocations, setLoadedLocations] = useState(false);

  const handleChangeRut = (text) => {
    data.rut = data.rut.replace("-", "")
    if (text.nativeEvent.data) {
      if (data.rut.length <= 8) {
        if (!isNaN(text.nativeEvent.data) || (text.nativeEvent.data === "k" && data.rut.length >= 7) || (text.nativeEvent.data === "K" && data.rut.length >= 7)) {
          if (data.rut.charAt(7) !== "k" && data.rut.charAt(7) !== "K") {
            data.rut += text.nativeEvent.data
          }
        }
      }
    }
    else {
      data.rut = data.rut.substring(0, data.rut.length - 1);
      setData(prev => ({...prev, ...data}))
    }

    if (data.rut) {
      setRutFormat(format(data.rut))
    }
    else {
      setRutFormat('')
    }
  }

  const handleChangeValidatedRut = (text) => {
    data.rut_validated = data.rut_validated.replace("-", "")
    if (text.nativeEvent.data) {
      if (data.rut_validated.length <= 8) {
        if (!isNaN(text.nativeEvent.data) || (text.nativeEvent.data === "k" && data.rut_validated.length >= 7) || (text.nativeEvent.data === "K" && data.rut_validated.length >= 7)) {
          if (data.rut_validated.charAt(7) !== "k" && data.rut_validated.charAt(7) !== "K") {
            data.rut_validated += text.nativeEvent.data
          }
        }
      }
    }
    else {
      data.rut_validated = data.rut_validated.substring(0, data.rut_validated.length - 1);
      setData(prev => ({...prev, ...data}))
    }

    if (data.rut_validated) {
      setValidatedRutFormat(format(data.rut_validated))
    }
    else {
      setValidatedRutFormat('')
    }
  }
  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Este campo es obligatorio')
      .email('Ingrese un correo válido'),
    location: Yup.string()
      .required('Este campo es obligatorio'),
  });

  useEffect(() => {
    if (loadedLocations === false) {
      const getLocations = async () => {
        let form_data = new FormData(),
          token = currentUser.access
        form_data.append('commerce_id',currentUser.user.commerce_id)
        try {
          const res = await axios({
            url: process.env.REACT_APP_BACKEND_URL + "/commerce/branches/",
            method: 'POST',
            mode: "no-cors",
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + token
            },
            data: form_data
          })
          setLoading(false)
          return res;
        } catch (e) {
          setLoading(false)
          if (e.response) {
            return e.response;
          }
        }
      }
      const assignLocations = async () => {
        if(currentUser){
          const response2 = await getLocations();
          if(response2.status === 500 || response2.length === 0){
            setLocations([])
          }else{
            if (response2.status === 401){
              return handleUserLogout();
            }else{
              setLocations(response2.data.filter(location => { return location.active }))
            }
          }
          setLoadedLocations(true)
        } else {
          return handleUserLogout();
        }
      }
      assignLocations()
    }
  }, [locations, currentUser, handleUserLogout, setLoading, setLocations, loadedLocations, setLoadedLocations])

  return (
    <div className="resume-container">
      {page !== -1 ? <NewVerificationStatus page={page} rut={rutStatus} email={email}/> : null}
      <div className="resume-top-grid">
        <div className="create-verification-resume">
          <Formik
            initialValues={{
              requested_user_rut: data.rut,
              validated_user_rut: data.rut_validated,
              location: '',
              email: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              setLoading(true);
              let submitValues = new FormData();
              let submitRut = rutFormat.replace(".", "").replace(".", "");
              let submitValidated = validatedRutFormat.replace(".", "").replace(".", ""); 
              submitValues.append('requested_user_rut', submitRut);
              submitValues.append('validated_user_rut', submitValidated);
              submitValues.append('location', values.location);
              submitValues.append('staff', staff);
              submitValues.append('commerce_code', currentUser.user.commerce_code);
              submitValues.append('email', values.email);

              try {
                setPage(0);
                setRutStatus(rutFormat)
                console.log("RUT status: ", rutStatus);
                const response = await axios({
                  url: process.env.REACT_APP_BACKEND_URL + '/verification/',
                  method: 'POST',
                  mode: 'no-cors',
                  headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + currentUser.access
                  },
                  data: submitValues
                })
                if (response.status === 201) {
                  setEmail(values.email);
                  setPage(1)
                  setLoading(false);
                } else if (response.status === 400) {
                  setPage(2);
                  const error = response.error;
                  setMessage(error);
                }
              } catch (error) {
                setPage(2);
                setMessage(error.message);
              } finally {
                setLoading(false);        
              }
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form-container-resume">
                  <div className="form-input-section">
                    {/*<h2 className="h2-create-verification-resume">  Nueva verificación</h2>*/}
                    <div className="form-field-resume">
                      <Field name="requested_user_rut" type="text" placeholder="RUT" className="field-resume" value={rutFormat} onChange={handleChangeRut}/>
                    </div>
                    <div className="form-field-resume">
                      <Field name="validated_user_rut" type="text" placeholder="Confirme el RUT" className="field-resume" value={validatedRutFormat} onChange={handleChangeValidatedRut}/>
                    </div>
                    <div className="form-field-resume">
                      <Field name="email" type="text" placeholder="Email" className="field-resume" />
                      {errors.email && touched.email && (
                        <div className="errors-section">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-field-resume">
                      <fieldset className="form-group">
                        {/*Se espera que estos valores cambien cuando se cree el modelo location*/}
                        <Field name="location" component="select" className="field-resume">
                          <option value="" disabled>Selecciona la ubicación</option>
                          {locations.length ? (
                            locations.map(location => (
                              <option value={location.name} key={"location_"+location.id}>{location.name}</option>
                            ))
                          ) : (
                            <option value="Casa Matriz">Casa Matriz</option>
                          )}
                        </Field>
                      </fieldset>
                      {errors.location && touched.location && (
                        <div className="errors-section">{errors.location}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-action">
                    {!loading ? (
                      <div className="button-section">
                        <button className="verification-button-resume" type="submit">Enviar</button>
                      </div>
                    ) : (
                      <div className="message-section">
                        <p>Enviando...</p>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      
    </div>
  )
}

export default function NewVerification() {
  var { currentUser, handleUserLogout } = useAuth();

  if(!currentUser){
    handleUserLogout()
    return <Redirect to="/login" />;
  }
  if(currentUser.type !== "commerce"){
    return <Redirect to="/home" />;
  }
  
  return (
    <AdminLayout title={"Nueva Verificación"}>
      <ResumeContent />
    </AdminLayout>
  )
}