import React, {useState, useEffect, useRef} from "react";
import '../../styles/capture.css';
import Loading from '../../components/loading';
import Slider from '../Slider/Slider';
import '../../styles/slider.css';
import SwitchCameraIcon from "../../images/switch-camera-icon.png";
import {Camera} from "react-camera-pro";
import {useOpenCv} from "opencv-react";
import Detect_qr_pos from "./detect_qr";
// import TemplateBackCarnet from './template_testeo_back_carnet.png'
import TemplateBackCarnet from '../../images/documento-back.svg';

const AutomaticQRCapture = (props) => {
  const getAutomaticPhotoTakingTimeInSeconds = () => {
    return process.env.AUTOMATIC_PHOTO_TAKING_TIME_IN_SECONDS ?? 5;
  }
  const {loaded, cv} = useOpenCv();
  const [showImgCapture, setShowImgCapture] = useState(true);
  const [loading, setLoading] = useState(true);
  const INITIAL_FACING_MODE = "environment";
  
  const [imgSrc, setImgSrc] = useState(null);
  const [scaning] = useState(false);
  const [fps] = useState(10);
  const [manual, setManual] = useState(false);
  const [downcount, setDowncount] = useState(getAutomaticPhotoTakingTimeInSeconds());
  
  const webcamRef = React.useRef(null);
  const focusCount = React.useRef(0);
  const [focusCountShow, setfocusCountShow] = useState(0);
  const take_false_negative = React.useRef(false);
  let scan = useRef(false);
  const [camara_horizontal, setCamara_horizontal] = useState(false);
  
  const capture = React.useCallback(
    () => {
      scan.current=false
      props.data.QRautomaticPhoto = false
      setImgSrc(webcamRef.current.takePhoto());
      setShowImgCapture(false);
    }, [webcamRef, props.data]
  );
  
  function correct_placed(qr_coords, h, w) {
    let p1_coords = qr_coords[0]
    let p2_coords = qr_coords[1]
    // TODO definir bien estos rangos, hay que calzar el template con estos rangos!!
    // se usand coordenadas recolectadas de scan 300ppp
    let correct_p1_pos = false
    let correct_p2_pos = false
    // el aspect ratio generalmente es x / y
    if (w/h < 4117/2569) { // si el alto es mayor que el ancho segun proporcion carnet (CAMARA VERTICAL)
      // mantemos los bordes laterales cercano en la imagen
      // horizontalmente dejamos un margen 5% ambos lados el ancho carnet será 90% de w
      // X p1 33 / 705 relativa del carnet, a esto le sumamos el 5% de w
      let x_p1 = 0.9*w * 33 / 705 + 0.05 * w; // 0,3306
      let y_p1;
      let y_p2;
      setCamara_horizontal(false);
      if (p1_coords && x_p1 - 0.25 * w < p1_coords[0] && p1_coords[0] < x_p1 + 0.25 * w) {
        // 40 posicion y
        y_p1 = h/2 - 443/705 * 0.9 * w / 2 + 40 / 705 * 0.9 * w
        if  (y_p1 - 0.25 * w < p1_coords[1] && p1_coords[1] < y_p1 + 0.25 * w) {
          correct_p1_pos = true
        }
      }
      // X p2 33 / 705
      let x_p2 = 0.9*w * 33 / 705 + 0.05 * w; // 0,8752
      if (p2_coords && x_p2 - 0.25 * w < p2_coords[0] && p2_coords[0] < x_p2 + 0.25 * w) {
        // 154 posicion y
        y_p2 = h / 2 - 443 / 705 * 0.9 * w / 2 + 154 / 705 * 0.9 * w
        if (y_p2 - 0.25 * w < p2_coords[1] && p2_coords[1] < y_p2 + 0.25 * w) {
          correct_p2_pos = true
        }
      }
      // console.log(x_p1, y_p1, x_p2, y_p2)
      
    } else {
      // mantemos los bordes sueriores y inferior cercano en la imagen  (CAMARA HORIZONTAL)
      // verticalmente dejamos un margen 5% ambos lados el alto carnet será 90% de h
      // X p1 33 / 443 relativa del carnet, esto lo sumamos a despues de restar la mitad de caret al centro de w (w/2)
      let x_p1 = w/2 - 0.9*h * 705 / 443 / 2 + 0.9 * h * 33 / 443;
      setCamara_horizontal(true);
      if (p1_coords && x_p1 - 0.25 * h < p1_coords[0] && p1_coords[0] < x_p1 + 0.25 * h) {
        // 40 posicion y
        let y_p1 = 0.05 * h + 40 / 443 * 0.9 * h
        if  (y_p1 - 0.25 * h < p1_coords[1] && p1_coords[1] < y_p1 + 0.25 * h) {
          correct_p1_pos = true
        }
      }
      // X p2 33 / 443
      let x_p2 = w/2 - 0.9*h * 705 / 443 / 2 + 0.9 * h * 33 / 443; // 0,8752
      if (p2_coords && x_p2 - 0.25 * h < p2_coords[0] && p2_coords[0] < x_p2 + 0.25 * h) {
        // 154 posicion y
        let y_p2 = 0.05 * h + 154 / 443 * 0.9 * h
        if (y_p2 - 0.25 * h < p2_coords[1] && p2_coords[1] < y_p2 + 0.25 * h) {
          correct_p2_pos = true
        }
      }
    }
    // console.log(correct_p1_pos, correct_p2_pos)
    return correct_p2_pos && correct_p1_pos
  }
  const frameAnalisis = React.useCallback(
    () => {
    // console.log(scaning)
    if (!scan.current) { // para detener el loop
      if (take_false_negative.current) {
        let cameraCanvas = webcamRef.current.takePhoto()
        props.data.qr_false_negatives.push(cameraCanvas.split(',')[1]);
        take_false_negative.current = false; // almacenamos la imagen si no se detectó el carnet para poseterior analisis
      }
      return
    }
    let begin = Date.now();
    // console.log("SCANNING")
    // let cameraCanvas = webcamRef.current.takePhoto()
    let cameraCanvas = webcamRef.current.takePhoto()

    let img = cv.imread("camaraCanvas")
    // coordenadas en formato [x, y]
    let qr_coords = Detect_qr_pos(cv, img, true, "")  // el outcanvan es para debug, si se da, debe haber un tag con ese id o crashea
    let [h, w] = img.matSize;
    img.delete();
    if (correct_placed(qr_coords, h, w)) {
      // console.log("carnet bien posicionado!")
      focusCount.current += 3;
    } else if (focusCount.current > 0) {
      focusCount.current -= 1
    }
    setfocusCountShow(focusCount.current)
    if (take_false_negative.current) {
      props.data.qr_false_negatives.push(cameraCanvas.split(',')[1]);
      take_false_negative.current = false;
    }
    let elapsed = Date.now() - begin;
    let nextframe = 1000 / fps - elapsed;
    // console.log("elapsed", elapsed)
    // console.log("next frame", nextframe)
    // console.log("count", count)
    // console.log("countflags", countflags)
    if (focusCount.current > 9) {  //TODO ajustar este parametro para enfocar la toma posterior, si es muy alto puede costar tomar la foto
      // console.log("foto lista")
      props.data.qr_coords = qr_coords
      props.data.QRautomaticPhoto = true
      setImgSrc(cameraCanvas)
      setShowImgCapture(false)
      return;
    }
    setTimeout(frameAnalisis, nextframe);
  }, [cv, fps, props.data])
  
  // useEffect(() => {
  //     if (imgSrc) {
  //         // console.log("AVANZANDO")
  //         // props.data.QRautomaticPhoto = !manual;
  //     }
  // }, [imgSrc]);
  
  
  const handleSubmit = () => {
    props.data.reverse_image = imgSrc.split(',')[1];
    props.next(props.data, 1)
  }
  
  
  const handleBack = () => {
    props.prev(props.data);
  }
  
  const renderTitle = () => {
    return <div><h1 className='title-capture'>Cédula Reverso</h1></div>
  }
  
  const renderSubtitle = () => {
    return <h1 className='subtitle-capture'>Alinea el documento según la plantilla.</h1>
  }
  
  const renderSecondSubtitle = () => {
    return <h1 className='subtitle-capture' id="button-2-captured">Asegúrate de que se lean todos los datos y se vean bien las fotos.</h1>
  }
  
  const renderBtnText = () => {
    return downcount > 0 ? "(" + downcount + ") Escaneo Automático": "Tomar Foto Manual"
  }
  
  useEffect(() => { // Creacion componente
    const timer = setTimeout(() => {
      setLoading(false); // aparentemente el comoponente Camera se demora en cargar y a veces lanza error si no se espera
    }, 3000);
    props.data.qr_false_negatives = []
    let canvas = document.getElementsByTagName("canvas");
    if (typeof canvas[0] === 'object' && canvas[0] !== null && 'setAttribute' in canvas[0]) {
      canvas[0].setAttribute('id', 'camaraCanvas');
    }
    return () => {
      clearTimeout(timer);
    }
  }, [props.data])
  
  
  useEffect(() => { // Inicio scaner
    if (loading || !loaded) return;
    scan.current = !scan.current
    props.data.qr_false_negatives = []
    frameAnalisis()
    let downcountInterval = setInterval(function () {
      setDowncount((p) => {
        if (p > 0)
          return p - 1
        else {
          clearInterval(downcountInterval)
          return p
        }
      })
    }, 1000)
    const manualTimer = setTimeout(() => {
      take_false_negative.current = true
      // scan.current = false;
      setManual(true);
      // console.log("timeout, take manual")
    }, getAutomaticPhotoTakingTimeInSeconds() * 1000)
    let timesRun = 0;
    let interval = setInterval(function () {
      timesRun += 1;
      if (timesRun >= 3) {
        clearInterval(interval);
      }
      take_false_negative.current = true
      //do whatever here..
    }, 5000);
    // console.log("scaning change")
    // console.log(count)
    
    return () => {
      clearTimeout(manualTimer);
      clearInterval(interval);
      clearInterval(downcountInterval);
    }
    
  }, [scaning, loading, loaded, frameAnalisis, props.data]);
  
  
  return (
    <>
      <div className='background-capture' hidden={loading}> {/*hay un bug al usar pantalla chica con min-height*/}
        <div className="capture">
          {showImgCapture && (
            <div className="full-container-of-cam1">
              <div className="full-container-of-cam2">
                <div className="wide-camera-container">
                  <div className="borde-nuevo">
                  </div>
                  {camara_horizontal
                    ? <img src={TemplateBackCarnet} alt="template" className="img-template-horizontal"
                           style={{filter: `grayscale(${1 - focusCountShow / 8})`}}/>
                    : <img src={TemplateBackCarnet} alt="template" className="img-template-vertical"
                           style={{filter: `grayscale(${1 - focusCountShow / 8})`}}/>
                  }
                  <Camera
                    ref={webcamRef}
                    facingMode={INITIAL_FACING_MODE}
                  />
                </div>
                <div className="full-container-of-cam3">
                  {renderTitle()}
                  {renderSubtitle()}
                  <div className="capture-buttons-container">
                    <button disabled={!manual} className="button button-capture button-automatic-photo" onClick={(e) => {
                      capture()
                    }}>{renderBtnText()}</button>
                    <img className="button-switch-camera"
                         onClick={() => webcamRef.current.switchCamera()} src={SwitchCameraIcon}
                         title="Cambiar cámara" alt="Cambiar cámara"/>
                  </div>
                </div>
              </div>
            </div>
          )}
          {imgSrc && (
            <div className="captured captured__carnet">
              <div className="slider-container">
                <Slider img={[props.data.carnet, imgSrc]}/>
              </div>
              <div className="actions-container">
                <h1 className='title-capture'>Revisa las imágenes para continuar</h1>
                {renderSecondSubtitle()}
                <div>
                  <button className="button button-again-capture" onClick={handleBack}>
                    Volver a escanear
                  </button>
                </div>
                <div>
                  <button className="button button-contine-capture" onClick={handleSubmit}>Continuar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      
      <div hidden={!loading}>
        <Loading/>
      </div>
    </>
  );
};

export default AutomaticQRCapture;