import React from "react";
import '../../styles/capture.css';
import '../../styles/slider.css';
import GertyLogo from "../../images/gerty-blue.png";
import AutomaticQRCapture from "./AutomaticQRCapture";
import TakePictureCarnet from "../validacionRut/CaptureCarnet";
import cedula_vieja from "./cedula_antigua.png";
import cedula_nueva from "./cedula_nueva.png";
import SuspenseImage from "../SuspenseImage";

function ChoiceBackOldOrNew(props) {

  const handleNew = () => {
      props.setCarnetNuevo(1)
  }
  const handleOld = () => {
      props.setCarnetNuevo(-1)
  }
  if (props.carnetNuevo === 1) {
      return AutomaticQRCapture(props)
  } else if (props.carnetNuevo === -1) {
      return TakePictureCarnet(props)
  }

  return (
    <div>
      <SuspenseImage className='gerty-verificate-validar'
           src={GertyLogo}
           alt="GertyId Logo" />
      <h1 className="title-capture">¿Qué carnet tiene?</h1>
      <div className="capture-buttons-container">
          <SuspenseImage className="button-img" onClick={handleOld} src={cedula_vieja} alt={"Formato de cedula antiguo"}/>
          <SuspenseImage className="button-img" onClick={handleNew} src={cedula_nueva} alt={"Formato de cedula nuevo"}/>
      </div>
    </div>
  )

}

export default ChoiceBackOldOrNew;