import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Introduccion from '../../components/validacion/VerificarIdentidad';
import AlmostReady from '../../components/validacionRut/AlmostReady';
import TakePictureCara from '../../components/validacionRut/FotoCara';
import VerificationComplete from '../../components/validacionRut/VerificationComplete';
import VerificationFailed from '../../components/validacionRut/VerificationFailedID';
import ManualValidationFirstForm from '../../components/manualValidation/InsureeForm';
import ManualValidationSecondForm from '../../components/manualValidation/ValidationResponsibleForm';
import AlertInfoRut from '../../components/AlertInfoRut';
import getAccessToken from '../../utils/getAccessToken';
import VerificationState from '../../components/VerificationState';
import CamaraPermission from "../../components/CamaraPermission";
import { format } from 'rut.js';
import TakePictureEmocion from "../../components/validacionRut/FotoEmocion";
import ChoiceCountry from "./choice_country";
import ChoiceBackOldOrNew from "./choice_back_old_or_new";
import EvaluationStatus from "../evaluation/EvaluationStatus";
import EvaluationDetails from "../evaluation/EvaluationDetails";
import EvaluationPaymentDetails from "../evaluation/EvaluationPaymentDetails";
import getGeoInfo from "./GeoLoc";

export default function ValidarIdentidadFotosAutomaticas(props) {

  const [carnetNuevo, setCarnetNuevo] = useState(0);
  const [country, setCountry] = useState("")
  const [page, setPage] = useState(0);
  const [goBackToPage, setGoBackToPage] = useState(1);
  const [data, setData] = useState({
    face_image: '',
    frontal_image: props.data.required_document === false ? "True" : "",
    reverse_image: props.data.required_document === false ? "True" : "",
    rut: props.data.rut ? props.data.rut : "",
    dni: props.data.dni ? props.data.dni : "",
    dni_type: props.data.dni_type ? props.data.dni_type : "",
    formatted_rut: format(props.data.rut),
    emotion: props.data.emotion ? props.data.emotion : "",
    emotion_english: props.data.emotion_english ? props.data.emotion_english : "",
    emotion_image: '',
    certified_email: '',
    birthdate: '',
    serie_number: '',
    manual_reason: '',
    name_responsible: '',
    // rut_responsible: '',
    dni_responsible: '',
    document_country: '',
    agreed: false,
    is_manual_verification: props.data.is_manual_verification,
    is_face_successful: props.data.is_face_successful ? props.data.is_face_successful : false,
    token: props.data.token,
    usage: props.data.usage,
    commerce_name: props.data.commerce_name,
    redirect_to: props.data.redirect_to,
    reception: props.data.reception,
  });

  useEffect(() => {
    // tratamos de definir el pais al cargar el componente
    if (props.country_selected) {
      setCountry(props.country_selected)
      setCarnetNuevo("locked") // si es /= 0 , bloquea la seleccion de pais
    } else {
      setCountry("CL")  // si no hay pais asumiremos que era un dato legacy, por tanto, de chile
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    data.document_country = country
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const [validateEmotionData, setValidateEmotionData] = useState({
    location: {
      state: {
        rut: data.rut,
        dni: data.dni,
        dni_type: data.dni_type,
        token: props.data.token,
        emotion: "",
        emotion_english: "",
        face_image_1: "",
        formatted_rut: data.formatted_rut,
        is_manual_verification: data.is_manual_verification
      }
    }
  });

  const sendInsuree = async (data, callback) => {
    let token = ""
    token = await getAccessToken
    let formdata = new FormData()
    formdata.append('certified_email', data.certified_email)
    formdata.append('birthdate', data.birthdate)
    formdata.append('serie_number', data.serie_number)
    formdata.append('manual_reason', data.manual_reason)
    formdata.append('token', data.token)
    formdata.append('document_country', data.document_country)
    setData(prev => ({...prev, ...data}))
    await axios({
      url:  process.env.REACT_APP_BACKEND_URL + "/manual_verification/insuree/",
      method: 'POST',
      headers: {
        "Content-Type": "multidata/form-data",
        "Authorization": "Bearer " + token
      },
      data: formdata
    })
    .then ((res) => {
      if (res.status !== 200) {
        throw new Error('No se pudo guardar los datos')
      }
      setPage(8)
    })
    .catch(err =>{
      setData(prev => ({...prev, ...data}))
      callback(false)
      setPage(7)
    })
  }

  const sendResponsible = async (data, callback) => {
    let token = ""
    token = await getAccessToken
    let formdata = new FormData()
    formdata.append('name_responsible', data.name_responsible)
    // formdata.append('rut_responsible', data.rut_responsible)
    formdata.append('dni_responsible', data.dni_responsible)
    formdata.append('dni_type', data.dni_type)
    formdata.append('token', data.token)
    formdata.append('document_country', data.document_country)
    setData(prev => ({...prev, ...data}))
    await axios({
      url:  process.env.REACT_APP_BACKEND_URL + "/manual_verification/responsible/",
      method: 'POST',
      headers: {
        "Content-Type": "multidata/form-data",
        "Authorization": "Bearer " + token
      },
      data: formdata
    })
    .then ((res) => {
      if (res.status !== 200) {
        throw new Error('No se pudo guardar los datos')
      }
      setPage(6)
    })
    .catch(err =>{
      setData(prev => ({...prev, ...data}))
      callback(false)
      setPage(8)
    })
  }

  const buildValidateEmotionData = (emotion, emotion_english, face_image_1) => {
    return {
      rut: data.rut,
      dni: data.dni,
      dni_type: data.dni_type,
      token: props.data.token,
      emotion: emotion,
      emotion_english: emotion_english,
      face_image: face_image_1,
      emotion_image: '',
      formatted_rut: data.formatted_rut,
      is_manual_verification: data.is_manual_verification
    }
  }
  // useEffect(()=>{
  //   console.log("data")
  //   console.log(data)
  // },[data])
  // useEffect(()=>{
  //   console.log("page")
  //   console.log(page)
  // },[page])

  const nextPage = async (newData, step = 0, callback = null) => {
    // console.log("in NextPage, step: ", step)
    setData(prev => ({...prev, ...newData}))

    // if (step !== 6) {
    //   setPage(6)
    //   return;
    // }

    if (step === 0) {
      setGoBackToPage(0)
      newData.rut = props.data.rut;
      newData.dni = props.data.dni;
      newData.dni_type = props.data.dni_type;
      setData(prev => ({...prev, ...newData}))
      if (data.is_face_successful && data.emotion !== "" && !data.is_manual_verification) {
        let emotionData = buildValidateEmotionData(data.emotion, data.emotion_english, "face image in attempt")
        setValidateEmotionData(emotionData)
        setPage(11)
      }
      else if (props.data.required_document === false) {
        setGoBackToPage(4)
        setPage(prev => prev + 4)
      } else {
        // console.log("page +1")
        setPage(prev => prev + 1)
      }
    }

    else if (step === 1) {
              // console.log("page +1")
      setPage(prev => prev + 1)
    }

    else if (step === 2){
      // console.log("hacer request a:" + process.env.REACT_APP_BACKEND_URL + "/validation/faces_verification/")
      let formdata = new FormData()
      formdata.append('carnet_image', newData.frontal_image)
      formdata.append('carnet_backside_image', newData.reverse_image)
      formdata.append('face_image', newData.face_image)
      formdata.append('false_negatives', newData.false_negatives)
      formdata.append('qr_false_negatives', newData.qr_false_negatives)
      formdata.append('rut', newData.rut)
      formdata.append('dni', newData.dni)
      formdata.append('dni_type', newData.dni_type)
      formdata.append('token', props.data.token)
      formdata.append('manual_verification', data.is_manual_verification)
      formdata.append('star_coords', data.star_coords)
      formdata.append('flag_coords', data.flag_coords)
      formdata.append('qr_coords', data.qr_coords)
      formdata.append('automaticPhoto', data.automaticPhoto)
      formdata.append('QRautomaticPhoto', data.QRautomaticPhoto)
      formdata.append('document_country', data.document_country)
      formdata.append('reception', data.reception)
      setData(prev => ({...prev, ...newData}))
      if (data.is_manual_verification === true) {
        // console.log("es manualll")
        setPage(9)
      }
      // const formDataObj = {};
      // for (const [key, value] of formdata.entries()) {
      //   formDataObj[key] = value;
      // }
      // console.log("data enviada a back", formDataObj)
      await axios({
        url:  process.env.REACT_APP_BACKEND_URL + "/validation/faces_verification/", // provar con puerto :80/validation/faces_verification/
        method: 'POST',
        headers: {
          "Content-Type": "multidata/form-data",
        },
        data: formdata
      })
      .then ((res) => {
        // console.log("----res----")
        // console.log(res)
        // console.log("--res end--")
        if (res.status !== 200) {
          throw new Error('No se pudo crear la cuenta')
        }
        if (res.data.validate) {
          if (res.data.dm_success && res.data.dm_success === true && data.is_manual_verification) {
            newData.dm_success = true;
            setData(prev => ({...prev, ...newData}))
            setPage(6);
          }
          else {
            if (res.data.emotion === "SAD") {
              newData.emotion = "triste"
              newData.emotion_english = "SAD"
            }
            else if (res.data.emotion === "HAPPY") {
              newData.emotion = "feliz"
              newData.emotion_english = "HAPPY"
            }
            else if (res.data.emotion === "ANGRY") {
              newData.emotion = "enojado"
              newData.emotion_english = "ANGRY"
            }
            else if (res.data.emotion === "SURPRISED") {
              newData.emotion = "sorprendido"
              newData.emotion_english = "SURPRISED"
            }
            setData(prev => ({...prev, ...newData}))
            let emotionData = buildValidateEmotionData(newData.emotion, newData.emotion_english, newData.face_image)
            setValidateEmotionData(emotionData)
            setPage(11)
          }
        } else {
          if (props.data.required_document === false) {
            setGoBackToPage(4)
          } else {
            setGoBackToPage(1)  // si hubo un error en este punto, debemos tomar de nuevo todas las imagenes
          }
          setPage(5)
        }
      })
      .catch(err =>{
        if (props.data.required_document === false) {
          setGoBackToPage(4)
        } else {
          setGoBackToPage(1)
        }
        setPage(5)
      })
    }

    else if (step === 6) {
      sendResponsible(newData, callback)
    }

    else if (step === 7) {
      setPage(7)
    }

    else if (step === 8) {
      sendInsuree(newData, callback)
    }

    else if (step === 10) {
      newData.rut = props.data.rut;
      newData.dni = props.data.dni;
      newData.dni_type = props.data.dni_type;
      setData(prev => ({...prev, ...newData}))
      if (props.data.required_document === false) {
        setPage(4)
      } else {
        setPage(1)
      }
    }
  }

  const nextPageEmotion = async (newData) => {
    setValidateEmotionData(prev => ({...prev, ...newData}))
    let formdata = new FormData()
    formdata.append('face_image_1', newData.face_image)
    formdata.append('face_image_2', newData.emotion_image)
    formdata.append('rut', newData.rut)
    formdata.append('dni', newData.dni)
    formdata.append('dni_type', newData.dni_type)
    formdata.append('emotion', newData.emotion_english)
    formdata.append('token', newData.token)
    formdata.append('reception', data.reception)
    await axios({
      url: process.env.REACT_APP_BACKEND_URL + "/validation/emotions_verification/",
      method: 'POST',
      headers: {
        "Content-Type": "multidata/form-data",
      },
      data: formdata
    })
    .then ((res) => {
      if (res.status !== 200) {
        throw new Error('No se pudo crear la cuenta')
      }
      if (res.data.validate) {
        setPage(6)
      }
      else {
        setPage(5)
      }
    })
    .catch(err =>{
      setPage(5)
    })
  }

  const prevPage = (newData, valor = 0) => {
    setData(prev => ({...prev, ...newData}))
    if (valor === 0) {
      setPage(prev => prev - 1)
    }
    else {
      setPage(valor)
      setGoBackToPage(0)  // siempre reiniciamos el comportamiento de prevPage
    }
  }
  
  const nextEvaluationDetails = () => {
    setPage(13)
  }
  
  const nextEvaluationPaymentDetails = () => {
    setPage(14)
  }

  const steps = [
    <Introduccion next={nextPage} data={data}/>, // 0
    <ChoiceCountry prev={prevPage} data={data} next={nextPage} carnetNuevo={carnetNuevo} setCarnetNuevo={setCarnetNuevo} country={country} setCountry={setCountry}/>, // 1
    <ChoiceBackOldOrNew prev={prevPage} data={data} next={nextPage} carnetNuevo={carnetNuevo} setCarnetNuevo={setCarnetNuevo}/>, // 2
    <AlmostReady prev={prevPage} data={data} next={nextPage}/>, // 3
    <TakePictureCara prev={prevPage} next={nextPage} data={data}/>, //4
    <VerificationFailed prev={prevPage} next={nextPage} goBackToPage={goBackToPage} data={data}/>, //5
    <VerificationComplete prev={prevPage} next={nextPage} nextEvaluationDetails={nextEvaluationDetails} data={data}/>, //6
    <ManualValidationFirstForm prev={prevPage} next={nextPage} data={data}/>, // 7
    <ManualValidationSecondForm prev={prevPage} next={nextPage} data={data}/>, // 8
    <AlertInfoRut prev={prevPage} next={nextPage} data={data}/>, // 9
    <CamaraPermission next={nextPage} data={data}/>, // 10
    <TakePictureEmocion prev={prevPage} next={nextPageEmotion} data={validateEmotionData}/>, // 11
    <EvaluationStatus prev={prevPage} next={nextPage} data={data}/>, // 12
    <EvaluationDetails next={nextEvaluationPaymentDetails} data={data}/>, // 13
    <EvaluationPaymentDetails data={data}/> // 14
  ];
  
  if (page >= 13) {
    // Flujo información de evaluación
    // Cedemos toda maquetación a componentes
    return (
      <>
        {steps[page]}
      </>
    )
  }

  return (
    <div className="container-validation">
      <VerificationState page={page} rut={data.formatted_rut}  dni={data.dni}  dni_type={data.dni_type} />
      <div className="container">
        {steps[page]}
      </div>
    </div>
  );
}