import React, {useEffect, useState, useCallback} from "react";
import '../../styles/validation.css'
import IconSuccess from '../../images/icon-success.svg';
import getAccessToken from "../../utils/getAccessToken";
import axios from "axios";
import Loading from "../loading";

export default function EvaluationStatus (props) {
  const [getStatus, setGetStatus] = useState(true);
  const verification_token = props.data.token;
  const [retryStatus, setRetryStatus] = useState(false);
  
  const getInitialMaxTime = () => {
    return process.env.REACT_APP_MAX_SECONDS || 60
  }
  
  const [maxTime, setMaxTime] = useState(getInitialMaxTime());
  
  const getEvaluationStatus = useCallback(async  () => {
    try {
      setGetStatus(false)
      let token = await getAccessToken,
        data = new FormData()
      data.append("token", verification_token)
      data.append("reception", props.data.reception)
      let response = await axios({
        method: 'POST',
        url: process.env.REACT_APP_BACKEND_URL + "/validation/evaluation_status/",
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + token
        },
        data: data
      })
      if (!response.data || response.data.status !== 'pending') {
        setGetStatus(true)
      }else {
        props.nextEvaluationDetails()
      }
    } catch (error) {
      setGetStatus(true)
    }
  }, [verification_token, props])
  
  const handleRetry = () => {
    setRetryStatus(false)
    setMaxTime(getInitialMaxTime())
    setGetStatus(true)
  }
  
  useEffect(() => {
    if (getStatus) {
      setTimeout(function () {
        if (maxTime > 0) {
          getEvaluationStatus()
          setMaxTime(maxTime - 1)
        }else {
          setGetStatus(false)
          setRetryStatus(true)
        }
      }, 1000);
    }
  }, [getStatus, getEvaluationStatus, maxTime])
  
  return (
    <div>
      <div className='figure' id="circle">
        <img className='icon-success-verificacion-pendiente'
         src={IconSuccess}
         alt="success icon" />
      </div>
      <h1 className="text-complete-verification mb-2">Verificación Completa</h1>
      {retryStatus ? (
        <button onClick={handleRetry}
                className="evaluation-status__retry-btn">Reintentar obtención de bonificación</button>
      ) : (
        <>
          <Loading/>
          <h4 className="verification-complete-paragraph mt-4">Espera, estamos obteniendo la bonificación de tu seguro</h4>
        </>
        )
      }
    </div>
  );
}