import React from "react";
import '../../styles/capture.css';
import '../../styles/slider.css';
import TakePictureCarnet from '../validacionRut/CaptureCarnetFront';
import AutomaticCapture from "./AutomaticCapture";
import cedula_nueva from './cedula_nueva.png';
import cedula_vieja from './cedula_antigua.png';

function ChoiceOldOrNew({props, setIsCountrySelected}) {

  const handleNew = () => {
    setIsCountrySelected(true)
    props.setCarnetNuevo(1)
  }
  const handleOld = () => {
    setIsCountrySelected(true)
    props.setCarnetNuevo(-1)
  }
  if (props.carnetNuevo === 1) {
    return AutomaticCapture(props)
  } else if (props.carnetNuevo === -1) {
    return TakePictureCarnet(props)
  }

  return (
    <>
      <h1 className="country_select__title">¿Qué carnet tiene?</h1>
      <div className="country_select__container">
        <img className="button-img" onClick={handleOld} src={cedula_vieja} alt={"Formato de cedula antiguo"}/>
        <img className="button-img" onClick={handleNew} src={cedula_nueva} alt={"Formato de cedula nuevo"}/>
      </div>
    </>
  )

}

export default ChoiceOldOrNew;