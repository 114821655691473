import React from "react";
import '../styles/validation.css'
import IconLoading from '../images/icon-loading.png';

export default function Loading () {
  return (
    <div>
      <div className='figure' id="circle-empty">
          <img className='icon-loading'
              src={IconLoading}
              alt="loading icon" />
      </div>
    </div>
  );
}