import React from 'react'
import GertyLogo from '../../images/gerty-blue.svg'

export default function EvaluationHeader () {
  return (
    <div className="evaluation-header">
      <img src={GertyLogo}
           alt="GertyId Logo" />
    </div>
  )
}