



export function formatRut(rut){
    const newRut = rut.replace(/\./g,'').replace('-', '').trim().toLowerCase();
    const lastDigit = newRut.substr(-1, 1);
    const rutDigit = newRut.substr(0, newRut.length-1)
    let result = Array.from(rutDigit).map((digit, i) => {
        if (rutDigit.length === 8 && (i === 2 || i === 5)) {
          return "." + digit;
        } else if (rutDigit.length === 7 && (i === 1 || i === 4)) {
          return "." + digit;
        }
        return digit;
    });

    return result.concat('-').concat(lastDigit);
}