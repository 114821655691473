import React, { useState } from 'react';
import DownloadImgModal from '../DownloadImgModal';
import "../../styles/display_image.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearchPlus} from "@fortawesome/free-solid-svg-icons";

export default function DisplayImage (props) {
  const [modalShow, setModalShow] = useState(false);
  const createSubTitle = () => {
    if (props.type === "face1-carnet"){
      return <div className="display-image__subtitle">Rostro</div>;
    } else if (props.type === "carnet_image"){
      return <div className="display-image__subtitle">Documento Identidad Frente</div>;
    } else if (props.type === "face1-face2") {
      return <div className="display-image__subtitle">Emoción</div>
    } else if (props.type === "backside_image") {
      return <div className="display-image__subtitle">Documento Identidad Reverso</div>
    }
  }

  const createTitle = () => {
    return (
      <div>
        <div className="display-image__title">{props.name}</div>
        {createSubTitle()}
      </div>
    )
  }
  return (
    <div>
      {props.type === "face1-carnet" || props.type === "face1-face2" ? 
        <div className="display-image__image-container" onClick={() => setModalShow(true)}>
          <div className="display-image__search-icon-container">
            <FontAwesomeIcon icon={faSearchPlus} className="display-image__search-icon"/>
          </div>
          <img className="display-image__image" src={props.image} alt={`Intento id: ${props.id}`}/>
        </div>
      : 
      <div className="display-image__carnet-container" onClick={() => setModalShow(true)}>
        <div className="display-image__search-icon-container">
          <FontAwesomeIcon icon={faSearchPlus} className="display-image__search-icon"/>
        </div>
        <img className="display-image__carnet" src={props.image} alt={`Intento id: ${props.id}`} />
      </div>}
      {props.position  > 0 ? <div className="display-image__position">Intento {props.position}</div> : null}
      <DownloadImgModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        activeId={props.id}
        title={createTitle()}
        downloadAttempt={true}
        prevDownloadName={"Imagen".trim().replace(" ", "_").toLowerCase()}
        image={props.image}
      >
        <img src={props.image} alt={`Intento id: ${props.id}`} id={props.id}/>
      </DownloadImgModal>
    </div>

  )
}