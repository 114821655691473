import Alert from 'react-bootstrap/Alert'
import React, {useEffect, useState} from 'react';
import '../styles/verification-state.css';

export default function VerificationState (props) {

  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [dni, setDni] = useState()

  const setVariables = () => {
    let m = "";
    if (!props.dni_type || props.dni_type === "rut") {
      setDni(props.rut)
    } else {
      setDni(props.dni.toUpperCase())
    }

    switch (props.page) {
      case 5:
        setType("danger");
        if (!props.dni_type || props.dni_type === "rut") {
          m = "Falló verificación del rut:";
        } else {
          m = "Falló verificación de la identidad:"
        }
        setMessage(m)
        break;
      case 6:
        setType("success");
        if (!props.dni_type || props.dni_type === "rut") {
          m = "Rut verificado:";
        } else {
          m = "Identidad verificada:"
        }
        setMessage(m)
        break;
      default:
        setType("info");
        if (!props.dni_type || props.dni_type === "rut") {
          m = "Verificando rut:";
        } else {
          m = "Verificando identidad:"
        }
        setMessage(m)
        break;
    }
  }

  useEffect(() => {
    return setVariables();
  });

  return (
    <Alert variant={type} className="state-info">
      <div>{message} <strong>{dni}</strong></div>
    </Alert>
  )
}