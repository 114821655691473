import React from 'react';
import NF404 from '../images/404.gif';
import GertyCloud from "../images/gerty-cloud.svg";

export default function NotFound() {
  return (
    <div className="container-validation">
      <div className="container">
        <img className='gerty-cloud'
             src={GertyCloud}
             alt="Logo Gerty cloud" />
        <h1 className="text-complete-verification">No podemos continuar</h1>
        <img src={NF404} alt="Page Not found" />
      </div>
    </div>
  );
}
