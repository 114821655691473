import React from 'react';

const FlagButton = ({countryName, onClick, isSelected}) => {
  const formattedName = countryName.charAt(0).toUpperCase() + countryName.slice(1).toLowerCase();
  const className = "flag-button" + (isSelected ? " flag-button__selected" : " flag-button__unselected")

  // le quito tildes al string
  const normalize_name = countryName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // accesedemos a la imagen con path absoluto, para esto deber existir en la carpeta raiz public
  const local_img = `/images/bandera_${normalize_name}.png`

  return (
    <div className={className}  onClick={onClick}>
      <img className="flag-button__image" src={local_img} alt={`Bandera de ${formattedName}`}/>
      <div className="flag-button__title">
        <h4>{formattedName}</h4>
      </div>
    </div>
  );
};

export default FlagButton;
