// import {useOpenCv} from "opencv-react";
import {Contour_correct_size_proportion, dotProduct, linealNorm, sum} from "./utils_functions";

let STAR_W_PROP = 380 / 4117;
let STAR_H_PROP = 360 / 4117;
let W_TOLERANCE = [0.5, 1.2];
let H_TOLERANCE = [0.5, 1.2];
let STAR_HW_PROP = 0.94;
let HW_TOLERANCE = [0.6, 1.666];


function is_correct_star_histogram(hist) {
    hist = linealNorm(hist).map(x => x ** 3);
    let points = 0
    for (let i = 0; i < 5; ++i) {
        // dprint("points grupo ", i)
        if (hist[i * 4 + 1] / hist[i * 4] < 1 / 2) {
            points += 1
            // dprint(1)
        }
        if (hist[i * 4 + 2] / hist[i * 4] < 1 / 3) {
            points += 1
            // dprint(2)
        }
        if (hist[i * 4 + 3] / hist[i * 4] < 1 / 2) {
            points += 1
            // dprint(3)
        }
    }
    // dprint("points", points)
    return points > 10;
}


function Is_contour_a_star(cv, c, mask) {  // # c es un contorno que se extrajo de la mascara mask
    // const {cv} = useOpenCv();
    let M = cv.moments(c, false);

    if (M.m00 !== 0) {
        // let cx = Math.floor(M.m10 / M.m00)  // # centro de la estrella
        // let cy = Math.floor(M.m01 / M.m00)
        // pass
    } else {
        return false
    }
    let cx = Math.floor(M.m10 / M.m00)  // # centro de la estrella
    let cy = Math.floor(M.m01 / M.m00)

    let [h, w] = mask.matSize;

    let rect = cv.boundingRect(c);

    // let xmin = rect.x
    // let xmax = xmin + rect.width - 1
    // let ymin = rect.y
    // let ymax = ymin + rect.height - 1
    let cw = rect.width
    let ch = rect.height
    // sacamos la diagonal del cuadro que rodea la estrella
    let diagonal = (cw * cw + ch * ch) ** (1 / 2)
    // dprint("diagonal")
    // dprint(diagonal)
    // con esto tenemos el maximo radio de la figura
    let max_radio = Math.floor(diagonal / 2)
    // dprint(max_radio)
    let aux = mask.clone()
    let pi = Math.PI;
    let directional_histogram = []
    for (let o = 0; o < 2; o += 0.1) {  // # cada 0.1pi
        // let directional_pixel_sum = 0;
        let set_check_sum = new Set(); // esto es para no contar puntos repetidos
        for (let d = 0; d < max_radio; ++d) {  // # se aumenta de a poco la distancia
            // """ se le suman al punto inicial x e y por trigonometría"""
            let x = Math.floor(Math.round(Math.cos(pi * o) * d) + cx)
            let y = Math.floor(Math.round(Math.sin(pi * o) * d) + cy)
            if (x < 0 || x >= w || y < 0 || y >= h) { // # se llegó a un punto que se sale de la imagen
                break
            }
            if (mask.data[y * mask.cols + x]) {  // # si se encuentra un pixel detro de la figura
                // directional_pixel_sum += 1
                set_check_sum.add(`[${x}, ${y}]`) // para que nuestro punto sea contado una sola vez
                // aux = cv2.circle(aux, (x, y), radius=0, color=0, thickness=-1)
                cv.circle(aux, {x: x, y: y}, 0, [0, 0, 0, 255], -1)
            } else {
                break  // # en teoría no debiesen haber más pixeles en esa dirección
            }
        }
        // # dprint("directional_pixel_sum")
        // # dprint(directional_pixel_sum)
        // # dprint(len(set_check_sum))
        directional_histogram.push(set_check_sum.size)
    }
    // # original_star_vector = [40, 20, 15, 21, 39, 21, 16, 20, 40, 23, 17, 20, 39, 21, 18, 22, 37, 19, 16, 22]
    // # este vector se estrajo con este algoritmo a partir de una estrella "ideal"
    let ideal_lineal_normalized = [0.34629763, 0.17314882, 0.12986161, 0.18180626, 0.33764019, 0.18180626,
        0.13851905, 0.17314882, 0.34629763, 0.19912114, 0.14717649, 0.17314882,
        0.33764019, 0.18180626, 0.15583393, 0.1904637, 0.32032531, 0.16449138,
        0.13851905, 0.1904637]
    // dprint(directional_histogram)
    let max_pos = directional_histogram.indexOf(Math.max(...directional_histogram));

    // dprint(max_pos)
    function arrayRotate(arr, count) {
        // positivo rota a la derecha, negativo izquierda
        count -= arr.length * Math.floor(count / arr.length);
        arr.push.apply(arr, arr.splice(0, count));
        return arr;
    }

    directional_histogram = arrayRotate(directional_histogram, max_pos)
    let current_max = 0
    let nbin = Math.floor(directional_histogram.length / 5) // numero de bins para cada punta de la estrella
    let maxi = 0
    for (let i = 0; i < nbin; ++i) {
        // eslint-disable-next-line no-loop-func
        let selcbins = [i, i + nbin, i + nbin * 2, i + nbin * 3, i + nbin * 4].map(x => directional_histogram[x])
        const sum = selcbins.reduce((a, b) => a + b, 0);
        const m = (sum / 5) || 0; // mean, average
        if (m > current_max) {
            current_max = m
            maxi = i
        }
    }

    directional_histogram = arrayRotate(directional_histogram, maxi)
    // dprint(directional_histogram)
    // let norm = directional_histogram.map(x=>x / sum(directional_histogram));
    let lnorm = linealNorm(directional_histogram)
    directional_histogram = directional_histogram.map(x => x ** 3)
    let lnorm_potencial = linealNorm(directional_histogram)
    let dot_compare = dotProduct(lnorm_potencial, ideal_lineal_normalized)
    // dprint("lnorm", lnorm)
    // dprint("dot", dotProduct(directional_histogram, directional_histogram))
    // dprint("norm dot", dotProduct(norm, norm))
    // dprint("lnorm, ideal dot", dot_compare)
    let compare_diff = lnorm.map((x, i) => (lnorm[i] - ideal_lineal_normalized[i]) ** 2);
    // dprint("diff compare", np.sum(compare_diff))
    // dprint("poss", x, y)
    // myimshow(aux, debug=debug)
    // myimshow(compare_diff, "compare_diff", "bar", debug=debug)
    // myimshow(directional_histogram / linealNorm(directional_histogram), type="bar", debug=debug)
    aux.delete()
    if (sum(compare_diff) < 0.15 && dot_compare > 0.85 && is_correct_star_histogram(directional_histogram)) {
        return true
    }
    return false
}


export default function Detect_star(cv, img, isMat = false, spected_pos = null, tresh = 160, mode = null, star_width_aprox = 0.85, average_const = 0, op1_size = 1, closing_size = 6, op2_size = 2) {
    // El componente donde se llama esta funcion, se le debe pasar cv desde "const {cv} = useOpenCv();"
    // TODO: revisar formato input, en estos momentos img se espera que sea formato cv.Mat
    // opcionalmente podría ser formato "ImageData" revisar https://docs.opencv.org/3.4/df/d24/tutorial_js_image_display.html
    // img = cv.matFromImageData(imgData)
    // const {cv} = useOpenCv();

    if (!isMat) {
        if (typeof (img) === "string") {
            img = cv.imread(img);
        } else {
            console.log("Error input Detect_star, es un Mat en vez de string?")
            return false
        }
    }

    let [h, w] = img.matSize;
    let hbound = Math.floor(h / 2)
    let wbound = Math.floor(w / 2)

    let rect = new cv.Rect(wbound, 0, wbound, hbound);
    let gray = img.roi(rect);  // aqui se recorta la imagen a analizar al cuarto arriba derecha
    cv.cvtColor(gray, gray, cv.COLOR_RGBA2GRAY);

    // # hsv_mask= cv.Mat.ones((h,w))
    // # hsv_mask = hsv_mask[:hbound, wbound:]

    let hsv = img.roi(rect)
    cv.cvtColor(hsv, hsv, cv.COLOR_RGBA2RGB);
    cv.cvtColor(hsv, hsv, cv.COLOR_RGB2HSV);
    let lower = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), [0, 0, 0, 0]);
    let upper = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), [41, 255, 255, 255]);
    let hsv_mask1 = new cv.Mat();
    cv.inRange(hsv, lower, upper, hsv_mask1)
    lower.delete()
    upper.delete()
    lower = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), [160, 0, 0, 0]);
    upper = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), [255, 255, 255, 255]);
    let hsv_mask2 = new cv.Mat();
    cv.inRange(hsv, lower, upper, hsv_mask2)
    let hsv_mask = new cv.Mat()
    cv.bitwise_or(hsv_mask1, hsv_mask2, hsv_mask)

    // let rgb = img.roi(rect);  // añadir un filtro rgb
    // cv.cvtColor(rgb, rgb, cv.COLOR_RGBA2RGB);
    // lower.delete()
    // upper.delete()
    // lower = new cv.Mat(rgb.rows, rgb.cols, rgb.type(), [69, 70, 62, 0]);
    // upper = new cv.Mat(rgb.rows, rgb.cols, rgb.type(), [159, 139, 123, 255]);
    // let rgb_mask = new cv.Mat();
    // cv.inRange(rgb, lower, upper, rgb_mask)
    // cv.bitwise_or(hsv_mask, rgb_mask, hsv_mask)
    // rgb.delete()
    // rgb_mask.delete()

    // # gray = cv2.bitwise_and(gray, hsv_mask)
    let segment = cv.Mat.zeros(img.rows, img.cols, cv.CV_8U)

    let kernelop1 = cv.Mat.ones(op1_size, op1_size, cv.CV_8U)
    let kernelcl = cv.Mat.ones(closing_size, closing_size, cv.CV_8U)
    let kernelop2 = cv.Mat.ones(op2_size, op2_size, cv.CV_8U)


    // # star_width_aprox = Math.floor(380 / 4117 * w * 0.6)

    star_width_aprox = Math.floor(380 / 4117 * w * star_width_aprox)
    // # console.log(star_width_aprox)
    star_width_aprox += star_width_aprox % 2 === 0  // # debe ser impar
    // average_const = average_const
    let threshold = new cv.Mat();
    let adapt_thresh = new cv.Mat();
    cv.adaptiveThreshold(gray, adapt_thresh, 255, cv.ADAPTIVE_THRESH_MEAN_C, cv.THRESH_BINARY_INV, star_width_aprox, average_const);
    // # _, threshold = cv2.threshold(grayeq, 160, 255, cv2.THRESH_BINARY)
    // # threshold = ~threshold
    cv.bitwise_and(adapt_thresh, hsv_mask, threshold);
    // # cv2.imshow('threshold+hsv', threshold)


    let opening1 = new cv.Mat();
    cv.morphologyEx(threshold, opening1, cv.MORPH_OPEN, kernelop1);

    let closing = new cv.Mat();
    cv.morphologyEx(opening1, closing, cv.MORPH_CLOSE, kernelcl);

    let opening2 = new cv.Mat();
    cv.morphologyEx(closing, opening2, cv.MORPH_OPEN, kernelop2);

    let s = new cv.Scalar(0, 0, 0, 255);
    let left_pad = segment.cols - opening2.cols;
    let bot_pad = segment.rows - opening2.rows;
    cv.copyMakeBorder(opening2, segment, 0, bot_pad, left_pad, 0, cv.BORDER_CONSTANT, s);

    // segment[hbound, wbound:] = 255 //TODO: agregar esto para asegurar filtrado de algunos contornos
    // segment[:hbound, wbound] = 255
    gray.delete();
    hsv.delete();
    lower.delete();
    upper.delete();
    hsv_mask1.delete();
    hsv_mask2.delete();
    kernelop1.delete();
    kernelcl.delete();
    kernelop2.delete();
    threshold.delete();
    opening1.delete();
    closing.delete();
    opening2.delete();
    function analisis_on_segment(segment_analyzed, img) {

        let contours = new cv.MatVector();
        let hierarchy = new cv.Mat();
        // en python se usaba RETR_TREE en vez de RETR_LIST, RETR_LIST debiese ser más rapido, no usa el hierarchy
        cv.findContours(segment_analyzed, contours, hierarchy, cv.RETR_LIST, cv.CHAIN_APPROX_SIMPLE);
        // dprint("contours find")
        // dprint(len(contours))
        let mask = cv.Mat.zeros(img.rows, img.cols, cv.CV_8U) //<<
        let good_contour = []


        for (let i = 0; i < contours.size(); ++i) {
            let contour = contours.get(i);

            if (!Contour_correct_size_proportion(cv, contour, w, STAR_W_PROP, STAR_H_PROP, W_TOLERANCE, H_TOLERANCE, STAR_HW_PROP, HW_TOLERANCE)) {
                contour.delete();
                continue
            }
            // # approx = cv2.approxPolyDP(contour, 0.5, true)

            // # dprint("cv2.contourArea(contour)")
            // # dprint(cv2.contourArea(contour))

            let onecontour = new cv.MatVector();
            onecontour.push_back(contour);
            cv.drawContours(mask, onecontour, -1, [255, 0, 0, 0], -1, cv.LINE_8); //<<
            // cv2.drawContours(mask, [contour], -1, 1, -1) //<<
            onecontour.delete()
            if (!Is_contour_a_star(cv, contour, mask)) { //<<
                contour.delete()
                continue
            }
            // # cv2.drawContours(img, [contour], 0, (0, 0, 255), 5)
            good_contour.push(contour);
        }
        segment_analyzed.delete(); //<<
        contours.delete(); //<<
        hierarchy.delete(); //<<
        mask.delete(); //<<
        // # displaying the image after drawing contours
        // # cv2.imshow('shapes mask', mask)
        // # dprint("len(good_contour)")
        // # dprint(len(good_contour))
        if (good_contour.length === 1) {
            // console.log("estrella detected")

            let M = cv.moments(good_contour[0], false);
            let x = Math.floor(M.m10 / M.m00)
            let y = Math.floor(M.m01 / M.m00)
            // console.log("x, y", x, y)

            // img = cv2.circle(img, (x, y), radius=2, color=(0, 255, 255), thickness=-1)
            cv.circle(img, {x: x, y: y}, 2, [255, 255, 0, 255], -1) // TODO: revisar si esto no produce error con entrada img
            if (spected_pos) { // para debugear
                cv.rectangle(img, spected_pos.p1, spected_pos.p2, [55,155,255, 55],2)
                cv.circle(img, {x: (spected_pos.p1.x + spected_pos.p2.x) /2, y: (spected_pos.p1.y + spected_pos.p2.y) /2}, 4, [100,255,100, 1],-1)

            }
            good_contour[0].delete()
            return [x, y]
        } else if (good_contour.length > 1) {

            for (let i = 0; i < good_contour.length; ++i) {
                good_contour[i].delete()
            }

            // console.log("varias estrellas detectadas, probar de nuevo")
            // console.log(good_contour)
            // let detects = np.zeros(img.matSize)
            // for c in good_contour:
            //     cv2.drawContours(detects, [c], -1, 1, -1)
            //     is_contour_a_star(c, detects)
            // myimshow(detects, 'detects', debug=debug)
        } else {
            // console.log("no se detectó estrellas")
        }
        return false
    }
    let hsv_tresh = analisis_on_segment(segment, img)
    if (hsv_tresh) {
        hsv_mask.delete();
        adapt_thresh.delete()
        return hsv_tresh
    } else {
        let segment_2 = cv.Mat.zeros(img.rows, img.cols, cv.CV_8U)
        cv.copyMakeBorder(hsv_mask, segment_2, 0, bot_pad, left_pad, 0, cv.BORDER_CONSTANT, s);
        hsv_mask.delete();
        let only_hsv = analisis_on_segment(segment_2, img)
        if (only_hsv) {
            adapt_thresh.delete()
            return only_hsv
        } else {
            let segment_3 = cv.Mat.zeros(img.rows, img.cols, cv.CV_8U)
            cv.copyMakeBorder(adapt_thresh, segment_3, 0, bot_pad, left_pad, 0, cv.BORDER_CONSTANT, s);
            adapt_thresh.delete()
            let only_thresh = analisis_on_segment(segment_3, img)
            if (only_thresh) {
                return only_thresh
            }
        }
    }
    return false
}

