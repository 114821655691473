import React, {useState} from 'react';
import GertyLogo from '../images/gerty-blue.png';
import '../styles/validation.css'
import Loading from '../components/loading';

export default function CamaraPermission(props) {

  const[loading, setLoading] = useState('');

  const handleSubmit = () => {
    props.next(props.data, 10)
    setLoading(true);
  }

  if(loading){
    return <Loading />
  }

  return (
    <div className='camara-permission-container'>
      <img className='gerty-verificate-validar'
           src={GertyLogo}
           alt="GertyId Logo" />
      <h3 className="titulo-centro-verificar-pendiente-validar">Recuerda permitir el acceso a la cámara para tomar las imágenes</h3>
      <div><button className="button is-link" id="button-signin" onClick={handleSubmit}>Continuar</button></div>
    </div>
  );
}

