import useAuth from "../hooks/useAuth";
import {Redirect} from "react-router-dom";
import AdminLayout from "../components/AdminLayout";
import React, {useEffect} from "react";
import Location from "../components/Location";
import {Tabs, Tab} from "react-bootstrap";
import axios from 'axios';
import {useStateIfMounted} from "use-state-if-mounted";
import NewLocationModal from "../components/NewLocationModal";

export default function Locations() {

  var { currentUser, handleUserLogout } = useAuth();
  const [key, setKey] = useStateIfMounted('active');

  const [locations, setLocations] = useStateIfMounted([]);

  const [loading, setLoading] = useStateIfMounted(true);

  const getActiveLocations = () => {
    return locations.filter((location) => location.active)
  }

  const [ activeLocation, setActiveLocation ] = useStateIfMounted({
    id: undefined,
    name: "",
    active: true,
    created_at: ""
  });

  const [modalShow, setModalShow] = useStateIfMounted(false)

  const setCurrentLocation = (id) => {
    let location = locations.filter((location) => location.id === id)[0]
    if (location !== undefined) {
      setActiveLocation(location)
    }
  }

  const renderLocations = (locationsList) => {
    return locationsList.map((location) =>
    {
      return (
        <div
          className={"locations_container__location" + (activeLocation !== undefined && activeLocation.id === location.id ? " locations_container__location_active" : "") + (location.active ? "" : " locations_container__location_disabled")}
          onClick={() => setCurrentLocation(location.id)}
          key={location.id}
        >
          <div className={"locations_container__location_name"}>
            {location.name}
          </div>
          <div className={"locations_container__location_created_at"}>
            {location.active ? location.created_at : "Desactivada"}
          </div>
        </div>
      )
    })
  }

  const changeTab = (k) => {
    if (k === "active" && activeLocation !== undefined && !activeLocation.active) {
      let activeLocations = getActiveLocations()
      setActiveLocation(activeLocations.length ? activeLocations[0] :
        {
          id: null,
          name: "",
          active: true,
          created_at: ""
        }
      )
    }
    setKey(k)
  }

  useEffect(() => {
    if (activeLocation === undefined || activeLocation.id === undefined) {
      const getLocations = async () => {
        let form_data = new FormData(),
          token = currentUser.access
        form_data.append('commerce_id',currentUser.user.commerce_id)
        try {
          const res = await axios({
            url: process.env.REACT_APP_BACKEND_URL + "/commerce/branches/",
            method: 'POST',
            mode: "no-cors",
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + token
            },
            data: form_data
          })
          setLoading(false)
          return res;
        } catch (e) {
          setLoading(false)
          if (e.response) {
            return e.response;
          }
        }
      }
      const assignActiveLocation = async () => {
        if(currentUser){
          const response2 = await getLocations();
          if(response2 === undefined || response2.status === 500 || response2.length === 0 || response2.data.length === 0){
            setLocations([])
            setActiveLocation({
              id: null,
              name: "",
              active: true,
              created_at: ""
            })
          }else{
            if (response2.status === 401){
              return handleUserLogout();
            }else{
              setLocations(response2.data)
              let activeLocations = response2.data.filter((location) => location.active)
              if (activeLocations.length) {
                setKey('active')
                setActiveLocation(activeLocations[0])
              }else {
                setKey('all')
                setActiveLocation(response2.data[0])
              }
            }
          }
        } else {
          return handleUserLogout();
        }
      }
      assignActiveLocation()
    }
  }, [locations, activeLocation, currentUser, handleUserLogout, setActiveLocation, setLoading, setLocations, setKey])

  const AddNewLocationBtn = () => {
    return (
      <div className={"locations-title-section__download_btn"} onClick={() => setModalShow(true)}>
        Crear nueva ubicación
      </div>
    )
  }

  const activeLocationUpdated = (new_location) => {
    setLocations(locations.map(function(location) {
      return new_location.id === location.id ? new_location : location
    }))
    setActiveLocation(new_location)
    new_location.active ? setKey('active') : setKey('all')
  }

  const newLocationAdded = (new_location) => {
    locations.push(new_location)
    setLocations(locations)
    setActiveLocation(new_location)
    new_location.active ? setKey('active') : setKey('all')
    setModalShow(false)
  }

  const Content = () => {
    return (
      <div className={"locations-container"}>
        { locations.length ? (
          <>
            <div className={"locations-body-section__locations_container"}>
              <Tabs
                activeKey={key}
                onSelect={(k) => changeTab(k) }
                className="locations-body-section__filter"
              >
                <Tab eventKey="active" title="Activas" disabled={!getActiveLocations().length && true} tabAttrs={
                  {
                    title: !getActiveLocations().length && "Sin ubicaciones activas"
                  }
                }>
                  {renderLocations(getActiveLocations())}
                </Tab>
                <Tab eventKey="all" title="Todas">
                  {renderLocations(locations)}
                </Tab>
              </Tabs>
            </div>
            <div className={"locations-body-section__active_location"}>
              {activeLocation !== undefined && <Location activeLocation={activeLocation} afterUpdate={activeLocationUpdated} />}
            </div>
          </>
        ) : (
         <>
           <div className={"locations-body-section__empty_locations"}>
             <span>No posee ninguna ubicación asociada</span>
             <AddNewLocationBtn />
           </div>
         </>
        )}
        <NewLocationModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          afterCreate={newLocationAdded}
          key={"no_locations_modal"}
        />
      </div>
    )
  }

  return (
    <>
      { currentUser && currentUser.type === "commerce" ? (
        <AdminLayout loading={loading} helpSection={<AddNewLocationBtn/>}>
          <Content/>
        </AdminLayout>
      ) : (
        <Redirect to={{
          pathname: "/login",
          state: { referrer: "/locations" }
        }} />
      )}
    </>
  )

}