import React, {useEffect, useState} from "react";
import GertyLogo from '../images/gerty-white.svg';
import 'bulma/css/bulma.min.css';
import '../styles/index.css';
import '../styles/home.css';
import axios from "axios";
import Loading from "../components/loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { useRollbar } from '@rollbar/react';
import VERSION from "../VERSION";

export default function CheckBackendStatus() {

  const rollbar = useRollbar();

  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState(false)

  useEffect( () => {
    const checkStatus = async () => {
      await axios({
        url:  process.env.REACT_APP_BACKEND_URL,
        method: 'GET',
        headers: {
          "Content-Type": "multidata/form-data"
        }
      })
      .then ((res) => {
        setStatus(true)
      })
      .catch(err =>{
        setStatus(false)
        rollbar.error('[Chequeo de estado del servidor] Error conectando a servidor', err);
      })
      .finally(() => {
        setLoading(false)
      })
    }
    checkStatus()
  })

  const BackendOK = () => {
    return (
      <div className={"backend-status"}>
        <FontAwesomeIcon icon={faCheckCircle} className="backend-status-icon backend-status-icon__ok" />
        <h1 className='title'>¡Conexión OK!</h1>
      </div>
    )
  }

  const BackendNotOK = () => {
    return (
      <div className={"backend-status"}>
        <FontAwesomeIcon icon={faTimesCircle} className="backend-status-icon backend-status-icon__not-ok" />
        <h1 className='title'>¡No podemos conectarnos al servidor!</h1>
      </div>
    )
  }

  const RenderStatus = () => {
    if (status) {
      return <BackendOK />
    }
    return <BackendNotOK />
  }

  return(
    <div className="home-container status-page">
      <div className='container'>
        <img className='logo'
             src={GertyLogo}
             alt="GertyId Logo" />
        { loading ? (
            <Loading />
          ) : (
            <RenderStatus />
          )
        }
      </div>
      <div className="app-version">
        {VERSION}
      </div>
    </div>
  );
}