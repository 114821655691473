import React from "react";
import OldVerificationComplete from "./OldVerificationComplete";
import EvaluationStatus from "../evaluation/EvaluationStatus";

export default function VerificationComplete (props) {
  
  const goToEvaluationStatus = () => {
    return props.data.usage !== "credits" && !props.data.is_manual_verification
  }
  
  if (goToEvaluationStatus()) {
    return <EvaluationStatus nextEvaluationDetails={props.nextEvaluationDetails} data={props.data}/>
  }
  
  return <OldVerificationComplete {...props} />
  
}