import React, { useState, useEffect } from "react";
import '../../styles/capture.css';
import Loading from '../loading';
import SwitchCameraIcon from "../../images/switch-camera-icon.png";
import {Camera} from "react-camera-pro";

const TakePictureEmotion = (props) => {

  let info =  {
    "emotion": props.data.emotion,
    "sad": 0,
    "happy": 0,
    "surprised": 0,
    "angry": 0};

  const [showImgCapture, setShowImgCapture] = useState(true);
  const [loading, setLoading] = useState(true);

  const [imgSrc, setImgSrc] = useState(null);
  const webcamRef = React.useRef(null);
  const INITIAL_FACING_MODE = props.data.is_manual_verification ? "environment" : "user";
  const capture = React.useCallback(
    () => {
      setImgSrc(webcamRef.current.takePhoto());
      setShowImgCapture(false);
    },
    [webcamRef]
  );

  function chooseEmotionIcon(emotionInfo) {
    if (emotionInfo.emotion){
      if (emotionInfo.emotion === "feliz") {
        emotionInfo.happy = 1;
      }
      else if (emotionInfo.emotion === "sorprendido") {
        emotionInfo.surprised = 1;
      }
      else if (emotionInfo.emotion === "enojado") {
        emotionInfo.angry = 1;; 
      }
      else if (emotionInfo.emotion === "triste") {
        emotionInfo.sad = 1;
      }
    }

    return emotionInfo; 
  }

  chooseEmotionIcon(info); //Llamamos a la función para elegir el ícono

  const handleSubmit = () => {
    setLoading(true);
    props.data.emotion_image = imgSrc.split(',')[1];
    props.next(props.data)
  }

  const onBack = () => {
    setShowImgCapture(true);
    setImgSrc(null);
  }
 
  useEffect(() => {
    const timer = setTimeout(() => { setLoading(false); }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
    <div className='background-capture' hidden={loading}>
      {showImgCapture && (
        <div>
          <h1 className='title-capture'>¡Gracias! Ahora tomate una foto <span className="span-for-emotion">{info.emotion}</span></h1>
          <h1 className='subtitle-capture' id="take-picture-face"> Ubica tu rostro dentro del círculo.</h1>
        </div>
      )}
      {imgSrc && (
        <div>
          <h1 className='title-capture'>Revisa la imagen para continuar</h1>
          <h1 className='subtitle-capture' id="take-picture-face">Asegúrate de que la imagen se vea bien.</h1>
        </div>
      )}
      <div className="capture">
        {showImgCapture && (
          <>
            <div className="full-container-face">
              <div className="borde1">
                <div className="filtro-circular1"/>
              </div>
              <Camera
                ref={webcamRef}
                aspectRatio={1}
                facingMode={INITIAL_FACING_MODE}
              />
            </div>
            <div className="capture-buttons-container face-capture">
              <button className="button button-capture" id="button-picture-face" onClick={capture}>Tomar Foto</button>
              <img className="button-switch-camera" onClick={() => webcamRef.current.switchCamera()} src={SwitchCameraIcon} title="Cambiar cámara" alt="Cambiar cámara" />
            </div>
          </>
          )}
        {imgSrc && (
          <div>
            <img className="rounded-face-img" src={imgSrc} alt="captured-img"/>
            <div>  
              <button className="button button-again-capture" onClick={onBack}>
                Volver a tomar foto
              </button>
            </div>
            <div>
              <button className="button button-contine-capture" onClick={handleSubmit}>Continuar</button>
            </div>
          </div>
        )}
      </div>
    </div>
    <div hidden={!loading}>
        <Loading/>
      </div>
    </>
  );
};

export default TakePictureEmotion;