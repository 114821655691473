// import {useOpenCv} from "opencv-react";

// import {OpenCvProvider} from "opencv-react";

export function currencyFormat(value) {
    return new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP'
    }).format(value);
}

export function euclidian_dist(p1, p2) {
    let [x1, y1] = p1;
    let [x2, y2] = p2;
    let y = x2 - x1;
    let x = y2 - y1;
    return Math.sqrt(x * x + y * y);
}


export function Contour_correct_size_proportion(cv, contour, referece, width_prop, heigh_prop, w_ranges = [0.6, 1.5], h_ranges = [0.6, 1.5], self_prop = null, s_ranges = null, debug = null) {
    // const {cv} = useOpenCv();
    let rect = cv.boundingRect(contour);
    let xmin = rect.x
    let xmax = xmin + rect.width - 1
    let ymin = rect.y
    let ymax = ymin + rect.height - 1

    let lw = rect.width
    let lh = rect.height
    let w_getted = lw / referece
    let h_getted = lh / referece
    let w_compare_ratio = w_getted / width_prop
    let h_compare_ratio = h_getted / heigh_prop
    if (debug) {
        console.log("coords", [xmin, ymin], [xmax, ymax])
        console.log("proporcions", w_getted, h_getted)
        console.log("comp", w_compare_ratio, h_compare_ratio)
    }
    if (self_prop !== null) {
        let lselfp = lh / lw
        let self_ratio = lselfp / self_prop
        if (debug) {
            console.log("self and ratio", lselfp, self_ratio)
        }
        if (!(s_ranges[0] <= self_ratio && self_ratio <= s_ranges[1])) {
            return false
        }
    }
    if (w_ranges[0] <= w_compare_ratio <= w_ranges[1]) {
        return h_ranges[0] <= h_compare_ratio && h_compare_ratio <= h_ranges[1]
    }
    return false

}

export function linealNorm(arr) { // esto hace lo mismo que np.linalg.norm() de python con un array 1-D
    let norm = Math.sqrt(arr.reduce((a, b) => a + b ** 2, 0));
    return arr.map(x => x / norm)
}

export function dotProduct(a, b) { // producto punto
    return a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);
}

export function sum(arr) { // sumatoria de array
    return arr.reduce((m, n) => m + n);
}

// debug web https://docs.opencv.org/3.4/dc/dcf/tutorial_js_contour_features.html
// let src = cv.imread('canvasInput');
// let dst = cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC3);
// cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, 0);
// cv.threshold(src, src, 140, 255, cv.THRESH_BINARY);
// let contours = new cv.MatVector();
// let hierarchy = new cv.Mat();
// cv.findContours(src, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);
// console.log("contours.size()");
// console.log(contours.size());
// console.log(contours[0]);
//
// let cnt = contours.get(0);
// console.log("cnt");
// console.log(cnt);
// console.log(cnt.data16U);
//
// let rect = cv.boundingRect(cnt);
// console.log("rect");
// console.log(rect);
//
// console.log('image width: ' + src.cols + '\n' +
//             'image height: ' + src.rows + '\n' +
//             'image size: ' + src.size().width + '*' + src.size().height + '\n' +
//             'image depth: ' + src.depth() + '\n' +
//             'image channels ' + src.channels() + '\n' +
//             'image type: ' + src.type() + '\n');
// // You can try more different parameters
// let Moments = cv.moments(cnt, false);
// momentsOutput.innerHTML = Moments.m00;
// src.delete(); dst.delete(); contours.delete(); hierarchy.delete();
