import React from 'react';
import DisplayImage from './DisplayImage';
import "../../styles/display_attempts.css";

export default function DisplayAttempts (props) {

  const faceAttempts = props.attempt_images.filter(attempt => attempt.type === "face1-carnet").length;
  const emotionAttempts = props.attempt_images.filter(attempt => attempt.type === "face1-face2").length;
  const fullName = () => {
    if (props.first_name && props.last_name) {
      return `${props.first_name} ${props.last_name}`
    } else {
      return "Nombre no especificado"
    }
  }

  return (
    <div className="display-attempt-images">
      <div className="display-attempt-images__title">Imágenes</div>
      <p className="display-attempt-images__subtitle">Rostro</p>
      {
        props.attempt_images.filter(attempt => attempt.type === "face1-carnet").length > 0 ?
          <div className="display-attempt-images__face">
            {
              props.attempt_images.filter(attempt => attempt.type === "face1-carnet").map(
                (attempt, index) => (
                  <DisplayImage image={attempt.image} id={attempt.id} type={attempt.type} name={fullName()} position={faceAttempts > 1 ? index + 1 : -1} key={"face_" + index}/>
                )
              )
            }
          </div> :
          <div className="display-attempt-images__info">El usuario no ha subido fotos de su rostro</div>
      }
      <p className="display-attempt-images__subtitle">Emoción</p>
      {
        props.attempt_images.filter(attempt => attempt.type === "face1-face2").length > 0 ?
          <div className="display-attempt-images__emotion">
            {
              props.attempt_images.filter(attempt => attempt.type === "face1-face2").map(
                (attempt, index) => (
                  <DisplayImage image={attempt.image} id={attempt.id} type={attempt.type} name={fullName()} position={emotionAttempts > 1 ? (index + 1) : -1} key={"emotion_" + index}/>
                )
              )
            }
          </div> :
          <div className="display-attempt-images__info">El usuario no ha intentado validar emoción</div>
      }
      <p className="display-attempt-images__subtitle">Documento</p>
      <div className="display-attempt-images__document">
        {
          props.carnet_image ?
            <DisplayImage image={props.carnet_image} type={"carnet_image"} name={fullName()} key={"carnet_image"}/> :
            <div className="display-attempt-images__info-carnet">Frente de Documento no disponible</div>
        }
        {
          props.backside_image ?
            <DisplayImage image={props.backside_image} type={"backside_image"} name={fullName()} key={"backside_image"}/> :
            <div className="display-attempt-images__info-carnet">Reverso de Documento no disponible</div>
        }
      </div>
    </div>
  )
}