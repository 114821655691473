import React, {useEffect, useState} from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Label } from 'reactstrap';
import '../../styles/validation.css'
import Loading from "../loading";
import {format} from "rut.js";

export default function InsureeForm (props) {

  const [loading, setLoading]= useState('');
  const [message] = useState('');

  const country = props.data.document_country

  const renderDocType = () => {
    if (country === "CL") {
      return "Rut"
    } else if (country === "CO") {
      return "Nuip"
    } else if (country === "EC") {
      return "N° Cédula"
    } else if (country === "MX") {
      return "Curp / rfc"
    }
  }

  const renderPlaceholder = () => {
    switch (country) {
      case "CL":
        return "75.223.564-9"
      case "CO":
        return "1234567890"
      case "EC":
        return "1234567890"
      case "MX":
        return "HEGG560427MVZRRL04"
      default:
        console.log("Lo lamentamos, por el momento no disponemos de " + country + ".");
    }
  }


  const minimizeRut = (rut) => {
    if (country !== "CL")
      return rut
    return rut.replaceAll(".", '');
  }

  const handleSubmit = (values) => {
    setLoading(true);
    props.data.name_responsible = values.name_responsible
    props.data.dni_responsible = minimizeRut(values.dni_responsible)
    props.data.agreed = values.agreed_input
    props.next(props.data, 6, setLoading)
  }

  // const handleChange
  const handleChange = (e, setValues) => {
    let value;
    switch (country) {
      case "CL":
        handleChangeChile(e,setValues)
        return
      case "MX":
        value = e.target.value.toUpperCase()
        break
      case "CO":
        value = e.target.value.replace(/[^0-9]/g, '');
        break
      case "EC":
        value = e.target.value.replace(/[^0-9]/g, '');
        break
      default:
        handleChangeChile(e,setValues)
        return
    }
    props.data.dni_responsible = value
    setValues(prevValues => ({
      ...prevValues,
      // we use the name to tell Formik which key of `values` to update
      [e.target.name]: value
    }))
  }

  const handleChangeChile = (event, setValues) => {
    props.data.dni_responsible = props.data.dni_responsible.replace("-", "")
    if (event.nativeEvent.data) {
      if (props.data.dni_responsible.length <= 8) {
        if (!isNaN(event.nativeEvent.data) || (event.nativeEvent.data === "k" && props.data.dni_responsible.length >= 7) || (event.nativeEvent.data === "K" && props.data.dni_responsible.length >= 7)) {
          if (props.data.dni_responsible.charAt(7) !== "k" && props.data.dni_responsible.charAt(7) !== "K") {
            props.data.dni_responsible += event.nativeEvent.data
          }
        }
      }
    }
    else {
      props.data.dni_responsible = props.data.dni_responsible.substring(0, props.data.dni_responsible.length - 1);
    }

    let format_dni = ""

    if (props.data.dni_responsible) {
      format_dni = format(props.data.dni_responsible)
    }

    setValues(prevValues => ({
      ...prevValues,
      // we use the name to tell Formik which key of `values` to update
      [event.target.name]: format_dni
    }))

  }

  useEffect(() => {
    if (!props.data.is_manual_verification) {
      return props.prev(props.data, 0)
    }
  }, [props]);

  if(loading) {
    return <Loading />
  }

  return (
    <div className="container-validation__manual-validation">
      <h1 className="titulo-register">Datos del Responsable</h1>
      <Formik
        initialValues={
          {
            name_responsible: props.data.name_responsible,
            dni_responsible: props.data.dni_responsible,
            agreed_input: props.data.agreed
          }
        }
        validationSchema={
          Yup.object({
            name_responsible: Yup.string()
              .required('Este campo es obligatorio'),
            dni_responsible: country === "CL" ? Yup.string()
              .required('Este campo es obligatorio')
              .min(9, 'Ingrese un rut valido')
              .matches(
                /^(?=.*[-])/,
                'Debe incluir guion'
              ) :
              Yup.string()
                .required('Este campo es obligatorio')
            ,
            agreed_input: Yup.bool()
              .oneOf([true],'Debe certificar la identidad del paciente para continuar'),
          })
        }
        onSubmit={
          async (values) => {
            handleSubmit(values)
          }
        }
      >
        {({ errors, touched, setValues}) => (
          <Form>
            <div className="field-form-register">
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className={ errors.name_responsible && touched.name_responsible ? "input-group-error field" : "field" }>
                    <Label className='input-label'>Nombre de responsable de la validación</Label>
                    <Field
                      className="input"
                      name="name_responsible"
                      placeholder="Sofía Vallejos"
                    />
                    {
                      errors.name_responsible && touched.name_responsible && (
                        <div className="error-input-register no-padding">{errors.name_responsible}</div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className={ errors.dni_responsible && touched.dni_responsible ? "input-group-error field" : "field" }>
                    <Label className='input-label'>{renderDocType()} de responsable de la validación</Label>
                    <Field className="input" name="dni_responsible" placeholder={renderPlaceholder()} onChange={(e) => handleChange(e, setValues)}/>
                    {
                      errors.dni_responsible && touched.dni_responsible && (
                        <div className="error-input-register no-padding">{errors.dni_responsible}</div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className={ errors.agreed_input && touched.agreed_input ? "input-group-error field" : "field" }>
                    <label className="control control--checkbox no-margin">
                      <Field type="checkbox" className="input" name="agreed_input" />
                      <div className="control__indicator no-margin"></div>
                      <div className="control__indicator-text">
                        <strong>Yo</strong>, actúo como ministro de fé por la identidad de la persona. <strong>Certificando</strong> que tengo pruebas para determinar y demostrar que la persona atendida corresponde al <strong>asegurado.</strong>
                      </div>
                    </label>
                    {
                      errors.agreed_input && touched.agreed_input && (
                        <div className="error-input-register no-padding">{errors.agreed_input}</div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="button is-link">Enviar</button>
          </Form>
        )}
      </Formik>
      <p>{message}</p>
    </div>
  );
}