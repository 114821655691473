import * as React from 'react';
import {QRCodeCanvas} from 'qrcode.react';
// import GertyLogo from '../images/gerty-blue-ico.ico';

const RenderQRCode = (props) => {
  return (
    <QRCodeCanvas
      value={props.value}
      level={"M"}
      size={props.size || 180}
      includeMargin={false}
      bgColor={"#ffffff"}
      // fgColor={"#4236ff"}
      id={props.id}
      fgColor={"#000000"}
      // imageSettings={{
      //   src: GertyLogo,
      //   height: props.size ? (props.size / 6) : 45,
      //   width: props.size ? (props.size / 6) : 45,
      //   excavate: true
      // }}
    />
  )
}

export default RenderQRCode;