import React, {useState, useEffect, useRef} from "react";
import '../../styles/capture.css';
import Loading from '../loading';
import '../../styles/slider.css';
import SwitchCameraIcon from '../../images/switch-camera-icon.png';
// import TemplateCarnet from '../../images/template_testeo_carnet.png'
// import TemplateCarnet from './template_testeo_carnet.png'
import TemplateCarnet from '../../images/documento-front.svg';
import {Camera} from "react-camera-pro";
import Detect_flag from "./detect_flag";
import {useOpenCv} from "opencv-react";
import Detect_star from "./detect_star";


// const { loaded, cv } = useOpenCv();

function AutomaticCapture(props) {
    // https://trello.com/b/AMmgBB2e/gertyid2
    const getAutomaticPhotoTakingTimeInSeconds = () => {
        return process.env.AUTOMATIC_PHOTO_TAKING_TIME_IN_SECONDS ?? 5;
    }
    const {loaded, cv} = useOpenCv();

    const [loading, setLoading] = useState(true);
    const [imgSrc, setImgSrc] = useState(null);
    const [fps] = useState(10);
    const [scaning] = useState(false);
    const [manual, setManual] = useState(false);
    const [downcount, setDowncount] = useState(getAutomaticPhotoTakingTimeInSeconds());
    const webcamRef = React.useRef(null);
    const focusCount = React.useRef(0);
    const [focusCountShow, setfocusCountShow] = useState(0);
    const [focusCountTime, setfocusCountTime] = useState(0);
    const focusCountTimeStampStart = React.useRef(0);
    const take_false_negative = React.useRef(false);
    const spected_flag_coord = React.useRef(false);
    const spected_star_coord = React.useRef(false);
    const INITIAL_FACING_MODE = "environment";
    const [camara_horizontal, setCamara_horizontal] = useState(false);

    let scan = useRef(false);
    const capture = React.useCallback(
        () => {
            scan.current = false
            let img = webcamRef.current.takePhoto()
            props.data.automaticPhoto = false
            setImgSrc(img);

        }, [webcamRef, props.data]
    );

    function correct_placed(star_coords, flag_coords, h, w) {
        // TODO definir bien estos rangos, hay que calzar el template con estos rangos!!
        // se usand coordenadas recolectadas de scan 1200ppp
        let correct_flag_pos = false
        let correct_star_pos = false
        let margen_aceptacion = 0.06
        // el aspect ratio generalmente es x / y
        if (w / h < 4117 / 2569) { // si el alto es mayor que el ancho segun proporcion carnet (CAMARA VERTICAL)
            // mantemos los bordes laterales cercano en la imagen
            // horizontalmente dejamos un margen 5% ambos lados el ancho carnet será 90% de w
            // X flag 1284 / 4117 relativa del carnet, a esto le sumamos el 5% de w
            let x_flag = 0.9 * w * 1284 / 4117 + 0.05 * w; // 0,3306
            setCamara_horizontal(false)
            let y_flag = h / 2 - 2569 / 4117 * 0.9 * w / 2 + 291 / 4117 * 0.9 * w;
            if (flag_coords && x_flag - margen_aceptacion * w < flag_coords[0] && flag_coords[0] < x_flag + margen_aceptacion * w) {
                // 291 posicion y

                if (y_flag - margen_aceptacion * w < flag_coords[1] && flag_coords[1] < y_flag + margen_aceptacion * w) {
                    correct_flag_pos = true
                }
            }
            // X star 3755 / 4117
            let x_star = 0.9 * w * 3755 / 4117 + 0.05 * w; // 0,8752
            let y_star = h / 2 - 2569 / 4117 * 0.9 * w / 2 + 310 / 4117 * 0.9 * w;
            if (star_coords && x_star - margen_aceptacion * w < star_coords[0] && star_coords[0] < x_star + margen_aceptacion * w) {
                // 310 posicion y

                if (y_star - margen_aceptacion * w < star_coords[1] && star_coords[1] < y_star + margen_aceptacion * w) {
                    correct_star_pos = true
                }
            }
            // console.log(x_flag, y_flag, x_star, y_star)
            spected_flag_coord.current = {
                p1: {x: x_flag - margen_aceptacion * w, y: y_flag - margen_aceptacion * w},
                p2: {x: x_flag + margen_aceptacion * w, y: y_flag + margen_aceptacion * w}
            }
            spected_star_coord.current = {
                p1: {x: x_star - margen_aceptacion * w, y: y_star - margen_aceptacion * w},
                p2: {x: x_star + margen_aceptacion * w, y: y_star + margen_aceptacion * w}
            }

        } else {
            // mantemos los bordes sueriores y inferior cercano en la imagen (CAMARA HORIZONTAL)
            // verticalmente dejamos un margen 5% ambos lados el alto carnet será 90% de h
            // X flag 1284 / 2569 relativa del carnet, esto lo sumamos a despues de restar la mitad de caret al centro de w (w/2)
            let x_flag = w / 2 - 0.9 * h * 4117 / 2569 / 2 + 0.9 * h * 1284 / 2569;
            // 291 posicion y
            let y_flag = 0.05 * h + 291 / 2569 * 0.9 * h
            setCamara_horizontal(true);
            if (flag_coords && x_flag - margen_aceptacion * h < flag_coords[0] && flag_coords[0] < x_flag + margen_aceptacion * h) {

                if (y_flag - margen_aceptacion * h < flag_coords[1] && flag_coords[1] < y_flag + margen_aceptacion * h) {
                    correct_flag_pos = true
                }
            }
            // X star 3755 / 2569
            let x_star = w / 2 - 0.9 * h * 4117 / 2569 / 2 + 0.9 * h * 3755 / 2569; // 0,8752
            // 310 posicion y
            let y_star = 0.05 * h + 310 / 2569 * 0.9 * h;
            if (star_coords && x_star - margen_aceptacion * h < star_coords[0] && star_coords[0] < x_star + margen_aceptacion * h) {

                if (y_star - margen_aceptacion * h < star_coords[1] && star_coords[1] < y_star + margen_aceptacion * h) {
                    correct_star_pos = true
                }
            }
            spected_flag_coord.current = {
                p1: {x: x_flag - margen_aceptacion * h, y: y_flag - margen_aceptacion * h},
                p2: {x: x_flag + margen_aceptacion * h, y: y_flag + margen_aceptacion * h}
            }
            spected_star_coord.current = {
                p1: {x: x_star - margen_aceptacion * h, y: y_star - margen_aceptacion * h},
                p2: {x: x_star + margen_aceptacion * h, y: y_star + margen_aceptacion * h}
            }
        }
        // console.log("correct_flag_pos, correct_star_pos")
        // console.log(correct_flag_pos, correct_star_pos)
        return correct_star_pos && correct_flag_pos
    }

    const frameAnalisis = React.useCallback(
        () => {
            // console.log("scan", scaning)
            if (webcamRef.current == null)
                return
            let begin = Date.now();
            if (webcamRef.current.getNumberOfCameras() > 0) {
                if (!scan.current) { // para detener el loop
                    if (take_false_negative.current) {
                        let cameraCanvas = webcamRef.current.takePhoto()
                        props.data.false_negatives.push(cameraCanvas.split(',')[1]);
                        take_false_negative.current = false; // almacenamos la imagen si no se detectó el carnet para poseterior analisis
                    }
                    return
                }
                // console.log("SCANNING")
                let cameraCanvas = webcamRef.current.takePhoto()

                let img = cv.imread("camaraCanvas")
                // coordenadas en formato [x, y]
                let star_coords = Detect_star(cv, img, true) //, spected_star_coord.current)
                let flag_coords = Detect_flag(cv, img, "", true) //, spected_flag_coord.current)  // el outcanvan es para debug, si se da, debe haber un tag <canvas> con ese id o crashea (id=outdebug)
                let [h, w] = img.matSize;
                img.delete();
                if (correct_placed(star_coords, flag_coords, h, w)) {
                    // console.log("carnet bien posicionado!")
                    if (focusCount.current === 0) {
                        focusCountTimeStampStart.current = Date.now();
                    }
                    focusCount.current += 3;
                } else if (focusCount.current > 0) {
                    focusCount.current -= 1
                }
                setfocusCountShow(focusCount.current)
                if (take_false_negative.current) {
                    props.data.false_negatives.push(cameraCanvas.split(',')[1]);
                    take_false_negative.current = false;
                    // console.log("saving fals negative")
                }
                if (focusCount.current > 15) {
                    // console.log("foto lista")
                    props.data.flag_coords = flag_coords
                    props.data.star_coords = star_coords
                    props.data.automaticPhoto = true
                    setImgSrc(cameraCanvas)

                    let elapsedForFocusStart = Date.now() - focusCountTimeStampStart.current;
                    setfocusCountTime(elapsedForFocusStart)
                    focusCount.current = 0
                    // console.log("ELAPSED", elapsedForFocusStart)
                    return;

                }
            }
            let elapsed = Date.now() - begin;
            let nextframe = 1000 / fps - elapsed;
            // console.log("elapsed", elapsed)
            // console.log("next frame", nextframe)
            // console.log("count", count)
            // console.log("countflags", countflags)
            setTimeout(frameAnalisis, nextframe);
        }, [cv, fps, props.data])


    useEffect(() => { // Creacion componente
        const timer = setTimeout(() => {
            setLoading(false); // aparentemente el comoponente Camera se demora en cargar y a veces lanza error si no se espera
        }, 3000);
        props.data.false_negatives = []
        let canvas = document.getElementsByTagName("canvas");
        if (typeof canvas[0] === 'object' && canvas[0] !== null && 'setAttribute' in canvas[0]) {
            canvas[0].setAttribute('id', 'camaraCanvas');
            canvas[0].getContext('2d', {willReadFrequently: true});
        }
        return () => {
            clearTimeout(timer);
        }
    }, [props.data])

    useEffect(() => {
        if (imgSrc) {
            // console.log("AVANZANDO")
            props.data.carnet = imgSrc;
            props.data.frontal_image = imgSrc.split(',')[1];
            // props.data.automaticPhoto = !manual;
            props.next(props.data, 1);
        }
    }, [imgSrc, props.data, props]);

    useEffect(() => { // Inicio scaner
        if (loading || !loaded) return;
        scan.current = !scan.current
        props.data.false_negatives = []
        frameAnalisis()
        let downcountInterval = setInterval(function() {
            // if (downcount <;M,;= 0) {
                // clearInterval(downcount);
            // }
            setDowncount((p) => {
                if (p>0)
                    return p-1
                else{
                    clearInterval(downcountInterval)
                    // console.log("stoped")
                    return p
                } })
        },1000)
        const manualTimer = setTimeout(()=>{
            take_false_negative.current = true
            // scan.current = false; // esto detiene el scan, desavilitado deja escaneado siempre
            setManual(true);
            // console.log("timeout, take manual")
        },getAutomaticPhotoTakingTimeInSeconds() * 1000)
        let timesRun = 0;
        let interval = setInterval(function(){
            timesRun += 1;
            if(timesRun >= 3){
                clearInterval(interval);
            }
            take_false_negative.current = true
        }, 5000);
        // console.log("scaning change")
        // console.log(count)

        return () => {
            clearTimeout(manualTimer);
            clearInterval(interval);
            clearInterval(downcountInterval);
        }

    }, [scaning, loading, loaded, frameAnalisis, props.data]);

    const renderTitle = () => {
        return <div><h1 className='title-capture'>Cédula Frontal</h1></div>
    }

    const renderSubtitle = () => {
        return <h1 className='subtitle-capture'>Alinea el documento según la plantilla.</h1>
    }

    const renderBtnText = () => {
        return downcount > 0 ? "(" + downcount + ") Escaneo Automático": "Tomar Foto Manual"
    }

    return (
        <>
            <div className='background-capture' hidden={(loading || !loaded)}>
                <div className="capture">
                    <div className="full-container-of-cam1">
                        <div className="full-container-of-cam2">
                            <div className="wide-camera-container">
                                <div className="borde-nuevo">
                                </div>
                                {camara_horizontal
                                    ? <img src={TemplateCarnet} alt="template" className="img-template-horizontal"
                                           style={{filter: `grayscale(${1 - focusCountShow / 14})`}}/>
                                    : <img src={TemplateCarnet} alt="template" className="img-template-vertical"
                                           style={{filter: `grayscale(${1 - focusCountShow / 14})`}}/>
                                }
                                <Camera
                                    id={"camaraCapturing"}
                                    ref={webcamRef}
                                    facingMode={INITIAL_FACING_MODE}
                                />
                            </div>
                            <div className="full-container-of-cam3">
                                {renderTitle()}
                                {renderSubtitle()}
                                <div className="capture-buttons-container">
                                    <button disabled={!manual} className="button button-capture button-automatic-photo" onClick={(e) => {
                                        capture()
                                    }}>{renderBtnText()}</button>
                                    <img className="button-switch-camera button-automatic-photo"
                                         onClick={() => webcamRef.current.switchCamera()}
                                         src={SwitchCameraIcon} title="Cambiar cámara" alt="Cambiar cámara"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="wide-camera-container">*/}
                {/*    {camara_horizontal*/}
                {/*        ? <img src={TemplateCarnet} alt="template" className="img-template-horizontal"/>*/}
                {/*        : <img src={TemplateCarnet} alt="template" className="img-template-vertical"/>*/}
                {/*    }*/}
                {/*    <canvas id="outdebug" style={{width: "100%"}}></canvas>*/}
                {/*</div>*/}
            </div>
            <div hidden={!(loading || !loaded)}>
                <Loading/>
            </div>
        </>
    );
}

export default AutomaticCapture;