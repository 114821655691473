import React from 'react';
import '../../styles/login-rut.css';
import GertyLogo from '../../images/gerty-blue.png';
import IconSmile from '../../images/icons-smile.svg';
import '../../styles/validation.css'
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import DownloadAppSection from "../DownloadAppSection";

export default function NoVerificationFound() {
  const { currentUser } = useAuth();
  
  return (
    <div className="background-no-pending">
      <img className='gerty-pending'
        src={GertyLogo}
        alt="GertyId Logo" />
      <h1 className="no-verification" >No hay verificaciones<br></br>pendientes asociadas</h1>
      <img className='no-pending-icon'
         src={IconSmile}
         alt="Smile" />
      <DownloadAppSection/>
      {currentUser ? (
        <div className="button is-link" id="button-no-pending">
          <Link to="/">Volver a inicio</Link>
        </div>
      ): (<div></div>
      )}
    </div>
  );
}