import axios from "axios";

export default function getGeoInfo(setCountry) {
  axios.get("https://ipapi.co/json/", {timeout: 1500})
    .then((response) => {
      let geoinfo = response.data;
      setCountry(geoinfo.country_code);
    })
    .catch((error) => {
      // si no nos funciona la api, por defecto elejimos chile
      setCountry("CL");
    });
}