import React, {useEffect} from "react";
import '../../styles/validation.css'
import { Link } from 'react-router-dom';
import IconSuccess from '../../images/icon-success.svg';
import GertyCloud from '../../images/gerty-cloud.svg';
import useAuth from "../../hooks/useAuth";
import DownloadAppSection from "../DownloadAppSection";
import IconLoading from "../../images/icon-loading.png";

export default function OldVerificationComplete (props) {
  const { currentUser } = useAuth();
  
  const redirect_to = props.data.redirect_to
  const usage = props.data.usage
  
  const redirectUser = () => {
    setTimeout( () => {
      window.location.href = redirect_to
    }, 5000)
  }
  
  const getRedirectToMessage = () => {
    switch (usage) {
      case "credits":
        return "Serás redireccionado a la página para simular tu crédito"
      default:
        return "Por favor espere mientras es redirigido"
    }
  }
  
  useEffect(() => {
    if (!!redirect_to) {
      redirectUser()
    }
  })
  
  return (
    <div>
      <div className='figure' id="circle">
        <img className='icon-success-verificacion-pendiente'
             src={IconSuccess}
             alt="success icon" />
      </div>
      <h1 className="text-complete-verification">Verificación Completa</h1>
      <h4 className="verification-complete-paragraph">Ya puedes continuar tu<br></br>atención en el comercio.</h4>
      {!!redirect_to && (
        <div className={"verification-complete__redirect-message"}>
          <img className='icon-loading'
               src={IconLoading}
               alt="loading icon" />
          <span>{getRedirectToMessage()}</span>
        </div>
      )}
      <img className='gerty-cloud'
           src={GertyCloud}
           alt="Logo Gerty cloud" />
      <DownloadAppSection/>
      {currentUser && (
        <div>
          <Link to="/home" className="button is-link" id="button-final-verification"> Finalizar </Link>
        </div>
      )}
    </div>
  );
}