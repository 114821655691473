import React, { useState } from "react";
import '../../styles/validation.css'
import IconSuccess from '../../images/icon-success.svg';
import Loading from "../loading";

export default function AlmostReady (props) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    props.next(props.data, 1)
  }

  const renderMessage = () => {
    return <h4 className="verification-complete-paragraph">Ahora una foto del rostro.</h4>
  }

  if(loading){
    return <Loading />
  }
  return (
    <div className="almost-ready-container">
      <div className='figure' id="circle">
        <img className='icon-success'
          src={IconSuccess}
          alt="success icon" />
      </div>
      <h1 className="text-complete-verification">Casi listos</h1>
      {renderMessage()}
      <button className="button is-link" id="almost-button" onClick={handleSubmit}>Continuar</button>
    </div>
  );
}