import React, {
    createContext, useEffect, useCallback,
  } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
  
  let logoutTimer;
  
  export const AuthContext = createContext();
  
  const AuthContextProvider = ({ children }) => {
    const [currentUser, storeUser, clearStoredUser] = useLocalStorage('user');
    const [sessionExpDate, storeSessionExpDate, clearSessionExpDate] = useLocalStorage('sessionExpiration');

    const handleUserLogin = (data) => {
      const expiration = new Date(data.access.exp * 1000);
      storeUser(data);
      storeSessionExpDate(expiration);
    };
  
    const handleUserLogout = () => {
      clearStoredUser();
      clearSessionExpDate();
    };
  
    const handleAutomaticLogout = useCallback(() => {

    }, []);

    useEffect(() => {      
      if (currentUser && sessionExpDate) {
        const remainingTime = new Date(sessionExpDate).getTime() - new Date().getTime();
        logoutTimer = setTimeout(handleAutomaticLogout, remainingTime);
      } else {
        clearTimeout(logoutTimer);
      }
    }, [currentUser, sessionExpDate, handleAutomaticLogout]);
  
    return (
      <AuthContext.Provider value={{ currentUser, handleUserLogin, handleUserLogout }}>
        {children}
      </AuthContext.Provider>
    );
  };
  
  export default AuthContextProvider;