// import GooglePlayStore from '../images/google-play-logo.png';
// import AppStore from '../images/appstore.png';
import React from "react";

export default function DownloadAppSection (props) {
  return (
    <div>
      {/*Por los momentos lo desactivaremos*/}
      {/*<h2 className="download-app">Descarga la app</h2>*/}
      {/*<h4 className="download-app-paragraph">Identifícate de manera fácil y segura</h4>*/}
      {/*<div className="apps-icons-no-pending">*/}
      {/*  {props.apple !== false*/}
      {/*    ? <img className='logo-appstore-verificate' src={AppStore} alt="Logo Apple Store" />*/}
      {/*    : ''*/}
      {/*  }*/}
      {/*  {props.google !== false*/}
      {/*    ? <img className='logo-google-verificate' src={GooglePlayStore} alt="Logo Google Play Store" />*/}
      {/*    : ''*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
  );
}