import React from "react";
import GertyLogo from '../images/gerty-white.svg';
import 'bulma/css/bulma.min.css';
import '../styles/index.css';
import '../styles/home.css';
import VERSION from "../VERSION";

export default function Home() {
  return(
    <div className="home-container">
      <div className='container'>
        <img className='logo'
          src={GertyLogo}
          alt="GertyId Logo" />
        <h1 className='title'>¡Hola! <br/> Bienvenido a Gerty</h1>
        <p className='message'>Acá podrás validar tu identidad para continuar con tu atención dental</p>
        <img className='img-gerty'
             src='http://www.gerty.com/wp-content/uploads/2021/07/Gerty-big-04-uai-1032x1004.png'
             alt='Gerty'
        />
      </div>
      <div className="app-version">
        {VERSION}
      </div>
    </div>
  );
}