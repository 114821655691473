import * as React from 'react';
import {Modal, Button} from "react-bootstrap";
import {useState} from "react";
import "../styles/download_img_modal.css";
import { saveAs } from 'file-saver'

const DownloadImgModal = (props) => {
  const [id] = useState(props.activeId)
  const downloadImg = () => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = props.prevDownloadName+"_"+id+".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <>
      <Modal
        className={"download-img-modal"}
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.title}
            {props.downloadAttempt ? 
            <Button onClick={() => saveAs(props.image, 'image.jpg')} className={"download-img-modal__download_btn"}>Descargar</Button>
            : <Button onClick={() => downloadImg()} className={"download-img-modal__download_btn"}>Descargar</Button>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.children}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DownloadImgModal;